import React, { useEffect, useState } from "react";
import * as Constants from "../constants/constants";
import Cookie from "universal-cookie";
import Button from "../components/Button/button";
import LinkButton from "../components/Button/linkButton";
import LoadingFullscreen from "../components/loadingFullscreen";
import { useNavigate } from "react-router-dom";
import withDirectionFocusable from "../components/withDirectionFocusable";
import {
	getUserDetails,
	getSubscriptionDetails
} from "../services/gainAPI";
import {
	pageView,
	logout as logoutEvent
} from "../services/gtm";

const ButtonFocusable = withDirectionFocusable(Button);
const LinkButtonFocusable = withDirectionFocusable(LinkButton);


function SettingsSignOut(props) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		document.title = "Çıkış yap";
		pageView();

		props.setFocus("ButtonCancel");
		//props.setFocus("MENU-0");
	}, []);

	async function signOut() {
		setLoading(true);
		var subscriptionInfo = await getSubscriptionDetails();
		var userDetails = await getUserDetails();
		window.google_tag_manager && logoutEvent(userDetails.created_at, subscriptionInfo.lastBillingDate, subscriptionInfo.isPremium);

		const cookies = new Cookie();
		cookies.remove('access_token', { path: "/" });
		cookies.remove('refresh_token', { path: "/" });
		cookies.remove('uuid', { path: "/" });

		setLoading(false);
		navigate("/qr-login", { replace: true });
	}

	return (
		<div className="main-wrapper settings">
			<LoadingFullscreen show={loading} />

			<span id="logo-gain"></span>

			<div className="setting-wrapper">
				<h3>Çıkış Yap</h3>
				<div className="sign-out-wrapper">
					<h4>GAİN oturumunu kapatmak istediğine emin misin?</h4>
					<div className="btn-wrapper">
						<LinkButtonFocusable
							title={"Vazgeç"}
							focusKey={"ButtonCancel"}
							left={"SettingSidebar"}
							right={"ButtonSignout"}
							className={
								Constants.BUTTON_MEDIUM + Constants.BUTTON_SIGN_OUT
							}
							link={"/profile"}
							onEnterPress={() => {
								navigate(-1);
							}}
						/>
						<ButtonFocusable
							title={"Çıkış Yap"}
							focusKey={"ButtonSignout"}
							left={"ButtonCancel"}
							className={
								Constants.BUTTON_MEDIUM + Constants.BUTTON_SIGN_OUT
							}
							onEnterPress={() => {
								signOut();
							}}
							onClick={() => {
								signOut();
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SettingsSignOut;
