import React, { useState, useLayoutEffect } from "react";
import { getUserDetails, getSubscriptionDetails } from "../services/gainAPI";
import { Navigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function RequireAuth(props) {

    const [userData, setUserData] = useState();
    let location = useLocation();

    useLayoutEffect(() => {
        async function fetchData() {
            var accountInfo = await getUserDetails();
            setUserData(accountInfo);
        }
        fetchData();
    }, []);

    function renderData() {
        if (userData !== undefined) {
            if (userData !== null) {
                Sentry.setUser({ email: userData.email, id: userData.uuid });
                window.userEmail = userData.email;
                window.userID = userData.uuid;
                //console.log("userData", userData)
                if(userData.isPremium !== null && userData.isPremium === true){
                    return props.children
                }
                else{
                    return <Navigate to="/free-account-warning" state={{ from: location }} replace />
                }
            }
            else {
                return <Navigate to="/qr-login" state={{ from: location }} replace />
            }
        }
        else {
            return <></>
        }
    }

    return (
        renderData()
    )
}
