import React from "react";
import { Link } from "react-router-dom";

export default function EpisodeCard(props) {
	return (
		<>
			<Link to={props.link} className={`episode-card ${props.focused ? "selected":""}`}>
                <em className="banner"> 
                    <img src={props.img} alt={props.title} />
                    {/* <span><i className="play-icon"></i>S.1: B4</span>  */}
                    {props.progress && <i className="line"><i style={{width: props.progress + "%"}} ></i></i>}
                </em>
                <em className="detail">
                    <span>{props.titleName}</span>
                    <p>{props.detail}</p>
                    <i>{props.time}</i>
                </em> 
			</Link> 
		</>
	);
}