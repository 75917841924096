import React from "react";

export default function Input(props) {

  return (
        <input
                type={props.type} 
                className={`${props.className} ${props.focused ? "selected" : ""}`} 
                name={props.name}
                id={props.id}
                placeholder={props.placeholder}
                value={props.value}
                autoComplete="off" 
                maxLength="100"    
                onChange={props.onChange}
        /> 
  );
}