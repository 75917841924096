import React, {useEffect} from "react";
import GeneralProvider from "../provider/generalProvider";
import { Outlet } from "react-router-dom";
import SettingsSidebar from "../components/settingsSidebar";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import withDirectionFocusable from "../components/withDirectionFocusable";
import BackKeyHandler from "../components/backKeyHandler";

const SettingsSidebarFocusable = withFocusable({ trackChildren: true })(SettingsSidebar);
const OutletFocusable = withFocusable()(Outlet);
const BackKeyHandlerFocusable = withDirectionFocusable(BackKeyHandler);

export default function SettingsLayout(props) {
	useEffect(() => {
		props.setFocus("Outlet");

        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
        <GeneralProvider>
            <div className="wrapper">
                {
                    (window.platform === "vestel" || window.platform === "tizen" || window.platform === "webos") &&
                    <BackKeyHandlerFocusable 
                        focusKey={"ExitConfirmationFocusable"}
                        backFocus={"WatchNowButton"} />
                }
                <span id="logo"></span>
                <OutletFocusable focusKey={"Outlet"} focusable={true} />
                <SettingsSidebarFocusable
                    focusKey={"SettingSidebar"}
                    focusable={true}/>
            </div>
        </GeneralProvider>
	);
}
