import React from "react";

export default function FeaturedMediaSummaryContainer(props) {
  // constructor(props){
  //     super(props);
  // }

  return (
    <div id="featured-media-summary" className="media-summary">
      {props.children}
    </div>
  );
}
