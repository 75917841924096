import React from "react";

export default function TitleDetailSkeleton(props) {

    return (
        <>
            {props.onlyEpisodes == false && (
                <div id="featured-media-summary" className="media-summary skeleton">

                    <div className="logo animate"></div>

                    <div className="info animate"></div>

                    <div className="episode-buttons">
                        <span className="episode-title animate"></span>
                        <div className="episode-buttons-list">
                            <span className="btn btn-medium btn-season animate"></span>
                            <span className="btn btn-medium btn-season animate"></span>
                        </div>
                    </div>

                </div>
            )}


            <div className="title-detail-content">
                <div className="title-detail-list">
                    <div className="title-detail-track">
                        <div className="episode-card skeleton">
                            <div className="banner">
                                <div className="img animate"></div>
                            </div>
                            <div className="detail">
                                <span className="animate"></span>
                                <p className="animate"></p>
                                <i className="animate"></i>
                            </div>
                        </div>
                        <div className="episode-card skeleton">
                            <div className="banner">
                                <div className="img animate"></div>
                            </div>
                            <div className="detail">
                                <span className="animate"></span>
                                <p className="animate"></p>
                                <i className="animate"></i>
                            </div>
                        </div>
                        <div className="episode-card skeleton">
                            <div className="banner">
                                <div className="img animate"></div>
                            </div>
                            <div className="detail">
                                <span className="animate"></span>
                                <p className="animate"></p>
                                <i className="animate"></i>
                            </div>
                        </div>
                        <div className="episode-card skeleton">
                            <div className="banner">
                                <div className="img animate"></div>
                            </div>
                            <div className="detail">
                                <span className="animate"></span>
                                <p className="animate"></p>
                                <i className="animate"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
