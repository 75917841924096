import React from "react";
import { Link } from "react-router-dom";

export default function SliderCard(props) {
	return (
		props.lazyLoad === false ?
			props.URL ?
				<Link
					to={props.URL}
					className={`item ${props.focused ? "selected" : ""}`}>
					<img src={props.imageURL} alt={props.title} />
				</Link>
				:
				<button
					type="button"
					className={`item ${props.focused ? "selected" : ""}`}
					onClick={props.onClick}
				>
					<img src={props.imageURL} alt={props.title} />
				</button>
			:
			<Link
				to={props.URL}
				className={`item ${props.focused ? "selected" : ""}`}>
				<img className="lazy-load-slider-image" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={props.imageURL} alt={props.title} />
			</Link>
	);
}
