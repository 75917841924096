import React, { useEffect, useState } from "react";
import * as Constants from "../constants/constants";
import Button from "../components/Button/button";
import withDirectionFocusable from "../components/withDirectionFocusable";
import { useNavigate } from "react-router-dom";
import { exit as exitForTizen } from "../helper/platforms/tizen";
import { platformBack as platformBackWebOS } from "../helper/platforms/webos";

const ButtonFocusable = withDirectionFocusable(Button);

function BackKeyHandler(props) {
	const navigate = useNavigate();
	const [showExitConfirmation, setShowExitConfirmation] = useState(false);
	const [declareEvent, setDeclareEvent] = useState(false);

	// Platforma göre ve ana ekranda olup/olmadığına göre aksiyonlar aldırılıyor.
	// Login ya da Ana ekrandayken Back tuşuna Vestel ve Tizen'da basıldığında uygulamadan çıkmak istiyor musun diye soruluyor.
	// Login ya da Ana ekranda değilse ve Back tuşuna webOS'da basıldığında bir önceki ekrana yönlendiriliyor. (appinfo.json dosyasında "disableBackHistoryAPI": true olduğu için back tuşunu handle etmek gerekiyor. "disableBackHistoryAPI": false olduğunda ise window.webOS.platformBack() çalışmaz.)
	// Vestel'de back tuşuna basıldığında "Çıkmak istediğine emin misin?" ekranı çıkarabilmek için keypress eventinde back tuşu işlevi durduruluyor ve keydown eventi ile back tuşu handle ediliyor.

	useEffect(() => { 
		return () => {
			window.platform === "vestel" &&
				document.removeEventListener("keypress", ignoreKeyPress);
		};
	}, []);

	// Vestel/Samsung için ana sayfada back tuşuna basıldığında "Çıkmak istediğine emin misin?"" ekranı açtırılır.
	useEffect(() => {
		showExitConfirmation === true && props.setFocus("CancelButton");
		if (declareEvent === false) {
			//document.addEventListener("keydown", handleRemoteButtons, { passive: true });
            
			document.onkeydown = handleRemoteButtons;
			// ignoring regular keypress event selective : ref: https://developer.foxxum.com/docs/3_navigation/
			(window.platform === "vestel" || window.platform === "tizen") && document.addEventListener("keypress", ignoreKeyPress, true);
		}
	}, [showExitConfirmation]);

	function isInAllowedPage() {
		return (
			window.location.hash === "" ||
			window.location.hash === "/#/" ||
            window.location.hash === "#/" || // webos bu şekilde başında / olmadan verebiliyor
			window.location.hash === "/index.html" ||
			window.location.hash === "/index.html#/" ||
            window.location.hash === "/#/qr-login" ||
            window.location.hash === "#/qr-login" // webos bu şekilde başında / olmadan verebiliyor
		);
	}

    const goToPreviousScreen = () => {
        if (window.history.state && window.history.state.idx > 0) {
            // console.log("oo");
			navigate(-1);
		} else {
            // console.log("pp");
			navigate("/", { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
		}
    };

	function handleRemoteButtons(event) {
        // TV'de ekrana test amaçlı basmak için : document.getElementById('email').value = isInAllowedPage() + "***" + window.location.hash;
        //document.getElementById('email').value = window.VK_BACK;

		// vestel için
		if (window.platform === "vestel" && ((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) ||  event.keyCode === 461 || event.keyCode === 465 /*event.keyCode === 8*/ )     
            && isInAllowedPage() === true) {
                document.activeElement.blur();
				setShowExitConfirmation(true);
				setDeclareEvent(true);
				return false;
		}
		else if (window.platform === "vestel" && ((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) ||  event.keyCode === 461 || event.keyCode === 465 || event.keyCode === 221 ) && document.querySelector("#video-main-wrapper.video-bottom-toggle-box-active")) { 
		// "Back Button" tarayıcıda test edilebilmesi için tarayıcıda "Ü(221)" harfi keyCode değeri eklendi.      
			const target = document.getElementById("video-main-wrapper");	
			if(target.classList.contains("video-bottom-toggle-box-active")){
				props.setFocus("VideoPlayButton");
				const x = setTimeout(() => {
					target.classList.remove("video-bottom-toggle-box-active")
					clearTimeout(x);
				}, 200)
				target.classList.remove("track")
				target.classList.remove("subtitle")
				target.classList.remove("disable-controls")
			}
			return false;
		}  
		else if(window.platform === "vestel" && ((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) ||  event.keyCode === 461 || event.keyCode === 465 /*event.keyCode === 8*/ ) && window.titlePageURL != undefined) {
			// Video sayfasındayken geri tuşuna basıldıysa videonun bağlı olduğu title sayfasına yönlendirilir.
			navigate(window.titlePageURL, { replace: true });
			try {
				delete window.titlePageURL;
			}
			catch {
				window.titlePageURL = undefined;
			}
			return false;
		}

        
		// webos için
		if (window.platform === "webos" && 
            ((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) || event.keyCode === 461 || event.keyCode === 465) &&
            isInAllowedPage() === true) {
            platformBackWebOS();
            return false;
		} 
		else if (
			window.platform === "webos" &&
			((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) || event.keyCode === 461 || event.keyCode === 465) &&
			document.querySelector("#video-main-wrapper.video-bottom-toggle-box-active")
		) {
			const target = document.getElementById("video-main-wrapper");	
			if(target.classList.contains("video-bottom-toggle-box-active")){
				props.setFocus("VideoPlayButton");
				const x = setTimeout(() => {
					target.classList.remove("video-bottom-toggle-box-active")
					clearTimeout(x);
				}, 200)
				target.classList.remove("track")
				target.classList.remove("subtitle")
				target.classList.remove("disable-controls")
			}
			return false;
		}
        else if (
			window.platform === "webos" &&
			((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) || event.keyCode === 461 || event.keyCode === 465) &&
			isInAllowedPage() === false
		) {
			if(window.titlePageURL != undefined)
			{
				// Video sayfasındayken geri tuşuna basıldıysa videonun bağlı olduğu title sayfasına yönlendirilir.
				navigate(window.titlePageURL, { replace: true });
				try {
					delete window.titlePageURL;
				}
				catch {
					window.titlePageURL = undefined;
				}
				return false;
			}
			else{
				goToPreviousScreen();
			}
		}

		// tizen için
		// tizen key code'ları: https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html
		if (
			window.platform === "tizen" &&
			((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) || event.keyCode === 10009 || event.keyCode === 0) &&
			isInAllowedPage() === true
		) {
            document.activeElement.blur();
			setShowExitConfirmation(true);
			setDeclareEvent(true);
			return false;
		} else if (
			window.platform === "tizen" &&
			((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) || event.keyCode === 10009 || event.keyCode === 0) &&
			document.querySelector("#video-main-wrapper.video-bottom-toggle-box-active")
		){
			const target = document.getElementById("video-main-wrapper");	
			if(target.classList.contains("video-bottom-toggle-box-active")){
				props.setFocus("VideoPlayButton");
				const x = setTimeout(() => {
					target.classList.remove("video-bottom-toggle-box-active")
					clearTimeout(x);
				}, 200)
				target.classList.remove("track")
				target.classList.remove("subtitle")
				target.classList.remove("disable-controls")
			}
			return false;
		}else if (
			window.platform === "tizen" &&
			((window.VK_BACK !== undefined && event.keyCode == window.VK_BACK) || event.keyCode === 10009 || event.keyCode === 0) &&
			isInAllowedPage() === false
		) {
            if(window.titlePageURL != undefined)
			{
				// Video sayfasındayken geri tuşuna basıldıysa videonun bağlı olduğu title sayfasına yönlendirilir.
				navigate(window.titlePageURL, { replace: true });
				try {
					delete window.titlePageURL;
				}
				catch {
					window.titlePageURL = undefined;
				}
				return false;
			}
			else{
				goToPreviousScreen();
			}
		}
	}

	function ignoreKeyPress(e) {
        //var isFocusOnInput = document.activeElement !== null  && document.activeElement.hasAttribute("type") === true;

		if (window.platform === "vestel" && isInAllowedPage()) {
			// sadece ana sayfada çalışıyor
			switch (e.keyCode) {
				// prevent keyPress events keys from being interpreted by platform browser
				//case 1009: // Tizen
				//case 8: // Vestel: VK_BACK_SPACE = 8
				case 461: // Vestel: VK_BACK = 461
                case window.VK_BACK:
					e.preventDefault();
					break;
			}
		}
	}

	// Çıkmak istediğine eminse:
	function handleExit() {
		if (window.platform === "tizen") {
			// ref: https://developer.tizen.org/forums/web-application-development/remote-web-application-exit
			exitForTizen();
		} else if (window.platform === "vestel") {
			window.close();
		}

		// if(document.referrer == "") { window.close(); } // if there is no refferer url, close the app
		// else{ window.location.assign(document.referrer) } // if there is a refferer url, go back to referrer page
	}

	// çıkmaktan vazgeçtiğinde
	const handleCancelClick = () => {
		setShowExitConfirmation(false);
		props.setFocus(props.backFocus);
	};

	return showExitConfirmation === true ? (
		<div className="text-page-wrapper">
			<div>
				<h3>Çıkış yapmak istediğinize emin misiniz?</h3>
				<div className="btn-wrapper">
					<ButtonFocusable
						type="button"
						id={"ExitButton"}
						focusKey={"ExitButton"}
						right={"CancelButton"}
						className={Constants.BUTTON_MEDIUM + Constants.BUTTON_LOGOUT}
						title={"Çıkış Yap"}
						onEnterPress={() => {
							handleExit();
						}}
						onClick={() => {
							handleExit();
						}}
					/>
					<ButtonFocusable
						type="button"
						id={"CancelButton"}
						focusKey={"CancelButton"}
						left={"ExitButton"}
						className={
							Constants.BUTTON_MEDIUM + Constants.BUTTON_CANCEL_LOGOUT
						}
						title={"Vazgeç"}
						onEnterPress={handleCancelClick}
						onClick={handleCancelClick}
					/>
				</div>
			</div>
		</div>
	) : null;
}

export default BackKeyHandler;
