import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "universal-cookie";
import Button from "../components/Button/button";
import Countdown from "../components/CountDown";
import withDirectionFocusable from "../components/withDirectionFocusable";
import BackKeyHandler from "../components/backKeyHandler";
import * as Constants from "../constants/constants";
import { getPlatform } from "../helper/platform";
import { getGenerateCode, getQRCheck } from "../services/gainAPI";
import {
	pageView
} from "../services/gtm";
const ButtonFocusable = withDirectionFocusable(Button);
const BackKeyHandlerFocusable = withDirectionFocusable(BackKeyHandler);

export default function QRLogin(props) {
	const navigate = useNavigate();
	const [qrCode, setQRCode] = useState(null);
	const [tvCode, setTvCode] = useState(null);
	const [qrViewCount, setQRViewCount] = useState(0);
	const [cacheDuration, setCacheDuration] = useState(null);
	const [isPremium, setIsPremium] = useState(null);
	const [generateCode, setGenerateCode] = useState(false);

	const splitCode = (code) => {
		if (code === null) {
			// Code set edilene kadar boş kutular görünüyor.
			return (
				<>
					<span className="letter-box"></span>
					<span className="letter-box"></span>
					<span className="letter-box"></span>
					<span className="letter-box"></span>
					<span className="letter-box"></span>
					<span className="letter-box"></span>
				</>
			);
		} else {
			return code.split('').map((char, index) => (
				<span key={index} className="letter-box">{char}</span>
			))
		}
	}

	useEffect(() => {
		document.title = "QR ile Giriş yapın";
		pageView();
		props.setFocus("Login");

		setGenerateCode(true);
	}, []);

	useEffect(() => {
		if (tvCode !== null && cacheDuration !== null) {
			async function fetchData() {
				try {
					const checkResult = await getQRCheck(tvCode);
					// console.log("getQRCheck check", checkResult);
					if (checkResult === 403) {
						// console.log("Bekleniyor...");
						return;
					} else if (checkResult === 404) {
						// log("Süresi dolmuş bir QR kodu"); 
						if(qrViewCount < 3){
							setGenerateCode(true);
						}
						else{
							navigate("/timeout-warning", { replace: true }); 
						}
						return;
					} else if (checkResult === 429) {
						// console.log("Çok sayıda deneme yaptınız"); 
						navigate("/too-many-request-warning", { replace: true }); 
						return;
					}

					if (checkResult !== null) {
						// Kullanıcı onaylandı , Kullanıcı cookie'si set edilip sayfa yönlendirmesi yapılıyor.
						// console.log("IcheckResultData:", checkResult.data);
						var cookie = new Cookie();
						cookie.set("uuid", checkResult.data.account.uuid, {
							path: "/",
							maxAge: 60 * 60 * 24 * 90,
						}); // 90 gün
						cookie.set("access_token", checkResult.data.access_token, {
							path: "/",
							maxAge: 60 * 60 * 24 * 90,
						}); // 90 gün
						cookie.set("refresh_token", checkResult.data.refresh_token, {
							path: "/",
							maxAge: 60 * 60 * 24 * 90,
						}); // 90 gün

						setIsPremium(checkResult.data.account.isPremium);
						clearInterval(x);
					}

				} catch (error) {
					// console.error("Hata oluştu: ", error);
				}
			}

			fetchData();
			// QR Check için setInterval ile check ediliyor.
			const x = setInterval(fetchData, 5000);
			return () => {
				clearInterval(x);
			};
		}
	}, [tvCode, cacheDuration]);


	useEffect(() => {
		if (isPremium !== null) { // && tvCode !== null
			if (isPremium) {
				navigate("/", { replace: true }); 
			} else {
				navigate("/free-account-warning", { replace: true }); 
			}
		}
	}, [isPremium]);

	useEffect(() => {
		if (generateCode === true) {
			if(qrViewCount < 2){
				getGenerateCode().then(function (result) {
					// log("getGenerateCode result:", result);
					if (result !== null) {
						setQRViewCount(qrViewCount + 1);
						setQRCode(result.qrCode);
						setTvCode(result.tvCode);
						setGenerateCode(false);
						setCacheDuration(result.cacheDuration);
						//setCacheDuration(20);
					}
					else{
						setGenerateCode(false);
						// console.log("Çok sayıda deneme yaptınız"); 
						navigate("/too-many-request-warning", { replace: true }); 
						return;
					}
				});
			}
			else{
				navigate("/timeout-warning", { replace: true }); 
			}
		}
	}, [generateCode]);

	// Keydown Events
	useEffect(() => {
		function keyDown(e) {
			// console.log(e.keyCode);
			// sanal klavyede enter tuşuna basıldığında bir sonraki alana focuslanması sağlanır.
			if (
				(e.keyCode === 13 || e.keyCode === window.VK_ENTER) &&
				getPlatform().name !== "arcelik"
			) {
				if (document.activeElement.getAttribute("id") === "email") {
					document.getElementById("password").focus();
					props.setFocus("Password");
				} else if (
					document.activeElement.getAttribute("id") === "password" &&
					document.querySelectorAll(".password-switcher.selected")
						.length === 0
				) {
					document.getElementById("passwordToggle").focus();
					props.setFocus("PasswordVisibility");
					// document.getElementById("LoginButton").focus();
					// props.setFocus("LoginButton");
				}
			}
		}

		window.addEventListener("keydown", keyDown);
		return () => {
			window.removeEventListener("keydown", keyDown);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function counterEnd() {
		// console.log("sayaç bitti");
		setGenerateCode(true);
	}

	return (
		<div className="wrapper qr-login-wrapper">
			{(window.platform === "vestel" ||
				window.platform === "tizen" ||
				window.platform === "webos") && (
					<BackKeyHandlerFocusable // todo
						focusKey={"ExitConfirmationFocusable"}
						backFocus={"Login"}
					/>
				)}

			<span id="logo-gain"></span>

			<div className="content">
				<span className="primary-title mb">
					GAİN’e üye olmadıysan gain.tv adresinden üyelik oluştur.
				</span>
				<h2 className="mb">Adımları Takip Ederek Kolayca Giriş Yap</h2>
				<div className="qr-login-main">
					<div className="left-huge">
						<div className="counter-item first">
							<span className="count">1</span>
							<div>
								<span className="primary-title">
									Tarayıcınızı açın, aşağıdaki linke gidin
								</span>
								<h2>gain.tv/pin</h2>
							</div>
						</div>
						<div className="counter-item second">
							<span className="count mt">2</span>
							<div>
								<span className="secondary-title mb">
									Aktivasyon kodunu gir
								</span>
								<div className="letter-huge">
									{tvCode !== null ? splitCode(tvCode) : splitCode(null)}
								</div>
								<div className="counter-text">
									Kodun geçerlilik süresi&nbsp;
									{(cacheDuration) && <Countdown initialSeconds={cacheDuration} onCountdownComplete={counterEnd} />
									}
								</div>
							</div>
						</div>
					</div>
					<div className="center-huge">
						<span className="line-elem"></span>
						<span className="primary-title">VEYA</span>
						<span className="line-elem"></span>
					</div>
					<div className="qr-code-box">
						<span className="secondary-title">
							Mobil cihazınızın kamerasını kullanarak QR kodunuzu
							okutunuz.
						</span>
						<img width={250} height={250} src={qrCode !== null ? qrCode : Constants.loader} alt=" " />
					</div>
				</div>
				<ButtonFocusable
					focusKey={"Login"}
					id={"Login"}
					type="submit"
					className="btn btn-medium"
					title={"TV Kullanarak Giriş Yap"}
					up={""}
					// disabled={!validateForm()}
					onBecameFocused={(e) => {
						// document.getElementById("LoginButton").focus();
					}}
					onClick={() => {
						navigate("/login", { replace: true });
					}}
					onEnterPress={() => {
						navigate("/login", { replace: true });
						// props.setFocus("Outlet");
					}}
				/>
			</div>
		</div>
	);
}
