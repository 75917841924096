// ref: https://developer.samsung.com/smarttv/develop/api-references/samsung-product-api-references/webapi-api.html
// önemli not: kullanılacak özelliğe göre config.xml'de privelege set edilmesi gerekiyor. 


// multitasking 
// ref: https://developer.samsung.com/smarttv/develop/guides/fundamentals/multitasking.html
// ref: https://developer.samsung.com/smarttv/develop/migrating-applications/migrating-legacy-platform-to-tizen.html
// document.addEventListener("visibilitychange",
//   function() {
//     if (document.hidden) {
//       // Something you want to do when application is paused 
//       console.log("lifecycle [pause]");

//       // This code handles playing media when application is hidden
//       // webapis.avplay.suspend();
//     } 
//     else { 
//       // Something you want to do when application is resumed
//       console.log("lifecycle [resume]");

//       // This code handles playing media when application is visible
//       // webapis.avplay.restore();
//     }
//   }
// );

export function init() {
	if (window.webapis !== undefined && window.webapis.productinfo !== undefined) {
		window.modelName = getDeviceName();
		window.deviceID = getUID();
		window.firmware = getFirmware();
		// var version = getVersion();
		// window.version = version !== null ? version : window.version; // api ile çekilemediyse useragent'tan çekilen değer kalır.
	}
}

function getDeviceName() {
	var value = "unknown";
	try {
		value =
			window.webapis.productinfo.getRealModel() + // UNU7400
			", " +
			window.webapis.productinfo.getModelCode(); // 18_KANTME_UHD
	} catch {
		value = "Device bilgisi alınamadı.";
	}
	return value;
}

function getFirmware() {
	var value = "unknown";
	try {
		value = String(window.webapis.productinfo.getFirmware()); // T-KTM2DEUC-1202.3
	} catch (error) {
		value = "Firmware bilgisi alınamadı.";
		//console.log(" error code = " + error.code);
	}
	return value;
}

// ref: https://developer.tizen.org/forums/web-application-development/how-get-tizen-version-webapi
// function getVersion() {
// 	var value = "unknown";
// 	try {
// 		value = String(window.tizen.systeminfo.getCapability('http://tizen.org/feature/platform.version')); // tizen 5.5 olan cihazda 3.5 veriyor???
// 	} catch (e) {
// 		value = "Version bilgisi alınamadı.";
// 		//console.log("getVersion exception [" + e.code + "] name: " + e.name + " message: " + e.message);
// 	}
// 	return value;
// }

function getUID() {
	var value = "unknown";
	//console.log("ConnectionType: " + window.webapis.network.getActiveConnectionType());
	try {
		value = window.webapis.network.getMac();
	} catch (e) {
		value = "UID bilgisi alınamadı.";
		//console.log("getMAC exception [" + e.code + "] name: " + e.name + " message: " + e.message);
	}
	return value;
}

function getAppID() {
	var value = "unknown";
	try {
		value = String(window.tizen.application.getAppInfo().version);
	} catch (e) {
		value = "App ID bilgisi alınamadı.";
		//console.log("getAppInfo exception [" + e.code + "] name: " + e.name + " message: " + e.message);
	}
	return value;
}




// function getIP() {
// 	return window.webapis.network && "function" == typeof window.webapis.network.getIp
// 		? window.webapis.network.getIp()
// 		: "";
// }

// function getLocalSet() {
// 	return window.webapis.productinfo
// 		? String(window.webapis.productinfo.getLocalSet()) // EU_LITHUANIA
// 		: null;
// }

// function getDuid() {
//     // 4K cihazlar
//    return window.webapis.productinfo.getDuid();
// }

// function isUdPanelSupported() {
//      // 4K cihazlar
// 	return window.webapis.productinfo.isUdPanelSupported();
// }

// function is8KPanelSupported() {
//     // 8K cihazlar
// 	return window.webapis.productinfo.is8KPanelSupported();
// }

// function getCountry() {
// 	var e = null;
// 	try {
// 		e = this.LOCALE.country;
// 	} catch (t) {
// 		e = String(
// 			window.location.search.match(/country=([\w\-\_]+)/)[1]
// 		).toLowerCase();
// 	}
// 	return e;
// }

export function exit() {
	if (window.tizen && window.tizen.application) {
		window.tizen.application.getCurrentApplication().exit();
	}
}

export function checkInternet() {
	return window.webapis.network.isConnectedToGateway();
}

export function getSupportedKeys() {
	return window.tizen.tvinputdevice.getSupportedKeys(); // ref: https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html
}

// npm paketi ile eklendiğinden gerek kalmadı.
// export function addWebApis() {
// 	var s = document.createElement("script");
// 	s.type = "text/javascript";
// 	s.src = "$WEBAPIS/webapis/webapis.js";
// 	//s.async = true;
// 	document.head.appendChild(s);
// }

// {
//     KEY_LEFT: 37,
//     KEY_RIGHT: 39,
//     KEY_UP: 38,
//     KEY_DOWN: 40,
//     KEY_ENTER: 13,
//     KEY_BACK: 0,
//     KEY_RETURN: 10009,
//     KEY_0: u[0],
//     KEY_1: u[1],
//     KEY_2: u[2],
//     KEY_3: u[3],
//     KEY_4: u[4],
//     KEY_5: u[5],
//     KEY_6: u[6],
//     KEY_7: u[7],
//     KEY_8: u[8],
//     KEY_9: u[9],
//     KEY_RED: u.ColorF0Red,
//     KEY_GREEN: u.ColorF1Green,
//     KEY_YELLOW: u.ColorF2Yellow,
//     KEY_BLUE: u.ColorF3Blue,
//     KEY_PLAY: u.MediaPlay,
//     KEY_PAUSE: u.MediaPause,
//     KEY_STOP: u.MediaStop,
//     KEY_RECORD: u.MediaRecord,
//     KEY_FF: u.MediaFastForward,
//     KEY_RW: u.MediaRewind,
//     KEY_PLAY_PAUSE: u.MediaPlayPause,
//     KEY_TOOLS: u.Tools,
//     KEY_CH_UP: u.ChannelUp,
//     KEY_CH_DOWN: u.ChannelDown,
//     KEY_CHLIST: u.ChannelList,
//     KEY_PRECH: u.PreviousChannel,
//     KEY_TTX_MIX: u.Teletext,
//     KEY_EXIT: u.Exit,
//     KEY_INFO: u.Info
// }
