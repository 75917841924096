import React from "react";
import { Link } from "react-router-dom";

export default function BackLink(props) {
  return (
    <>
      <Link
        to={props.link}
        className={`back-button ${props.focused ? "selected" : ""}`}
      >
        {props.firstText}
        {props.icon}
        basın
      </Link>
    </>
  );
}
