import React from "react";

export default function settingsProfileSkeleton(props) {

    return (
        <>
            <div className="setting-wrapper skeleton">
                <h3>Profil</h3>
                <div className="user-profile-card">
                    <div className="user-profile-svg animate"> </div>
                    <div>
                        <h4 className="animate"></h4>
                        <span className="email-title animate"></span>
                    </div>
                </div> 
                <span className="btn btn-medium btn-sign-out animate"></span>
            </div>
        </>
    );
}
