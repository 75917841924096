import React, { useEffect,useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionIcon from "../components/subscriptionIcon";
import withDirectionFocusable from "../components/withDirectionFocusable";
import BackLink from "../components/Button/backLink";
import {  
	getSubscriptionDetails, 
} from "../services/gainAPI";
import { MediaContext } from "../provider/generalProvider";
import { pageView } from "../services/gtm";
import SettingsSubscriptionSkeleton from "../components/Skeleton/settingsSubscription";

const BackLinkFocusable = withDirectionFocusable(BackLink);

function SettingsSubscription(props) {
    const navigate = useNavigate();
    const [isPremium,setIsPremium] = useState(null);
    const [isPremiumIcon,setIsPremiumIcon] = useState(null);
    const [premiumDetail,setPremiumDetail] = useState(null); 
    const [currentPlan,setCurrentPlan] = useState(null); 
    const { changeSidebarItem } = useContext(MediaContext);

    useEffect(() => { 
        document.title = "Abonelik";
        pageView();
        props.setFocus("MENU-1");
        
         getSubscriptionDetails().then(function(result){
            setPremiumDetail(result);
        });  
        changeSidebarItem("subscription"); 
    }, []); 
     

    useEffect(() => {   
        if(premiumDetail !== null){
            //console.log(premiumDetail);
            if(premiumDetail.isPremium === true){ // Premium Aktif
                setIsPremium(true);
                setIsPremiumIcon('true');
                if(premiumDetail.currentPlan === 'monthly'){  // Paket Bilgisi
                    setCurrentPlan('Aylık Paket'); 
                }else{
                    setCurrentPlan('Yıllık Paket'); 
                }
            }else{ // Premium Aktif Değil
                setIsPremium(false);
                setIsPremiumIcon('false');
            }
        } 
    }, [premiumDetail]);

	return (
		<div className="main-wrapper settings">
            {/* <LoadingFullscreen show={loading} /> */}
			<span id="logo-gain"></span>
            
            <BackLinkFocusable
				left={"SettingSidebar"}
				down={"MENU-0"}
				focusKey={"BackButton"}
				link={"/"}
				onEnterPress={() => {
					navigate(-1);
				}}
				firstText={"geri için"}
				icon={
					<i>
						<svg
							width="34"
							height="34"
							viewBox="0 0 34 34"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.6667 -8.46519e-05C25.8714 -8.50542e-05 33.3333 7.46184 33.3333 16.6666C33.3333 25.8713 25.8714 33.3333 16.6667 33.3333C7.46192 33.3333 -3.26171e-07 25.8713 -7.28523e-07 16.6666C-1.13088e-06 7.46184 7.46192 -8.42495e-05 16.6667 -8.46519e-05ZM8.39289 15.4073C8.12322 15.751 7.9614 16.1833 7.95841 16.6533L7.95837 16.6665L7.95837 16.6666C7.95837 17.2374 8.19262 17.7535 8.57023 18.124L15.223 24.7768C16.0203 25.5741 17.3131 25.5741 18.1104 24.7768C18.9077 23.9795 18.9077 22.6868 18.1104 21.8894L14.9292 18.7083L23.3334 18.7083C24.461 18.7083 25.375 17.7942 25.375 16.6666C25.375 15.539 24.461 14.6249 23.3334 14.6249L14.9289 14.6249L18.1104 11.4435C18.9077 10.6461 18.9077 9.35343 18.1104 8.55611C17.3131 7.75879 16.0203 7.75879 15.223 8.55611L8.55636 15.2228C8.4979 15.2812 8.44335 15.3429 8.39289 15.4073Z"
								fill="currentColor"
							/>
						</svg>
					</i>
				}
			/>
             

            {premiumDetail ?  
                <div className="setting-wrapper">
                    <h3>Abonelik</h3>
                    <div className="subscription-wrapper">
                        <SubscriptionIcon active={isPremiumIcon} />

                        {isPremium === true && (
                            <div className="info-area">
                                <h4>Abonelik Durumu: Aktif</h4>
                                <span>{currentPlan}</span>

                                {premiumDetail.lastBillingDate && (
                                        <span>Başlangıç: {premiumDetail.lastBillingDate}</span> 
                                    )
                                }
                            </div>
                        )}
                        {isPremium === false && (
                            <div className="info-area not-premium">
                                <h4>Abonelik Yok</h4>
                            </div>
                        )}
                    </div>
                    {isPremium === true && (
                        <>
                            {premiumDetail.promotionEndDate !== null && (
                                <>
                                    <h3>Ödeme</h3>
                                    <div className="subscription-payment-note">
                                        <p>
                                            Promosyon koduyla başlattığınız aboneliğinizi
                                            &nbsp;{premiumDetail.nextBillingDate}&nbsp;tarihine kadar kullanabilirsiniz.
                                        </p>
                                    </div>
                                </>
                            )}
                            
                        </>
                    )}
                    {isPremium === false && (
                        <>
                            <h3>Nasıl Abone Olurum?</h3>
                            <div className="subscription-payment-note">
                                <p>
                                gain.tv web sitesinden veya mobil uygulamamız üzerinden profilinde yer alan "Abone Ol" butonuna tıklayarak abone olabilirsin.
                                </p>
                            </div>
                        </>
                    )}
                </div> 
            : <SettingsSubscriptionSkeleton /> }
            
		</div>
	);
}

export default SettingsSubscription;
