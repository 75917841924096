import React, { useState, useEffect, useLayoutEffect, useContext } from "react";

export default function FeaturedMediaSummary(props) {
    return (
        <>
            <div className="img-container skeleton  animate"></div>
            <div id="featured-media-summary" className="media-summary skeleton">
                <div className="logo animate"></div>
                <div className="line sm animate"></div>
                <div className="line lg animate"></div>

                {(props.btnSet === true) && (
                    <div className="title-buttons">
                        <div className="title-btn-container">
                            <a className="btn btn-medium show-watch-now-button btn-full-width selected animate" href="#"><i></i></a>
                            <div className="bottom-btn-wrapper">
                                <button type="button" className="btn btn-medium add-to-list-btn null animate"><i></i></button>
                                <button type="button" className="btn-circle animate"></button>
                                <button type="button" className="btn-circle animate"></button>
                            </div>
                        </div>
                    </div>
                )} 
            </div>
        </>
    );
}
