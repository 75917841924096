import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";

// fonksiyonları çağırırken başına şunu eklemeyi unutma -> window.google_tag_manager &&

export function pageView() {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		//event: 'pageview',
		event: "virtual_pageview",
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown",
		page: {
			url: window.location.href,
			title: document.title
		}
	});
}

export function login(uuuid, createdAt, lastBillingDate, isPremium) {
	var formattedCreatedAt = dayjs
		.unix(createdAt)
		.format("DD-MM-YYYY")
		.toString();
	//console.log("formattedCreatedAt", formattedCreatedAt);

	var diffDays = 0; // default value
	if (lastBillingDate !== null && lastBillingDate !== "") {
		dayjs.extend(customParseFormat);
		lastBillingDate = dayjs(lastBillingDate, "YYYY-MM-DD HH:mm:ss");
		//console.log("lastBillingDate", lastBillingDate);
		var now = dayjs();
		diffDays = now.diff(lastBillingDate.format("MM-DD-YYYY"), "day");
		//console.log("diffDays", diffDays);
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		userId: uuuid,
	});

	window.dataLayer.push({
		event: "login",
		user_premium: isPremium,
		register_date: formattedCreatedAt,
		user_last_purchase: diffDays,
        //'subscription_type': true
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

export function loginFailed(message) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "login_failed",
		message: message,
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

export function logout(createdAt, lastBillingDate, isPremium) {
	var formattedCreatedAt = dayjs
		.unix(createdAt)
		.format("DD-MM-YYYY")
		.toString();
	//console.log("formattedCreatedAt", formattedCreatedAt);

	var diffDays = 0; // default value
	if (lastBillingDate !== null && lastBillingDate !== "") {
		dayjs.extend(customParseFormat);
		lastBillingDate = dayjs(lastBillingDate, "YYYY-MM-DD HH:mm:ss");
		//console.log("lastBillingDate", lastBillingDate);
		var now = dayjs();
		diffDays = now.diff(lastBillingDate.format("MM-DD-YYYY"), "day");
		//console.log("diffDays", diffDays);
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "log_out",
		user_premium: isPremium,
		user_register_date: formattedCreatedAt,
		user_since_last_purchase: diffDays,
		//'subscription_type': true
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

export function rating(
	videoName,
	isTitle,
	titleID,
	mediaID,
	category,
	newRate,
	previousRate
) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "like_button",
		video_name: videoName,
		is_title: isTitle,
		title_id: titleID,
		video_id: mediaID,
		title_video_category: category,
		rate: newRate,
		previous_rate: previousRate,
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

export function videoWatchEvent(
	action,
	videoName,
	isLive,
	episode,
	season,
	mediaID,
	titleID,
	category,
	currentTime,
	duration
) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "video_watch_time",
		video_name: videoName,
		is_live: isLive,
		video_episode: episode !== undefined ? episode : "",
		video_season: season !== undefined ? season : "",
		video_id: mediaID,
		title_id: titleID,
		video_category: category,
		video_action: action,
		video_rate: (currentTime / duration) * 100,
		last_engagement_duration_seconds: currentTime,
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

export function videoRewind(videoName, episode, season, mediaID, currentTime) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "video_rewind",
		video_name: videoName,
		video_episode: episode !== undefined ? episode : "",
		video_season: season !== undefined ? season : "",
		video_id: mediaID,
		last_engagement_duration_seconds: currentTime,
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

export function videoSkipIntro(videoName, episode, season, mediaID, category) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "skip_intro",
		video_name: videoName,
		video_episode: episode !== undefined ? episode : "",
		video_season: season !== undefined ? season : "",
		video_id: mediaID,
		video_category: category,
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

export function videoNextEpisode(
	videoName,
	episode,
	season,
	mediaID,
	category,
	currentTime
) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "next_episode",
		video_name: videoName,
		video_episode: episode !== undefined ? episode : "",
		video_season: season !== undefined ? season : "",
		video_id: mediaID,
		video_category: category,
		last_engagement_duration_seconds: currentTime,
        platform: window.platform !== undefined ? window.platform : "unknown",
        version: window.version !== undefined ? window.version : "unknown",
        brand: window.brand !== undefined ? window.brand : "unknown",
        deviceID : window.deviceID !== undefined ? window.deviceID : "unknown",
        modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}
