 import React, { useState, createContext } from "react";

export const MediaContext = createContext({
    ActiveSidebarItem: null, // sidebar'da seçili itemı belirlemek için kullanılıyor.
    changeSidebarItem: () => {},  // sidebar'da seçili itemı değiştirmek için kullanılıyor.

    SearchTextContext : "", // search sayfasında kullanılıyor
	changeSearchInput: () => {}, // search sayfasında kullanılıyor
});

const GeneralProvider = (props) => {
    const [ActiveSidebarItem, setActiveSidebarItem] = useState(null); 
	const [SearchTextContext, setSearchTextContext] = useState("");

    const changeSidebarItem = (value) => {
		setActiveSidebarItem(value);
    }; 

	const changeSearchInput = (value) => {
		setSearchTextContext(value);
	};
    
	// eslint-disable-next-line
	return (
		<MediaContext.Provider
			value={{
				changeSearchInput,
				SearchTextContext,

                ActiveSidebarItem,
                changeSidebarItem
			}}
		>
			{props.children}
		</MediaContext.Provider>
	);
};
export default GeneralProvider;
