import {
    getCoverImage,
    getLogoImage
} from "../helper/imageHelper";
import * as dayjs from "dayjs";

// playlist'teki itemlarda gezinildiğinde featured media'daki elementlerin değişmesi sağlanır.
export const changeFeaturedMediaInformations = (media) => {
    // console.log(media);

    if(media === null || media.metadata === null || document.getElementById('img-hero') === null){
        return;
    }

    var showContWatchBar = media.continueWatch !== undefined && media.continueWatch !== null && document.querySelector('.main-wrapper').classList.contains("scroll-active");
    
    // Kapak İmajı
    if(document.getElementById('img-hero') !== null){
        document.getElementById('img-hero').setAttribute('src', getCoverImage(media.metadata.images, 1920));
    }
    

    var logoImageURL = '';
    if (media.metadata.images.logos !== null && media.metadata.images.logos.length > 0) {
        logoImageURL = getLogoImage(media.metadata.images, 533);
    }

    // Logo ve Başlık
    var logoElem = document.getElementById('featured-media-logo');
    var titleElem = document.getElementById('featured-media-title');
    if (logoImageURL !== '') {
        logoElem.style.display = "block";
        logoElem.setAttribute('src', logoImageURL);
        titleElem.innerHTML = "<h2></h2>";
        titleElem.style.display = "none";
    } else {
        logoElem.style.display = "none";
        titleElem.innerHTML = "<h2>" + media.metadata.name + "</h2>";
        titleElem.style.display = "flex";
    }

    if (showContWatchBar) {
        // izlemeye devam et barı gösterilir
        document.getElementById("cont-watch-in-sticky-area").style.display = 'flex';
        var percent = Math.floor(media.continueWatch.position * 100) / Math.floor(media.metadata.duration);
        document.querySelector("#cont-watch-in-sticky-area i").style.setProperty('--percent', percent + '%');
        //console.log(Math.floor(media.metadata.duration) - Math.floor(media.continueWatch.position));
        
        if(typeof dayjs.duration !== "function"){
            // ref: https://day.js.org/docs/en/plugin/duration
            var duration = require('dayjs/plugin/duration');
            dayjs.extend(duration);
        }
        
        document.querySelector("#cont-watch-in-sticky-area span").innerHTML = Math.floor(dayjs.duration({ seconds: Math.floor(media.metadata.duration) - Math.floor(media.continueWatch.position) }).asMinutes()) + "dk kaldı";
    }
    else{
        document.getElementById("cont-watch-in-sticky-area").style.display = 'none';
    }

    // Açıklama
    if (!showContWatchBar) {
        document.getElementById("featured-media-description").style.display = '-webkit-box';
        document.getElementById("featured-media-description").innerHTML = media.metadata.description; 
    }
    else{
        document.getElementById("featured-media-description").style.display = 'none';
    }

    // Canlı Yayın
    document.getElementById('featured-media-live').style.display = media.type === 'live' ? "block" : "none";
    let featuredMediaInfoElem = document.getElementById('featured-media-info');
    featuredMediaInfoElem.style.display = "flex";

    if (media.type !== 'live' && !showContWatchBar) {

        // Gain Originals
        document.getElementById('featured-media-originals').style.display = media.metadata.isGainOriginals === true ? "flex" : "none";

        // Category
        document.getElementById('featured-media-category').innerHTML = media.metadata.category;

        // IMDB 
        if (media.metadata.imdbScore !== undefined && media.metadata.imdbScore !== null  && media.metadata.imdbScore !== "") {
            document.getElementById('featured-media-imdb-container').style.display = "flex";
            document.getElementById('featured-media-imdb').innerHTML = media.metadata.imdbScore;
        } else {
            document.getElementById('featured-media-imdb-container').style.display = "none";
        }

        // RTUK
        var rtukContainer = document.getElementById('rtuk-container');
        rtukContainer.innerHTML = ''; 
        if (media.metadata.imdbScore !== undefined && media.metadata.imdbScore !== null && media.metadata.imdbScore !== "") // eğer imdb bilgisi varsa son li değildir, imdb bilgisi yoksa sonuncu li'dir.
        {
            if (rtukContainer.classList.contains("li-last")) {
                rtukContainer.classList.remove("li-last")
            }
        } else { 
       
            if (rtukContainer.classList.contains("li-last") === false) {
                rtukContainer.classList.add("li-last")
            }
        }
        if (media.metadata.contentRatings !== undefined && media.metadata.contentRatings.length > 0) {
            rtukContainer.style.display = "block";
            var rtukHTML = "";
            for (let c = 0; c < media.metadata.contentRatings.length; c++) {
                rtukHTML += '<span key="rtuk-' + media.metadata.contentRatings[c] + '" class="rtuk ' + media.metadata.contentRatings[c] + '"></span>';
            }
            rtukContainer.innerHTML = rtukHTML;
        }else{ 
            rtukContainer.style.display = "none";
        }

        var categoryLi =  document.getElementById('featured-media-category').parentElement;
        if(media.metadata.imdbScore == null && media.metadata.imdbScore == null){
            if (categoryLi.classList.contains("li-last") === false) {
                categoryLi.classList.add("li-last")
            }

            if(media.metadata.contentRatings !== undefined && media.metadata.contentRatings.length > 0){
                if (categoryLi.classList.contains("li-last")) {
                    categoryLi.classList.remove("li-last")
                }
            }

        }else{
            if (categoryLi.classList.contains("li-last")) {
                categoryLi.classList.remove("li-last")
            } 
        }


    } else {
        featuredMediaInfoElem.style.display = "none";
    }
}

// main wrapper'a class ekler. featured media summary alanının sticky olmasını/olmamasını sağlar.
export const scrollActive = (show) => {
    var mainWrapper = document.querySelector(".main-wrapper");
    if (show === true) {
        if (mainWrapper.classList.contains("scroll-active") === false) {
            mainWrapper.classList.add("scroll-active");
            //console.log("add");
        }
    } else {
        mainWrapper.classList.remove("scroll-active");
        //console.log("remove");
    }
}