// ref: https://webostv.developer.lge.com/develop/code-samples/webostvjs-library/ (örnek proje zipi ve screenshotı var, ordan bakılabilir.)
// ref: https://www.npmjs.com/package/@procot/webostv
// ref: https://webostv.developer.lge.com/api/webostvjs/webos/
// ref: https://webostv.developer.lge.com/api/webos-service-api/tv-device-information/

// window.webOS.deviceInfo;
// window.webOS.fetchAppId;
// window.webOS.fetchAppInfo;
// window.webOS.fetchAppRootPath;
// window.webOS.keyboard;
// window.webOS.libVersion;
// window.webOS.platform;
// window.webOS.platformBack;
// window.webOS.service;
// window.webOS.systemInfo;

// export function iswebOSTV() {
// 	return window.webOS.platform.tv;
// }

export function init() {
    setAppID();
    setDeviceInformations();
    setDeviceID();
}

function setDeviceInformations() {
    // alternatif için: https://webostv.developer.lge.com/api/webos-service-api/tv-device-information/
    try {
        window.webOS.deviceInfo(function (device) {
            try {
                window.modelName = device.modelName;
            } catch {
                window.modelName = "unknown";
            }

            try {
                window.version = device.sdkVersion.split('.');
            } catch {
                // getPlatform fonksiyonu içerisinde dolduruluyor.
            }

        });
    } catch {}
}

function setAppID() {
    try {
        var appID = window.webOS.fetchAppId();
        window.appID = appID !== "" ? appID : "unknown";
    } catch {
        window.appID = "unknown";
    }
}

export function keyboardIsShowing() {
    // asenkron çalışıyor olabilir?
    var isShowing = false;
    try {
        isShowing = window.webOS.keyboard.isShowing();
    } catch {}
    return isShowing;
}

function setDeviceID() {
    try {
        window.webOS.service.request("luna://com.webos.service.connectionmanager", {
            method: "getinfo",
            parameters: {},
            onSuccess: function (args) {
                try {
                    let macEthernet = args.wiredInfo.macAddress;
                    let macWifi = args.wifiInfo.macAddress;
                    if (macWifi !== null || macWifi !== "") {
                        window.deviceID = macWifi;
                    } else {
                        window.deviceID = macEthernet;
                    }
                } catch (err) {
                    window.deviceID = 'unknown';
                }
            },
            onFailure: function (args) {}
        });
    } catch {}
}

export function platformBack() {
    window.webOS !== undefined && window.webOS.platformBack();
}