// kapak imajını döndürür, bulamazsa 1px'lik boş imaj döner.  cover sizes: 480, 640, 960, 1280, 1920
export const getCoverImage = (images, width) => {
    if (images !== undefined && images !== null && images.coverPhotos !== undefined && images.coverPhotos !== null) {
        var images = images.coverPhotos.filter((image) => image.width === width && image.type === 'horizontal');
        if (images != null && images.length > 0) {
            return images[0].url;
        } else {
            return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        }
    } else {
        return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    }
}

// logo  imajını döndürür, bulamazsa 1px'lik boş imaj döner. logo sizes: 200, 266, 400, 533, 800
export const getLogoImage = (images, width) => {
    if (images !== undefined && images !== null && images.coverPhotos !== undefined && images.coverPhotos !== null) {
        var images = images.logos.filter((image) => image.width === width && image.type === 'horizontal');
        if (images != null && images.length > 0) {
            return images[0].url;
        } else {
            return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        }
    } else {
        return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    }
}

// logo  imajını döndürür, bulamazsa 1px'lik boş imaj döner. thumbnails sizes: 480, 640, 960, 1280, 1920
export const getThumbnailImage = (images, width) => {
    if (images !== undefined && images !== null && images.thumbnails !== undefined && images.thumbnails !== null) {

        var filterImages = images.thumbnails.filter((image) => image.width === width && (image.type === 'horizontal' || image.orientation === 'horizontal'));

        if (filterImages.length == 0 && width == 480) {
            width = 320;
            var alernativeImages = images.thumbnails.filter((image) => image.width === width && (image.type === 'horizontal' || image.orientation === 'horizontal'));

            if (alernativeImages != null && alernativeImages.length > 0) {
                return alernativeImages[0].url;
            } else {
                return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
            }

        } else {
            if (filterImages != null && filterImages.length > 0) {
                return filterImages[0].url;
            } else {
                return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
            }
        }

    } else {
        return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    }
}