import React from "react";

export default function BackButton(props) { 
  return (
    <>
      <button
        to={props.link}
        type={props.type}
        className={`back-button ${props.extraClass} ${props.focused ? "selected" : ""}`}
        onClick={props.onClick} 
      >
        {props.firstText}
        {props.icon}
        {props.afterText || "basın" }
      </button>
    </>
  );
}
