import React,{useEffect} from "react";  
import withDirectionFocusable from "../components/withDirectionFocusable";
import imgBalloon from "../assets/img/premium-free-balloon.svg";
import * as Constants from "../constants/constants";
import Button from "./Button/button";
import { useNavigate } from "react-router-dom"; 
const ButtonFocusable = withDirectionFocusable(Button);

export default function PremiumFree(props) {
    const navigate = useNavigate();

    useEffect(() => {
      props.setFocus("PremiumFreeBackButton");

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
     
	return (
		<> 
             <div className={"premium-free"}>
                {props.img && <img src={props.img} className="bg-banner" alt=" " /> }
                
                <div className="gradient-wrapper">
                    <div className="light bg-item"></div>
                    <div className="dark bg-item"></div>
                </div>
                <div className="content-wrapper">
                    <img src={imgBalloon} alt=" " />
                    <h2>Bu içerik ve çok daha fazlası için abone olun.</h2>
                    <p>Hemen <b>gain.tv</b> adresinden GAİN abonesi olun, film, dizi, belgesel, canlı yayınlar ve çeşit çeşit programlara anında ulaşın.</p>
                    <ButtonFocusable
							focusKey={"PremiumFreeBackButton"} 
							className={
								Constants.BUTTON_MEDIUM + Constants.BUTTON_TRY_AGAIN
                            }
                            onEnterPress={() => {
                                navigate(-1);
                            }}
                            onClick={() => {
                                navigate(-1);
                            }} 
							title={"Geri Dön"}
						/>
                </div>
             </div>
		</>
	);
}


 