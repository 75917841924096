import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "../components/snackbar";
import Cookie from "universal-cookie";
import Button from "../components/Button/button";
import LoadingFullscreen from "../components/loadingFullscreen";
import withDirectionFocusable from "../components/withDirectionFocusable";
import BackKeyHandler from "../components/backKeyHandler";
import { getPlatform } from "../helper/platform";
import {
	getUserDetails,
	getSubscriptionDetails,
	getPremiumInformation
} from "../services/gainAPI";
import {
	pageView,
	logout as logoutEvent
} from "../services/gtm";

const ButtonFocusable = withDirectionFocusable(Button);
const BackKeyHandlerFocusable = withDirectionFocusable(BackKeyHandler);

export default function QRFreeAccountWarning(props) {
	const navigate = useNavigate();
	const [snackbar, setSnackbar] = useState(null);
	const [loading, setLoading] = useState(null);

	useEffect(() => {
		document.title = "Abone olmanız gerekmektedir";
        pageView();
		props.setFocus("Complete");
	}, []);

	useEffect(() => {
		function keyDown(e) {
			// console.log(e.keyCode);
			// sanal klavyede enter tuşuna basıldığında bir sonraki alana focuslanması sağlanır.
			if (
				(e.keyCode === 13 || e.keyCode === window.VK_ENTER) &&
				getPlatform().name !== "arcelik"
			) {
			}
		}

		window.addEventListener("keydown", keyDown);
		return () => {
			window.removeEventListener("keydown", keyDown);
		};


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	async function signOut() {
		setLoading(true);
		// Cookie boş ise logout yaptıktan sonra Qr login sayfası, Dolu ise ve premium   

		var subscriptionInfo = await getSubscriptionDetails();
		if (subscriptionInfo) {
			//console.log("subscriptionInfo", subscriptionInfo);
			var userDetails = await getUserDetails();
			//console.log("userDetails", userDetails);
			window.google_tag_manager && logoutEvent(userDetails.created_at, subscriptionInfo.lastBillingDate, subscriptionInfo.isPremium);
		}


		const cookies = new Cookie();
		cookies.remove('access_token', { path: "/" });
		cookies.remove('refresh_token', { path: "/" });
		cookies.remove('uuid', { path: "/" });

		setLoading(false);
		navigate("/qr-login", { replace: true });
	}


	async function checkPremium() {
		getPremiumInformation().then(function (result) {
			if (result !== null && result.isPremium === true) {
				navigate("/", { replace: true });
			} else {
				setSnackbar({
					show: true,
					message: "Aboneliğiniz tamamlanamamıştır. Teknik bir sorun yaşıyorsanız destek@gain.com.tr adresimize ulaşınız.",
					cssClassName: "snackbar-validation snackbar-free-account-warning",
				});

				const x = setTimeout(function () {
					setSnackbar({
						show: false
					});
					clearTimeout(x);
				}, 5000);
			}
			// console.log('result.isPremium',result.isPremium); 
		});
	}

	return (
		<div className="wrapper qr-login-wrapper result-page">
			<LoadingFullscreen show={loading} />
			{(window.platform === "vestel" ||
				window.platform === "tizen" ||
				window.platform === "webos") && (
					<BackKeyHandlerFocusable // todo
						focusKey={"ExitConfirmationFocusable"}
						backFocus={"Complete"}
					/>
				)}

			<span id="logo-gain"></span>

			<div className="content">
				<div className="qr-login-result-main">
					<div className="inner-text">
						<h3>
							GAİN içeriklerine ulaşabilmek için, aynı hesap bilgilerinizle web sitemizi açıp,
						</h3>
						<h3>
							gain.tv/abone-ol/aylik adresimize giderek abone olmanız gerekmektedir.
						</h3>
						<h4>
							Aboneliğinizi tamamladıysanız TV uygulamanızda tanımlanması biraz süre alabilir.
						</h4>
					</div>
					<div className="btn-group mt">
						<ButtonFocusable
							focusKey={"Complete"}
							id={"Complete"}
							type="button"
							className="btn btn-medium"
							title={"Aboneliğimi Tamamladım"}
							up={""}
							// disabled={!validateForm()}
							onBecameFocused={(e) => {
								// document.getElementById("LoginButton").focus();
							}}
							onEnterPress={() => {
								checkPremium();
							}}
							onClick={() => {
								checkPremium();
							}}
						/>
						<ButtonFocusable
							focusKey={"Back"}
							id={"Back"}
							type="button"
							className="btn btn-medium"
							title={"Geri Dön"}
							up={""}
							// disabled={!validateForm()}
							onBecameFocused={(e) => {
								// document.getElementById("LoginButton").focus();
							}}
							onEnterPress={() => {
								signOut();
							}}
							onClick={() => {
								signOut();
							}}
						/>
					</div>

					{/* <span className="primary-title absolute-title">
						Teknik bir sorun yaşıyorsanız destek@gain.com.tr adresimize
						ulaşınız.
					</span> */}
				</div>

				{snackbar && (
					<Snackbar // Todo
						show={snackbar.show}
						cssClassName={snackbar.cssClassName}
						message={snackbar.message}
					/>
				)}
			</div>
		</div>
	);
}
