import React from "react"; 

export default function LoadingFullscreen(props) {
    return props.show === true ? (
    <>
            <div className="loading-fullscreen"> 
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        margin: "auto",
                        background: "0 0",
                        display: "block",
                        shapeRendering: "auto",
                    }}
                    width={200}
                    height={200}
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid" 
                >
                    <circle
                    cx={50}
                    cy={50}
                    fill="none"
                    stroke="#fff"
                    strokeWidth={10}
                    r={35}
                    strokeDasharray="164.93361431346415 56.97787143782138"
                    >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="4.761904761904762s"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                    />
                    </circle>
                </svg>
            </div>
        </>
	) : null;	 
}
