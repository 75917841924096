import React from "react";

export default function SliderPlaylist(props) {
	return (
		<div className="playlist-container" key={props.id} index={props.index}>
			<span className="text-large">{props.name}</span>
			<div className="playlist" transformx="0">
				{props.children}
			</div>
		</div>
	);
}
