import React, { useEffect } from "react";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import * as Constants from "../constants/constants";
import Button from "../components/Button/button";
import { pageView } from "../services/gtm";

const ButtonFocusable = withFocusable()(Button);

function PageNotFound(props) {

    useEffect(() => {
        document.title = "Sayfa bulunamadı";
        pageView();
    }, []);

	return (
		<div className="main-wrapper text-page-wrapper">
			<div>
				<h3>
					We’re having a problem connecting to GAİN. Please try again or
					visit www. gain.com/help
				</h3>
				<div className="btn-wrapper">
					<ButtonFocusable
						focusKey={""}
						className={
							Constants.BUTTON_MEDIUM + Constants.BUTTON_TRY_AGAIN
						}
						link={"/"}
						title={"Try Again"}
					/>
					<ButtonFocusable
						focusKey={""}
						className={
							Constants.BUTTON_MEDIUM + Constants.BUTTON_MORE_DETAIL
						}
						link={"/"}
						title={"More Details"}
					/>
				</div>
			</div>
		</div>
	);
}

export default PageNotFound;
