import React, { useContext } from "react";
import { MediaContext } from "../provider/generalProvider";

export default function TitleDetailWrapperBox(props) {
  const { isActiveSlider } = useContext(MediaContext);
  return (
    <div
     // scroll-active
      className={`main-wrapper  ${isActiveSlider ? "scroll-active" : ""} ${props.pageClass} ${
        props.mainClass
      }`}
    >
      {props.children}
    </div>
  );
}
