import React, { useEffect, useLayoutEffect, useState } from "react";
import FeaturedMediaSummary from "../components/featuredMediaSummary";
import SliderContainer from "../components/Slider/sliderContainer";
import MainWrapperBox from "../components/mainWrapperBox";
import withDirectionFocusable from "../components/withDirectionFocusable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pageView } from "../services/gtm";
import FeaturedmediaSkeleton from "../components/Skeleton/featuredMedia";
import SliderSkeleton from "../components/Skeleton/slider";
import { getTitle,getSimilarPlaylist } from "../services/gainAPI";
import { changeFeaturedMediaInformations,scrollActive} from "../helper/featuredMediaHelper";
// import LoadingFullscreen from "../components/loadingFullscreen";

const FeaturedMediaSummaryFocusable = withDirectionFocusable(FeaturedMediaSummary);
const SliderContainerFocusable = withDirectionFocusable(SliderContainer);

export default function Title(props) {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [sliderData, setSliderData] = useState(null);
	const [initialFeaturedMedia, setInitialFeaturedMedia] = useState(null);
	// const [loading, setLoading] = useState(true);

	useLayoutEffect(() => {
		scrollActive(false);

		var titleId = searchParams.get("id");

		getTitle(titleId).then(function (response) {
			if (response !== null && response.data !== null) {
                response.data.titleId = titleId; // titleId bilgi featuredMediaSummary'de video url'inde kullanılıyor.
				setInitialFeaturedMedia(response.data);
				document.title = response.data.metadata.name;
				pageView();
				// setLoading(false);
			}
			else {
				navigate(-1);
				return;
			}
		});

		return () => {
			setInitialFeaturedMedia(null);
			setSliderData(null);
		};
	}, [searchParams]);

    useEffect(() => {
		props.setFocus("WatchNowButton");
	}, [initialFeaturedMedia]);

	useEffect(() => {
		scrollActive(false);

		// ana sayfa(güncel) playlistleri çekiliyor.
		var titleId = searchParams.get("id");
		getSimilarPlaylist(titleId).then(function (response) {
			// console.log(response);
			//if(response !== null){response[0].name = "Benzer İçerikler";}
			setSliderData({
				playlists: [{
					name: 'Benzer İçerikler',
					list: response.data
				}]
			});
			// props.setFocus("WatchNowButton");
		});
	}, [searchParams]);

	return (
		// scroll-active class scroll olduğunda eklenecek
		<>
			<MainWrapperBox
				focusable={true}
				focusKey={"MainWrapper"}
				pageClass={"category"}
			>
				{/* <LoadingFullscreen show={loading} /> */}
				<span id="logo-gain"></span>
 
				{initialFeaturedMedia !== null ?
					<FeaturedMediaSummaryFocusable
						forgetLastFocusedChild={false}
						focusKey={"FeaturedMediaSummaryFocusable"}
						ShowDescription={true}
						ShowWatchNowButton={true}
						ShowDetailsButton={true}
						ShowLikeDislike={true}
						ShowAddToListButton={true}
						ShowTitleButtons={true}
						initialFeaturedMedia={initialFeaturedMedia}
						onBecameFocused={() => {
							changeFeaturedMediaInformations(initialFeaturedMedia);
						}}
					/>
					: <FeaturedmediaSkeleton btnSet={true} /> }

				{(sliderData !== null && initialFeaturedMedia !== null) ?
					<SliderContainerFocusable
                        lazyLoad={false}
						data={sliderData}
						initialFeaturedMedia={initialFeaturedMedia}
						canYouGoUp={true}
						focusKey={"Slider"}
					/>
					: <SliderSkeleton/>} 
			</MainWrapperBox>
		</>
	);
}
