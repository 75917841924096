// todo: MB211 ise mp4 oynatmak gerekebilir?

// ref: http://vstlsrv.com/swtest/smarttv/index.html#macid
// ref: http://vstlsrv.com/swtest/smarttv/api/systeminfo.html

{/* <object id="sysinfo" type="systeminfoobject" ></object> */}
{/* <object id="Macid " type="application/macid " ></object> */}

// sysinfo.serialnumber
// sysinfo.productname
// sysinfo.firmwareversion
// sysinfo.vendor
// sysinfo.resolution
// sysinfo.network
// sysinfo.techplatform 

export function init(){
    createSystemInfoObject();
    window.modelName = getProductName();
    window.deviceID = getUID();
}


// ilk olarak bu objectin oluşturulması gerekiyor.
function createSystemInfoObject() {
    if(document.getElementById('sysinfo') === null){
        var e = document.createElement("object");
        e.id = "sysinfo";
        e.type = "systeminfoobject";
        e.style.position = "absolute";
        e.style.opacity = 0;
        e.style.width = 0;
        e.style.height = 0;
        document.body.appendChild(e);
    }
}

function getProductName(){
    var productName = "";
    try{
        productName = window.sysinfo !== undefined && window.sysinfo.productname !== undefined ? window.sysinfo.productname : "unknown";
    }
    catch{
        productName = "unknown";
    }
    return productName; 
}

// MacID çekilmesi için objectin oluşturulması gerekiyor ve ardından siliniyor.
function getUID() {
    var macID = "";
    try{
        if(document.getElementById('Macid') === null){
            var e = document.createElement("object");
            e.id = "Macid";
            e.type = "application/macid";
            e.style.position = "absolute";
            e.style.opacity = 0;        
            e.style.width = 0;
            e.style.height = 0;
            document.body.appendChild(e);
            //e.parentElement.removeChild(e);
        }
        
        macID = window.Macid.getMACID();
    }
    catch{
        macID = "unknown";
    }
    return macID;
}


// function getLanguage() {
//     return navigator.language
// }