import React, { useContext, memo } from "react";
import { MediaContext } from "../provider/generalProvider";

function MainWrapperBox(props) {
	const { IsActiveSlider } = useContext(MediaContext);
	return (
		<div
			// scroll-active
			className={`main-wrapper ${IsActiveSlider ? "scroll-active" : ""} ${
				props.pageClass !== undefined ? props.pageClass : ""
			} ${props.mainClass !== undefined ? props.mainClass : ""}`}
		>
			{props.children}
		</div>
	);
}

export default memo(MainWrapperBox)
