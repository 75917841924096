import React, { useState, useContext } from "react";
import SidebarItem from "./sidebarItem";
import { useNavigate } from "react-router-dom";
import withDirectionFocusable from "../components/withDirectionFocusable";
import { MediaContext } from "../provider/generalProvider";

const MenuItemFocusable = withDirectionFocusable(SidebarItem);
// const RETURN_KEY = 8594;

export default function Sidebar(props) {
    const navigate = useNavigate();
    const { ActiveSidebarItem } = useContext(MediaContext);
	const [menu, setMenu] = useState([
		{
            key: "profile",
			link: "/profile",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#222222" />
					<path
						d="M45.6666 47V44.3333C45.6666 42.9188 45.1047 41.5623 44.1045 40.5621C43.1043 39.5619 41.7477 39 40.3333 39H29.6666C28.2521 39 26.8955 39.5619 25.8953 40.5621C24.8952 41.5623 24.3333 42.9188 24.3333 44.3333V47"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M35.0001 33.6667C37.9456 33.6667 40.3334 31.2789 40.3334 28.3333C40.3334 25.3878 37.9456 23 35.0001 23C32.0546 23 29.6667 25.3878 29.6667 28.3333C29.6667 31.2789 32.0546 33.6667 35.0001 33.6667Z"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			title: "Profil",
		},
		{
            key: "subscription",
			link: "/subscription",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#222222" />
					<path
						d="M35.5264 43.9706C35.5317 43.9718 35.5344 43.9727 35.5345 43.9728C35.5346 43.9729 35.532 43.9723 35.5264 43.9706ZM35.3707 43.9083C35.3106 43.8811 35.2372 43.8455 35.1505 43.8001C34.8546 43.6454 34.4569 43.4056 33.9887 43.0846C33.0538 42.4437 31.8838 41.5102 30.7425 40.3601C28.4244 38.0241 26.4004 34.9745 26.4004 31.7851C26.4004 26.8751 30.3662 23.2224 35.3901 23.2189L36.0186 23.2185C40.7451 23.5091 44.3873 27.0598 44.3873 31.7654C44.3873 34.9487 42.3712 37.9929 40.0582 40.3275C38.9194 41.4768 37.7509 42.4108 36.8151 43.0537C36.3465 43.3757 35.9474 43.6169 35.6491 43.7736C35.5335 43.8344 35.4408 43.8783 35.3707 43.9083Z"
						stroke="white"
						strokeWidth="2"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M35.3974 44.8867L33.8018 47.7997H36.9931L35.3974 44.8867Z"
						fill="white"
					/>
				</svg>
			),
			title: "Abonelik",
		},
		/*
            
            ,{
              link: "/dil",
              icon: <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="70" height="70" rx="35" fill="#222222"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M50.8666 46.1337C50.8666 46.6859 50.4189 47.1337 49.8666 47.1337H21.3333C20.781 47.1337 20.3333 46.6859 20.3333 46.1337V22.667C20.3333 22.1147 20.781 21.667 21.3333 21.667H49.8666C50.4189 21.667 50.8666 22.1147 50.8666 22.667V46.1337ZM22.8666 43.587C22.8666 44.1393 23.3143 44.587 23.8666 44.587H47.3333C47.8855 44.587 48.3333 44.1393 48.3333 43.587V25.2137C48.3333 24.6614 47.8855 24.2137 47.3333 24.2137H23.8666C23.3143 24.2137 22.8666 24.6614 22.8666 25.2137V43.587ZM34.3332 39.7594C34.3332 40.3117 33.8855 40.7594 33.3332 40.7594H31.7865C30.774 40.7594 29.8026 40.3581 29.0854 39.6433C28.3681 38.9285 27.9634 37.9586 27.9599 36.9461V31.8527C27.9634 30.8401 28.3681 29.8702 29.0854 29.1555C29.8026 28.4407 30.774 28.0394 31.7865 28.0394H33.3332C33.8855 28.0394 34.3332 28.4871 34.3332 29.0394V29.5861C34.3332 30.1383 33.8855 30.5861 33.3332 30.5861H31.7865C31.4442 30.5856 31.1148 30.7169 30.8665 30.9527C30.6183 31.1885 30.4703 31.5108 30.4532 31.8527V36.9461C30.4703 37.288 30.6183 37.6102 30.8665 37.8461C31.1148 38.0819 31.4442 38.2132 31.7865 38.2127H33.3332C33.8855 38.2127 34.3332 38.6604 34.3332 39.2127V39.7594ZM40.6932 40.7594H42.2398C42.7921 40.7594 43.2398 40.3117 43.2398 39.7594V39.2127C43.2398 38.6604 42.7921 38.2127 42.2398 38.2127H40.6932C40.3508 38.2132 40.0214 38.0819 39.7732 37.8461C39.525 37.6102 39.377 37.288 39.3598 36.9461V31.8527C39.377 31.5108 39.525 31.1885 39.7732 30.9527C40.0214 30.7169 40.3508 30.5856 40.6932 30.5861H42.3065C42.8588 30.5861 43.3065 30.1383 43.3065 29.5861V29.0394C43.3065 28.4871 42.8588 28.0394 42.3065 28.0394H40.7598C39.7473 28.0394 38.7759 28.4407 38.0587 29.1555C37.3414 29.8702 36.9367 30.8401 36.9332 31.8527V36.9461C36.9365 37.9472 37.3321 38.9072 38.035 39.62C38.7379 40.3329 39.6922 40.742 40.6932 40.7594Z" fill="white"/>
              </svg>,
            title: "Dil"
            },{
              link: "/otomatik-oynatma",
              icon: <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="70" height="70" rx="35" fill="#222222"/>
              <path d="M34.9998 48.3337C42.3636 48.3337 48.3332 42.3641 48.3332 35.0003C48.3332 27.6365 42.3636 21.667 34.9998 21.667C27.636 21.667 21.6665 27.6365 21.6665 35.0003C21.6665 42.3641 27.636 48.3337 34.9998 48.3337Z" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
              <path d="M32.3335 29.667L40.3335 35.0003L32.3335 40.3337V29.667Z" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
              </svg>
              ,
            title: "Otomatik Oynatma"
            },{
              link: "/yasal-uyarilar",
              icon:  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="70" height="70" rx="35" fill="#222222"/>
              <path d="M34.9998 48.3337C42.3636 48.3337 48.3332 42.3641 48.3332 35.0003C48.3332 27.6365 42.3636 21.667 34.9998 21.667C27.636 21.667 21.6665 27.6365 21.6665 35.0003C21.6665 42.3641 27.636 48.3337 34.9998 48.3337Z" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
              <path d="M35 40.3333V35" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
              <path d="M35 29.667H35.0133" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
              </svg>,
            title: "Yasal Uyarılar"
            }
            
            */
	]);

    const [isFocused, setIsFocused] = useState(false); 

    function getRightKey() {
        var hash = window.location.hash;
        if(hash === "#/profile"){
            return "SignOutBtn";
        }
        else if(hash === "#/signout"){
            return "ButtonSignout";
        }
        else if(hash === "#/signout"){
            return "ButtonSignout";
        }
        else if(hash === "#/subscription"){
            return null;
        }
        else{
            return "Outlet";
        }
    }
    
	return (
		<div
			id="sidebar"
			className={props.hasFocusedChild || isFocused ? "settings-sidebar opened" : "settings-sidebar"}>
			<ul>
				<li className="title">
					<h1>Ayarlar</h1>
				</li>
				{menu.map((item, key) => {
					return (
						<li key={"menu-item-li-"+ key}>
							<MenuItemFocusable
                                key={"menu-item-key-" + key}
								focusKey={"MENU-" + key}
								link={item.link}
								icon={item.icon}
								title={item.title}
								right={ getRightKey()}
                                left={null}
                                // left={window.location.hash === "#/profile" ? "SignOutBtn" : window.location.hash === "#/signout" ? "ButtonSignout" : "Outlet"}
                                up={key === 0 && (window.location.hash === "#/profile" || window.location.hash === "#/subscription") ? "BackButton" : null}
                                cssClassName={item.key === ActiveSidebarItem ? "active ": ""}
								onEnterPress={() => {
									navigate(item.link);
								}}
								onBecameFocused={() => {
									setIsFocused(true);
								}}
								onBecameBlurred={() => {
									setIsFocused(false);
								}}
							/>
						</li>
					);
				})}
			</ul>
		</div>
	);
}
