import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { MediaContext } from "../provider/generalProvider";
import FeaturedMediaSummary from "../components/featuredMediaSummary";
import SliderContainer from "../components/Slider/sliderContainer";
import MainWrapperBox from "../components/mainWrapperBox";
import withDirectionFocusable from "../components/withDirectionFocusable";
import { getCategoryPlaylists } from "../services/gainAPI";
import { pageView } from "../services/gtm";
import { 
    scrollActive
} from "../helper/featuredMediaHelper";
//Skeleton
import FeaturedmediaSkeleton from "../components/Skeleton/featuredMedia";
import SliderSkeleton from "../components/Skeleton/slider";
import * as Sentry from "@sentry/react";

const FeaturedMediaSummaryFocusable = withDirectionFocusable(FeaturedMediaSummary);
const SliderContainerFocusable = withDirectionFocusable(SliderContainer);

export default function Category(props) {
	const [searchParams] = useSearchParams();
	const { changeSidebarItem } = useContext(MediaContext);
    const [sliderData, setSliderData] = useState(null);
    const [initialFeaturedMedia, setInitialFeaturedMedia] = useState(null); // Featured media olarak ilk gelen içerik

	useEffect(() => {
        document.title = searchParams.get("name");
        pageView();

        changeSidebarItem(searchParams.get("name"));
        scrollActive(true);
    
		//console.log(searchParams.get("name"));
		async function getData() {
			await getCategoryPlaylists(searchParams.get("id")).then(
				(response) => { 
                    if(response !== null && response.playlists.length > 0)
                    {
                        var playlists = response.playlists.filter(p => p !== undefined && p.list !== null && p.list.length > 0);
                        setInitialFeaturedMedia(playlists[0].list[0]);
                        setSliderData(response);
                        props.setFocus(playlists[0].playlistId + "-0"); // kategoriye ait ilk playlistteki ilk mediaya focus olması sağlandı.
                    }
                    else{
                        var log = "Kategori playlist datası gelmedi!";
                        //console.log(log);
                        Sentry.captureMessage(log);
                    }
				}
			);
		}
        getData();
        
		return () => {
			setInitialFeaturedMedia(null);
			setSliderData(null);
		};
	}, [searchParams]);

	return (
        //console.log("category render"),
		<>
			<MainWrapperBox focusable={true} focusKey={"MainWrapper"}>
                {/* <LoadingFullscreen show={loading} /> */}

				<span id="logo-gain"></span>

                {initialFeaturedMedia ?
                    <FeaturedMediaSummaryFocusable
                        forgetLastFocusedChild={false}
                        focusKey={"FeaturedMediaSummaryFocusable"}
                        ShowDescription={true}
                        ShowWatchNowButton={false}
                        ShowDetailsButton={false}
                        ShowLikeDislike={false}
                        ShowAddToListButton={false}
                        ShowTitleButtons={false}
                        initialFeaturedMedia={initialFeaturedMedia}
                    // onBecameFocused={() => {
                    //     changeFeaturedMediaInformations(initialFeaturedMedia);
                    // }}
                    />
                    : <FeaturedmediaSkeleton />
                }


                {(sliderData !== null && initialFeaturedMedia !== null)
                    ? <SliderContainerFocusable
                        lazyLoad={false}
                        data={sliderData}
                        initialFeaturedMedia={initialFeaturedMedia}
                        canYouGoUp={false}
                        focusKey={"Slider"}
                    /> : <SliderSkeleton />}

			</MainWrapperBox>
		</>
	);
}
