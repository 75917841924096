const getRtukCard = (item) => {
    return (
        <>
            <span className={"rtuk " + item}></span>
            <span className="text-span">{getText(item)}</span>
        </>
    )
}
const getText = (item) => {
    let content = '';
    
    if(item === "content7Plus" ){
        content = "7 yaş ve üzeri"
    }
   
    if(item === "content13Plus" ){
        content = "13 yaş ve üzeri"
    }

    if(item === "content18Plus" ){
        content = "18 yaş ve üzeri"
    }

    if(item === "contentViolence" ){
        content = "Şiddet/Korku"
    }

    if(item === "contentSexuality" ){
        content = "Cinsellik"
    }

    if(item === "contentNegative" ){
        content = "Olumsuz Örnek Oluşturabilecek Davranışlar"
    }

    if(item === "contentAll" ){
        content = "Genel İzleyici Kitlesi"
    } 

    return content;
}

export default getRtukCard;