import React, { useState, useEffect, useContext } from "react";
import withDirectionFocusable from "../components/withDirectionFocusable";
import SliderCard from "../components/Slider/sliderCard";
import InputSearch from "../components/inputSearch";
import { MediaContext } from "../provider/generalProvider";
import { useNavigate } from "react-router-dom";
import { pageView } from "../services/gtm";
import { Debounce } from '../helper/debounce';
import { getRecommendedVideos, getSearch } from "../services/gainAPI";
import { getThumbnailImage } from "../helper/imageHelper";
import SearchMostViewedSkeleton from "../components/Skeleton/search";
import { getPlatform } from "../helper/platform";

const SliderCardFocusable = withDirectionFocusable(SliderCard);
const InputSearchFocusable = withDirectionFocusable(InputSearch);


function Search(props) {

    const navigate = useNavigate();
    const [mostWatchedPlaylist, setMostWatchedPlaylist] = useState(null);
    const [isVisibleMostWatchedPlaylist, setIsVisibleMostWatchedPlaylist] = useState(true);
    const [searchResult, setSearchResult] = useState(null);
    const { SearchTextContext, changeSidebarItem } = useContext(MediaContext);
    const debouncedSearch = Debounce(SearchTextContext, 750);

    useEffect(() => {
        document.title = "Arama";
        pageView();

        changeSidebarItem("search");

        // arama işlemi yapılmadan önce "en çok izlenenler" playlisti datası gösterilir.
        async function getData() {
            await getRecommendedVideos().then(
                (response) => {
                    // console.log(response);
                    setMostWatchedPlaylist(response);
                    props.setFocus("InputSearch");
                    document.getElementById("search").focus();

                    // if (getPlatform().name === "arcelik" && window.arSmartTV && window.arSmartTV.isVirtualKeyboardVisible() === false) {
                    //     window.arSmartTV.showVirtualKeyboard();
                    // }
                }
            );
        }
        getData();
    }, []);

    useEffect(() => {
        if (debouncedSearch !== null && debouncedSearch !== "") {
          
            if(debouncedSearch.length < 3){ 
                setIsVisibleMostWatchedPlaylist(false);
                setSearchResult('error'); 
            }else{
                doSearch(debouncedSearch);
            }
      
            // console.log('SearchTextContext'); 
        } else {
            setIsVisibleMostWatchedPlaylist(true);
            setSearchResult(null);
        }
    }, [debouncedSearch]);

    useEffect(() => {
        function keyDown(e) {
            // console.log(e.keyCode);

            if (getPlatform().name !== "arcelik"){
                if (e.keyCode == window.VK_ENTER || e.keyCode === 13 || e.keyCode === 40) {
                    // sanal klavyede enter tuşuna basıldığında arama sonuçlarında ilkine focuslanması sağlanır.
                    // normal klavyede aşağı tuşuna basıldığında arama sonuçlarında ilkine focuslanması sağlanır.
                    if (document.activeElement !== null && document.activeElement.hasAttribute("id") === true && document.activeElement.getAttribute("id") === "search") {
                        var firstSearchResult = document.querySelector('.playlist .item:first-child');
                        if (firstSearchResult !== null) {
                            document.querySelector('.playlist .item:first-child').focus();
                        }
                        props.setFocus("first-result");
                    }
                }
            }
        }

        getPlatform().name !== "arcelik" && window.addEventListener("keydown", keyDown);

        return () => {
            getPlatform().name !== "arcelik" && window.removeEventListener("keydown", keyDown);
        };
    }, []);

    function doSearch(searchTerm) {
        async function getData() {
            await getSearch(searchTerm).then(
                (response) => {
                    //console.log(response);
                    setIsVisibleMostWatchedPlaylist(false);
                    setSearchResult(response);
                }
            );
        }
        getData();
    }

    return (
        // scroll-active class scroll olduğunda eklenecek
        <div className="main-wrapper search">
            <div className="search-wrapper">
                <div className="form">
                    <InputSearchFocusable
                        focusKey="InputSearch"
                        onBecameFocused={() => {
                            document.getElementById("search").focus();
                        }}
                    />
                </div>
            </div>

            { mostWatchedPlaylist == null && searchResult == null && (
                <SearchMostViewedSkeleton />
            )}

            {isVisibleMostWatchedPlaylist && mostWatchedPlaylist != null &&
                <div className="playlist-wrapper">
                    <span className="text-large">En Çok İzlenenler</span>
                    <div className="playlist grid-type">
                        {mostWatchedPlaylist.list.map((media, key) => (
                            //(console.log(media.titleId)),
                            <SliderCardFocusable
                                lazyLoad={false}
                                trackChildren
                                up={key < 5 ? "InputSearch" : null}
                                // up={index == 0 && props.canYouGoUp == true ? "WatchNowButton" : null}
                                left={key % 5 === 0 ? "Sidebar" : null}
                                // right={key == p.playlist.length - 1 ? null : "TransformSliderRight"}
                                // onBecameFocused={sliderCardFocus}
                                onEnterPress={() => { 
                                    if(media.type == 'episode'){
                                        navigate("/video?id=" + media.mediaId + "&titleId=" + media.titleId);
                                    }else{
                                        navigate("/title?id=" + media.titleId);
                                    }
                                }}
                                onBecameFocused={() => {
                                    document.activeElement.blur(); // ilk card focus durumda kalıyordu, engelleniyor.
                                }}
                                URL={ 
                                    media.type == 'episode'
                                        ? "/video?id=" + media.mediaId + "&titleId=" + media.titleId
                                        : "/title?id=" + media.titleId
                                }
                                imageURL={getThumbnailImage(media.metadata.images, 480)}
                                key={key + "-" + media.mediaId}
                                focusKey={
                                    key === 0
                                        ? "first-result"
                                        : key + "-" + media.mediaId
                                }
                                title={media.metadata.name}
                                mediaObj={media}
                                index={key}
                                id={media.mediaId}
                                titleId={media.titleId}
                            />
                        ))}
                    </div>
                </div>}  

            {searchResult != null && searchResult !== 'error' && ( // Arama Sonuçları
                <div className="playlist-wrapper">
                    <span className="text-large">
                        Arama Sonuçları ({searchResult.titles !== null ? searchResult.titles.length + " Sonuç" : "Sonuç Bulunamadı"})
                    </span>
                    <div className="playlist grid-type">
                        {/* <div className="play-list-tranform  grid-type" transformy={0}>
                           
                        </div> */}
                        {searchResult.titles !== null &&
                            searchResult.titles.map((media, key) => (
                                <SliderCardFocusable
                                    lazyLoad={false}
                                    trackChildren
                                    // up={index == 0 && props.canYouGoUp == true ? "WatchNowButton" : null}
                                    // left={key == 0 ? "Sidebar" : "TransformSliderLeft"}
                                    // right={key == p.playlist.length - 1 ? null : "TransformSliderRight"}
                                    // onBecameFocused={sliderCardFocus}
                                    onEnterPress={() => { 
                                        if(media.type == 'episode'){
                                            navigate("/video?id=" + media.mediaId + "&titleId=" + media.titleId);
                                        }else{
                                            navigate("/title?id=" + media.titleId);
                                        }
                                    }}
                                    onBecameFocused={() => {
                                        document.activeElement.blur(); // ilk card focus durumda kalıyordu, engelleniyor.
                                    }}
                                    URL={ 
                                        media.type == 'episode'
                                            ? "/video?id=" + media.mediaId + "&titleId=" + media.titleId
                                            : "/title?id=" + media.titleId
                                    }
                                    imageURL={getThumbnailImage(media.metadata.images, 480)}
                                    key={key + "-" + media.titleId}
                                    title={media.metadata.name}
                                    mediaObj={media}
                                    index={key}
                                    id={media.id}
                                    titleId={media.titleId}
                                    cardId={key}
                                    totalCard={searchResult.titles.length}
                                    focusKey={
                                        key === 0
                                            ? "first-result"
                                            : key + "-" + media.titleId
                                    }
                                    down={
                                        searchResult.titles.length >= 9
                                            ? "TransformSearchDown"
                                            : null
                                    }
                                    up={
                                        searchResult.titles.length >= 9 && key >= 5
                                            ? "TransformSearchUp"
                                            : null
                                    }
                                    left={
                                        key === 0 || key % 5 === 0 ? "Sidebar" : null
                                    }
                                />
                            ))}
                    </div>
                </div>
            )}


            {searchResult == 'error' && ( // Arama Sonuçları 3'den az karakter olduğunda çıkan hata
                <div className="playlist-wrapper">
                    <span className="text-large">
                    Anahtar kelime en az üç karakter olmalıdır
                    </span>
                    <div className="playlist grid-type">
                       
                    </div>
                </div>
            )}
        </div>
    );
}

export default Search;
