import React from "react";
export default function SettingUserSvg(props) {
 

  return (
    <>
      <div className="user-profile-svg">
        <svg
          width={243}
          height={243}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#a)">
            <rect width={242.06} height={242.06} rx={121.03} fill="#fff" />
            <path
              d="M242.062 121.031c0 66.843-54.188 121.031-121.031 121.031C54.187 242.062 0 187.874 0 121.031 0 54.187 54.187 0 121.031 0c66.843 0 121.031 54.187 121.031 121.031Z"
              fill="#ED1C24"
            />
            <path
              d="M241.984 120.031c0 66.843-54.188 121.031-121.031 121.031-66.844 0-121.031-54.188-121.031-121.031 0-66.844 39.671 68.584 112.962 59.843 29.585 0 53.792-106.238 129.1-59.843Z"
              fill="url(#b)"
              style={{
                mixBlendMode: "screen"
              }}
              opacity={0.8}
            />
            <path
              d="M10.18 73.052c-27.162 61.076.332 132.607 61.408 159.768 61.077 27.162 132.607-.332 159.769-61.408C231.357 103 138.52 112.918 101 97 51.334 75.93 71.588-46 10.18 73.052Z"
              fill="url(#c)"
              style={{
                mixBlendMode: "screen"
              }}
              opacity={0.6}
            />
          </g>
          <defs>
            <linearGradient
              id="b"
              x1={39.593}
              y1={68.929}
              x2={232.57}
              y2={267.285}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.837} stopColor="#74161A" />
              <stop offset={1} stopColor="#74161A" stopOpacity={0} />
            </linearGradient>
            <linearGradient
              id="c"
              x1={93.999}
              y1={170.5}
              x2={-72.501}
              y2={25}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#74161A" />
              <stop offset={1} stopColor="#74161A" stopOpacity={0} />
            </linearGradient>
            <clipPath id="a">
              <rect width={242.06} height={242.06} rx={121.03} fill="#fff" />
            </clipPath>
          </defs>
        </svg>
        <svg
          width={192}
          height={192}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#d)">
            <rect width={191.2} height={191.2} rx={95.6} fill="#fff" />
            <path
              d="M191.012 95.603c0 52.799-42.803 95.602-95.603 95.602S-.193 148.402-.193 95.602 42.609 0 95.409 0c52.8 0 95.603 42.803 95.603 95.603Z"
              fill="#ED1C24"
            />
            <path
              d="M190.949 94.813c0 52.799-42.803 95.602-95.602 95.602-52.8 0-95.603-42.803-95.603-95.602 0-52.8 31.337 54.174 89.23 47.27 23.369 0 42.489-83.918 101.975-47.27Z"
              fill="url(#e)"
              style={{
                mixBlendMode: "screen"
              }}
              opacity={0.8}
            />
            <path
              d="M7.848 57.704c-21.455 48.244.262 104.746 48.506 126.201s104.747-.262 126.202-48.507c0-54.038-73.333-46.204-102.97-58.777C40.356 59.977 56.354-36.336 7.848 57.704Z"
              fill="url(#f)"
              style={{
                mixBlendMode: "screen"
              }}
              opacity={0.6}
            />
          </g>
          <defs>
            <linearGradient
              id="e"
              x1={31.081}
              y1={54.447}
              x2={183.513}
              y2={211.129}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0.837} stopColor="#74161A" />
              <stop offset={1} stopColor="#74161A" stopOpacity={0} />
            </linearGradient>
            <linearGradient
              id="f"
              x1={74.057}
              y1={134.678}
              x2={-57.462}
              y2={19.747}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#74161A" />
              <stop offset={1} stopColor="#74161A" stopOpacity={0} />
            </linearGradient>
            <clipPath id="d">
              <rect width={191.2} height={191.2} rx={95.6} fill="#fff" />
            </clipPath>
          </defs>
        </svg>
        <span>{props.name}</span>
      </div>
    </>
  );
}
