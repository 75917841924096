import React from "react";
import { Link } from "react-router-dom";

export default function LinkButton(props) {
  return (
      <Link 
        to={props.link}
        className={`
            ${props.className}    
            ${props.focused ? "selected" : ""}`} 
        >
        {props.icon}
        {props.title}
        {/* {props.animationTime && <i style={{ '--intro-time': props.animationTime +'s','animation-delay': props.offset +'s'}} className="into-bg-slide"></i> } */}
        {props.season !== undefined && <span>{props.season}</span>}
        {props.percent !== undefined && <i style={{ '--percent':props.percent+'%'}} className="video-continues"></i> }
      </Link>
  );
}