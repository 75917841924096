import React from "react";

export default function Snackbar(props) {
    return ( 
        props.show &&
        (
            <div className={`snackbar ${props.cssClassName}`}>
                {props.message}
            </div>
        )
    );
}
