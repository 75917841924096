import React, { useRef } from "react";
import SliderCard from "../Slider/sliderCard";
import SliderPlaylist from "./sliderPlaylist";
import { useNavigate } from "react-router-dom";
import withDirectionFocusable from "../../components/withDirectionFocusable";
import { 
    changeFeaturedMediaInformations,
    scrollActive
} from "../../helper/featuredMediaHelper"; 
import {
    getThumbnailImage
} from "../../helper/imageHelper";

const SliderCardFocusable = withDirectionFocusable(SliderCard);
const SliderPlaylistFocusable = withDirectionFocusable(SliderPlaylist);


export default function SliderContainer(props) {
	const navigate = useNavigate();
    const playlistWrapperRef = useRef(null);
    const fSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // px - rem
    //const playlistHeight = document.querySelectorAll(".playlist-container:first-child")[0].offsetHeight + 4 * fSize; 

    const sliderCardFocus = (coord, item) => {
        changeFeaturedMediaInformations(item.mediaObj);
    } 

    const onBecameFocusedForPlaylist = () => {
			// console.log("onBecameFocusedForPlaylist");
			props.canYouGoUp === true && scrollActive(true);
            // console.log("scrollActive:true", props.canYouGoUp);
			var selectedElement = document.querySelector(".playlist-wrapper .selected");

            //// ***** 1. YÖNTEM
			if (selectedElement) {
				var containerElement = selectedElement.closest(".playlist-container");
				var containerElementHeight = containerElement.offsetHeight + (4 * fSize);
				var playlistIndex = parseInt(containerElement.getAttribute("index"));
				document.getElementById("playlist-transformable").style.transform = "translateY(-" + containerElementHeight * playlistIndex + "px) translateZ(0)";
			}

            //// ***** 2. YÖNTEM
            // if (selectedElement) {
			// 	const containerElement = selectedElement.closest(".playlist-container");
			// 	const containerElementHeight = containerElement.offsetHeight + 4 * fSize;
			// 	const playlistIndex = parseInt(containerElement.getAttribute("index"));
            //     playlistWrapperRef.current.scrollTop = containerElementHeight * playlistIndex;
			// }
		};
        

    const onBecameBlurredForPlaylist = () => {
        // console.log("onBecameBlurredForPlaylist");
        if (props.canYouGoUp === true) {
            // console.log("scrollActive:false");
            scrollActive(false);
        }
    }; 

	return (
        // console.log("props.data:", props.data),
		<div className="playlist-wrapper" ref={playlistWrapperRef}>
			<div id="playlist-transformable">
				{
					props.data.playlists.filter(p => p !== undefined && p.list !== null && p.list.length > 0).map((p, index) => {
							// console.log(p);
                            return (
							<SliderPlaylistFocusable
								trackChildren
								name={p.name !== undefined ? p.name.replace("Yeni Program - ", "").replace("Yeni Programlar - ", "").replace("Yeni - ", "") : "İzlemeye Devam Et"}
								id={p.playlistId}
								key={"slider-" + index + p.playlistId}
								index={index}
								onBecameFocused={onBecameFocusedForPlaylist}
								onBecameBlurred={onBecameBlurredForPlaylist}
							>
								{
									p.list.map((content, key) => (
										<SliderCardFocusable
                                            lazyLoad={index > 3 ? props.lazyLoad : false}
											up={
												index === 0 && props.canYouGoUp === false
													? p.playlistId + "-" + key
													: index === 0 &&
													  props.canYouGoUp === true
													? "WatchNowButton"
													: null
											}
											left={key === 0 ? "Sidebar" : "TransformSliderLeft"}
											right={key === p.list.length - 1 ? null : "TransformSliderRight"}
											onBecameFocused={sliderCardFocus}
											// onEnterPress={() => {
                                            //     navigate("/title?id=" + media.mediaid);
                                            // }} 
                                            onEnterPress={() => {
                                                if (content.type === "title" || content.type === "singleTitle") {
                                                    navigate("/title?id=" + content.titleId);
                                                } else {
                                                    navigate("/video?id=" + content.mediaId + "&titleId=" + content.titleId);
                                                }
                                            }} 
											URL={
												content.type === "title" || content.type === "singleTitle"
													? "/title?id=" + content.titleId
													: "/video?id=" + content.mediaId + "&titleId=" + content.titleId
											}
                                            imageURL = {getThumbnailImage(content.metadata.images,480)}
											key={"slider-card-" + index + key + "-" + p.playlistId + "-" + content.titleId}
											focusKey={p.playlistId + "-" + key} 
											index={key}
											id={content.mediaId}
											title={content.metadata.name}
											mediaObj={content}
											playlistId={p.playlistId}
											titleId={content.titleId}
										/>
									))
								}
							</SliderPlaylistFocusable>
						);
					})
                } 
			</div>
		</div>
	);
}
