import React, { useState, useEffect } from 'react';

const convertToTime = (totalSeconds) => {
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;
    // 0 ile başlanması sağlandı.
    minutes = String(minutes).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return minutes + ":" + seconds
}

function Countdown({ initialSeconds, onCountdownComplete }) {
    const [secondsRemaining, setSecondsRemaining] = useState(initialSeconds);

    useEffect(() => {
        const i = setInterval(() => {
            setSecondsRemaining((prevSeconds) => prevSeconds > 0 ? prevSeconds - 1 : initialSeconds);
        }, 1000);

        return () => clearInterval(i);
    }, []);

    useEffect(() => {
        //console.log("secondsRemaining:", secondsRemaining);
        if (secondsRemaining === 0 && typeof onCountdownComplete === 'function') {
            //console.log("süre bitti");
            onCountdownComplete();
        }
    }, [secondsRemaining]);

    return convertToTime(secondsRemaining);
}

export default Countdown;
