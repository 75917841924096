import React from "react";

export default function SearchSkeleton(props) {

    return (
        <>
            <div className="playlist-wrapper skeleton">
                <span className="text-large animate"></span>
                <div className="playlist grid-type">
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                    <span className="item animate"></span>
                </div>
            </div>
        </>
    );
}
