import React from "react";
export default function SubscriptionIcon(props) {
  return (
    <>
      <div className={`subscription-icon ${props.active ? "active" : ""}`}>
        <svg
          width={164}
          height={209}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m81.74 185.045-13.015 23.759h26.029l-13.015-23.759Z"
            fill="#333"
            id="path-1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M163.218 78.024C163.218 32.988 126.711 0 81.672 0v.16C36.672.193.2 33.17.2 78.186c0 58.576 73.576 107.439 81.532 107.439v-.17c8.19-.172 81.487-48.965 81.487-107.43"
            fill="#333"
            id="path-2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M163.018 78.506c0-45.037-63.518 9.393-77.018 22.393s-27.5 10-41.5 0S0 33.652 0 78.666c0 58.576 73.576 107.439 81.532 107.439v-.17c8.19-.172 81.486-48.964 81.486-107.43Z"
            fill="#222"
            style={{
              mixBlendMode: "screen"
            }}
            opacity={0.6}
            id="path-3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.499 160.398c-50.5 14.5-53 0-60.5-12s-15.999-7-15.999-7-9.5 3-5.001 12.5c18 16 30.5 27.5 44.532 32.206v-.169c8.968-3.037 19.968-10.537 36.968-25.537Z"
            fill="#333"
            style={{
              mixBlendMode: "screen"
            }}
            opacity={0.6}
            id="path-4"
          />
        </svg>
      </div>
    </>
  );
}
