export function init() {
	if (window.arSmartTV) {
		// Arcelik SDK yüklendi mi check ediliyor.
		window.arSmartTV.init(
			() => {
				//console.log("Arcelik sdk initialized.");
				try {
					window.modelName = getDeviceName();
				} catch (error) {
					//console.log("Error code = " + error);
				}

				try {
					window.version = getSWVersion(); //getFirmware();
				} catch (error) {
					//console.log("Error code = " + error);
				}

				try {
					window.deviceID = getUID();
				} catch (error) {
					//console.log("Error code = " + error);
				}
				
				try {
					window.brand =
						window.brand == "unknown" ? getBrand() : window.brand;
				} catch (error) {
					//console.log("Error code = " + error);
				}
			},
			() => {
				window.modelName = "Arcelik sdk not initialized!";
				//console.log("Arcelik sdk not initialized!");
			}
		);
	} else {
		window.modelName = "Arcelik sdk not found!";
		//console.log("Arcelik sdk not found!");
	}
}

export function exit() {
	window.close();
}

function getFirmware() {
	let value = "unknown";
	if (window.arSmartTV) {
		try {
			value = window.arSmartTV.getProductInfo().getFirmware();
			//console.log(" Firmware value = " + value);
		} catch (error) {
			//console.log("Error code = " + error.code);
		}
	}

	if (value === "unknown") {
		try {
			var e = String(navigator.userAgent).match(/Firmware\/([\d\.\-\_]+)/);
			value = e ? e[1] : "";
			//console.log(" Firmware value = " + value);
		} catch (error) {}
	}
	return value;
}

function getDeviceName() {
	let value = "unknown";
	if (window.arSmartTV) {
		try {
			value = window.arSmartTV.getProductInfo().productName;
			//console.log(" Firmware value = " + value);
		} catch (error) {
			//console.log("Error code = " + error.code);
		}
	}
	return value;
}

function getUID() {
	var UID = "unknown";
	try {
		var e = window.arSmartTV.getNetworkInfo();
		UID =
			"wired" == e.connectionType ? e.wiredMacAddress : e.wirelessMacAddress;
	} catch {}
	return UID;
}

function getBrand() {
	var brand = "unknown";
	try {
		brand = window.arSmartTV.getBrand();
	} catch {}
	return brand;
}

function getSWVersion() {
	var v = "unknown";
	var productInfo = window.arSmartTV.getProductInfo();
	try {
		v = productInfo.swVersion;
	} catch {}
	return v;
}

// not: arSmartTV initialize olmadan çalışmaz.
function networkStatus() {
	var networkInfo = window.arSmartTV.getNetworkInfo();
	return (
		networkInfo.IPv4.internetConnectionAvailable === true ||
		networkInfo.IPv6.internetConnectionAvailable === true
	);
}

// export function getLanguage() {
// 	return navigator.language;
// }

// var deviceInfo = arSmartTV.getProductInfo();
// var networkInfo = arSmartTV.getNetworkInfo();
// document.getElementById("product").innerHTML=deviceInfo.productName;
// document.getElementById("productNameTitle").innerHTML= "(" + deviceInfo.productName + ")";
// document.getElementById("prod_date").innerHTML=months[deviceInfo.productionMonth-1]+" "+deviceInfo.productionYear;
// document.getElementById("netflix_status").innerHTML=(deviceInfo.netflixEnabled === true ? "enabled" : "disabled");
// document.getElementById("serial").innerHTML=deviceInfo.serialNumber;
// document.getElementById("sw_ver").innerHTML=deviceInfo.swVersion;
// document.getElementById("hw_ver").innerHTML=deviceInfo.hwVersion;
// document.getElementById("swPlatformCode").innerHTML=(typeof deviceInfo.swPlatformCode !== "undefined" ? deviceInfo.swPlatformCode : "");
// document.getElementById("menu_lang_via_mwb").innerHTML=navigator.language;
// document.getElementById("menu_lang").innerHTML=arSmartTV.getLanguage();
// document.getElementById("country").innerHTML=arSmartTV.getCountry();
// document.getElementById("brand").innerHTML=arSmartTV.getBrand();
// document.getElementById("local_ip").innerHTML=networkInfo.IPv4.ipAddress;
// document.getElementById("broadband_ip").innerHTML=networkInfo.IPv4.broadbandIpAddress;
// document.getElementById("ipv4_gateway").innerHTML=networkInfo.IPv4.gatewayAddress;
// document.getElementById("ipv4_dns").innerHTML=networkInfo.IPv4.dnsAddress;
// document.getElementById("wired_mac").innerHTML=networkInfo.wiredMacAddress;
// document.getElementById("wireless_mac").innerHTML=networkInfo.wirelessMacAddress;
// document.getElementById("conn_type").innerHTML=networkInfo.connectionType;
// document.getElementById("ipv4_enabled").innerHTML=(networkInfo.IPv4Enabled === true ? "enabled" : "disabled");
// document.getElementById("ipv6_enabled").innerHTML=(networkInfo.IPv6Enabled === true ? "enabled" : "disabled");
// document.getElementById("internet_access").innerHTML=(networkInfo.IPv4.internetConnectionAvailable === true || networkInfo.IPv6.internetConnectionAvailable === true ? "available" : "unavailable");
// document.getElementById("gateway_access").innerHTML=(networkInfo.IPv4.gatewayConnectionAvailable === true || networkInfo.IPv6.gatewayConnectionAvailable === true ? "available" : "unavailable");
// document.getElementById("ssid").innerHTML=networkInfo.ssid;
// document.getElementById("agent").innerHTML= navigator.userAgent;
// document.getElementById("screen_size").innerHTML= screen.width + 'x' + screen.height;
// document.getElementById("window_size").innerHTML= window.innerWidth + 'x' + window.innerHeight;

// {
//     LEFT: 37,
//     RIGHT: 39,
//     UP: 38,
//     DOWN: 40,
//     ENTER: 13,
//     RETURN: 461,
//     ZERO: 48,
//     ONE: 49,
//     TWO: 50,
//     THREE: 51,
//     FOUR: 52,
//     FIVE: 53,
//     SIX: 54,
//     SEVEN: 55,
//     EIGHT: 56,
//     NINE: 57,
//     RED: 403,
//     GREEN: 404,
//     YELLOW: 405,
//     BLUE: 406,
//     PLAY: 415,
//     PAUSE: 19,
//     STOP: 413,
//     REC: 416,
//     FF: 417,
//     RW: 412,
//     TOOLS: 470,
//     PUP: 427,
//     PDOWN: 428,
//     CHLIST: -1,
//     PRECH: -1,
//     TXTMIX: -1,
//     FAVCH: -1,
//     EXIT: -1,
//     INFO: -1
// }

// arSmartTV.startAppWithConfig({
//     app_name: "ARCELIK_PORTAL",
//     app_type: "web",
//     app_id: 169,
//     dev_name: "pisti",
//     app_logo: "",
//     app_url: url,
//     user_agent: "",
//     app_mode: "overlay",
//     use_vka: false,
//     use_mouse: false,
//     use_video: true,
//     use_audio: true,
//     has_border: false,
//     kiosk_mode: false,
//     allow_close_window: true,
//     disable_web_security: true,
//     enable_loading_animation: true,
//     height: 1080,
//     width: 1920,
//     dtv_video: {
//         visible: true,
//         x_pos: 0,
//         y_pos: 0,
//         width: 640,
//         height: 360
//     }
// });