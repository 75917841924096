import React from "react";

export default function VideoBackButton(props) {
  return (
    <button
        type="button"
        onClick={props.onClick}
        className={`${props.className} ${props.focused ? "selected" : ""}`}>

        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z" fill="#333333"/>
            <path d="M27 33.5L17 24L27 14.5" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

      {/* <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_30811_1414)">
          <rect width="48" height="48" rx="24" fill="#333333" />
          <path
            d="M27 33.5L17 24L27 14.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_30811_1414"
            x="-15"
            y="-15"
            width="78"
            height="78"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImage" stdDeviation="7.5" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_30811_1414"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_30811_1414"
              result="shape"
            />
          </filter>
        </defs>
      </svg> */}
      {props.title}
      <span>{props.subTitle}</span>
    </button>
  );
}
