import React from "react";

export default function Button(props) { 
  return (
      <button
        type="button"
        id={props.id}
        className={`${props.className} ${props.focused ? "selected" : ""}`}
        onClick={props.onClick} 
        disabled={props.disabled}
        >
            {props.icon}
            {props.title}
            {props.animationTime && <i style={{ '--intro-time' : props.animationTime +'s'}} className="into-bg-slide"></i> }
            {props.rightText !== "" && props.rightText}
            {props.checkIcon && props.checkIcon}
      </button>
  );
}