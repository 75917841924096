import React, { useState, useLayoutEffect, useContext, useCallback, memo } from "react";
import { MediaContext } from "../provider/generalProvider";
import FeaturedMediaSummary from "../components/featuredMediaSummary";
import SliderContainer from "../components/Slider/sliderContainer";
import MainWrapperBox from "../components/mainWrapperBox";
import withDirectionFocusable from "../components/withDirectionFocusable";
import { pageView } from "../services/gtm";
import {
	changeFeaturedMediaInformations,
	scrollActive,
} from "../helper/featuredMediaHelper";
import {
	getRandomVideoFromPlaylist,
	getGuncelPlaylists,
} from "../services/gainAPI";
//Skeleton
import FeaturedmediaSkeleton from "../components/Skeleton/featuredMedia";
import SliderSkeleton from "../components/Skeleton/slider";
import * as Sentry from "@sentry/react";

const FeaturedMediaSummaryFocusable = withDirectionFocusable(FeaturedMediaSummary);
const SliderContainerFocusable = withDirectionFocusable(SliderContainer);
// const BackButtonFocusable = withDirectionFocusable(BackButton);

function Home(props) {
	// const navigate = useNavigate();
	const { changeSidebarItem } = useContext(MediaContext);
	const [sliderData, setSliderData] = useState(null);
	const [initialFeaturedMedia, setInitialFeaturedMedia] = useState(null); // Featured media olarak ilk gelen içerik
	const [sliderFocus, setSliderFocus] = useState(null);

	useLayoutEffect(() => {
		document.title = "Keşfet";
		pageView();

		changeSidebarItem("home");
		scrollActive(false);

		getRandomVideoFromPlaylist("Hx3DX26W").then(function (content) {
			//  console.log("content",content);
			if (content !== null) {
				//content.watchNow = {id:'v4n1Xhxj', isTrailer: false};
				setInitialFeaturedMedia(content);
			} else {
				var log = "Hero playlist datası gelmedi!";
				Sentry.captureMessage(log);
			}
		});

		return () => {
			setInitialFeaturedMedia(null);
			setSliderData(null);
			setSliderFocus(null);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		async function getData() {
			await Promise.all([
				getGuncelPlaylists(5, 0), // "İzlemeye Devam Et" playlistinin datasının dönmesi için ilk 5 playlist çekiliyor.
				getGuncelPlaylists(40, 5), // 5. playlistten sonraki tüm playlistler çekiliyor.
			]).then((result) => {
				var sliderData = { data: { playlists: [] } };
				result.forEach(function (s) {
					s.data.playlists.forEach(function (p, index) {
						if (p.playlistId === undefined) {
							// "izlemeye devam et" playlistine özel playlistId set ediliyor.
							s.data.playlists[index].playlistId = "custom" + index;
						}
					});
					sliderData.data.playlists = sliderData.data.playlists.concat(
						s.data.playlists
					);
				});
				setSliderData(sliderData.data);
				//props.setFocus("WatchNowButton");
			});
		}
		getData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const onBecameFocusedFirstSlider = useCallback(() => {
        if (sliderFocus === null) {
          //console.log("Slider ilk defa focus oluyor");
          setSliderFocus(true);
          document
            .querySelectorAll(".lazy-load-slider-image")
            .forEach(function (img) {
              img.setAttribute("src", img.getAttribute("data-src"));
              img.classList.remove("lazy-load-slider-image");
            });
        }
      }, []);

	return (
		//console.log("home render"),
		<>
			<MainWrapperBox
				focusable={true}
				pageClass={"playlist-item-border"}
				focusKey={"MainWrapper"}
			>
				{/* <LoadingFullscreen show={loading} /> */}

				<span id="logo-gain"></span>

				{/* {
                    <p style={{
                            "zIndex" : 100,
                            "position" : "fixed",
                            "width" : "100%",
                            "fontSize" : "2rem",
                            "padding": "1rem"
                            }}>
                            {"modelName: " + window.modelName}<br />
                            {"brand: " + window.brand}<br />
                            {"version: " + window.version}<br />
                            {"deviceID: " + window.deviceID}<br />
                            {"chromiumVersion: " + window.chromiumVersion}<br />
                            {"userAgent: " + navigator.userAgent}
                        </p>
                } */}

				{sliderData !== null && initialFeaturedMedia !== null ? (
                    //console.log("initialFeaturedMedia:", initialFeaturedMedia),
					<FeaturedMediaSummaryFocusable
						forgetLastFocusedChild={false}
						focusKey={"FeaturedMediaSummaryFocusable"}
						ShowDescription={true}
						ShowWatchNowButton={true}
						ShowDetailsButton={true}
						ShowLikeDislike={false}
						ShowAddToListButton={false}
						ShowTitleButtons={false}
						initialFeaturedMedia={initialFeaturedMedia}
						onBecameFocused={() => {
							changeFeaturedMediaInformations(initialFeaturedMedia);
						}}
						isHomePage={true}
					/>
				) : (
					<FeaturedmediaSkeleton />
				)}

				{sliderData !== null && initialFeaturedMedia !== null ? (
					//console.log("sliderData:", sliderData),
					<SliderContainerFocusable
						lazyLoad={true}
						data={sliderData}
						onBecameFocused={onBecameFocusedFirstSlider}
						initialFeaturedMedia={initialFeaturedMedia}
						canYouGoUp={true} // true = ilk sliderdan yukarı çıkıldığında featured alana gidebilir
						focusKey={"Slider"}
					/>
				) : (
					<SliderSkeleton />
				)}
			</MainWrapperBox>
		</>
	);
}

export default memo(Home)