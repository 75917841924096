import React from "react";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { getScreenSize } from "../helper/platform";

// This function takes a component...
export default function withDirectionFocusable(WrappedComponent, selectData) {
	const FocusableComponent = withFocusable()(WrappedComponent);
    const fSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
	// ...and returns another component...
    
    function convertRemToPixels(rem) {    
        return Math.round(rem * parseFloat(fSize));
    }

    function setCaretPosition(ctrl, pos) {
        // Modern browsers
        if (ctrl.setSelectionRange) {
            ctrl.focus();
            ctrl.setSelectionRange(pos, pos);

            // IE8 and below
        } else if (ctrl.createTextRange) {
            var range = ctrl.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    }

	return class extends React.Component {
		constructor(props) {
			super(props);
		}

		handleArrowDirection = (direction, event) => {
			const { left, right, up, down, index, playlistId, cardId, totalCard, userPremium } = this.props;

            // Search inputuna focus durumdayken cursor'ı sağ/sol yapabilmek için eklendi. 
            // NOT: inputun type'ı text olduğunda çalışabiliyor.
            if(
                document.activeElement !== null  && 
                document.activeElement.hasAttribute("id") === true && 
                (
                    document.activeElement.getAttribute("id") === "search" || 
                    document.activeElement.getAttribute("id") === "password" || 
                    document.activeElement.getAttribute("id") === "email"
                ) && 
                (direction === "left" || direction === "right")
                ) {

                    if (document.activeElement.value.length === 0) {
                        return;
                    }

                    var currentpos = document.activeElement.selectionStart;
                    var activeElementID = document.activeElement.getAttribute("id");
                
                    try{
                        // input içerisinde sanal klavye ya da 
                        setCaretPosition(document.activeElement, direction === "left" ? currentpos - 1 : currentpos + 1);
                        if(activeElementID === "email" && direction === "left"){
                            return false;
                        }
                    }
                    catch(err){}

                    // email inputunda focus durumdayken sanal klavyedeki sağ/sol tuşu ile karakterler arası gidilebilmesi sağlandı.
                    if (activeElementID === "email" && direction === "right" && document.activeElement.value.length !== currentpos) {
                        //console.log(document.activeElement.value.length, currentpos);
                        return false;
                    }

                    // password inputunda en son karakterde ise ve sağ tuşa basılmışsa sağındaki butona focuslanabilmesi sağlanıyor.
                    if (activeElementID === "password" && direction === "right" && document.activeElement.value.length !== currentpos) {
                        //console.log(document.activeElement.value.length, currentpos);
                        return false;
                    }

                    // search inputunda en az 1 karakter girilmiş ise sol tuşa basıldığında menüye gitmesi engellenmiş olur
                    if (activeElementID === "search" && direction === "left" && document.activeElement.value.length > 0) {
                        //console.log(document.activeElement.value.length, currentpos);
                        return false;
                    }
            }

            // console.log(document.activeElement);
            // if(
            //     document.activeElement !== null 
            //     && ( document.activeElement.classList.contains("buttonTrackOption") ) 
            //     && ( direction === "left" )
            //     ) {
            //         if (document.activeElement.value.length === 0) {
            //             return;
            //         }

            //         console.log("test");
            //         document.getElementById('text-and-audio-tracks').style.display = 'none';
            //     }

			switch (direction) {
				case "left":
					if (left) {
                        if(left === "InputSearch"){
                            //event.setFocus("InputSearch");
                            return false;
                        }
                        else if(left === "TransformSliderLeft"){
                            var selectedElement = document.querySelector('.playlist-wrapper .selected');
                            if(selectedElement){
                                //// 1. YÖNTEM
                                const cardWith = selectedElement.offsetWidth + convertRemToPixels(2);
                                // console.log("slider içinde sola gittin", index, cardWith);
                                const parentPlaylist = selectedElement.closest('.playlist');
                                let currentX = cardWith + parseInt(parentPlaylist.getAttribute("transformx"));
                                parentPlaylist.setAttribute("transformx", currentX);
                                selectedElement.closest('.playlist').style.transform = 'translateX('+ currentX +'px) translateZ(0)'; 

                                /// 2. YÖNTEM
                                // const cardWith = selectedElement.offsetWidth + convertRemToPixels(2.04); // 20px sağdan boşluk, 4px'de border var.
                                // const parentPlaylist = selectedElement.closest('.playlist');
                                // parentPlaylist.scrollLeft -= cardWith;
                            }
                            // console.log(playlistId + "-" + (index - 1));
                            event.setFocus(playlistId + "-" + (index - 1));
                            return false;
                           
                        }else if(left === "VideoPlayButton"){
                            // console.log("videoplaybutton click : left");
                            // document.getElementById('text-and-audio-tracks').style.display = 'none';
                            // document.getElementsByClassName('btn-subtitle')[0].classList.remove('selected');
                            event.setFocus("VideoPlayButton");
                            return false;
                        }else{ //if(left == "TransformSliderLeft"){
                             // console.log("slider dışında sola gittin");
                             event.setFocus(left);
                             return false;
                        }
					}
					return true;
				case "right":
					if (right) {
                        if(right === "InputSearch"){
                            //event.setFocus("InputSearch");
                            return false;
                        }
                        else if(right === "VideoPlayButton"){
                            // console.log("videoplaybutton click : right");
                            event.setFocus("VideoPlayButton");
                            return false;
                        }
                        else if(right !== "TransformSliderRight"){
                            // console.log("slider dışında sağa gittin");
                            event.setFocus(right);
                            return false;
                        }
                        else{ //if(left == "TransformSliderLeft"){
                            let selectedElement = document.querySelector('.playlist-wrapper .selected');
                            if(selectedElement){
                                //// 1. YÖNTEM
                                const cardWith = selectedElement.offsetWidth + convertRemToPixels(2);
                                // console.log("slider içinde sağa gittin", index, cardWith);
                                const parentPlaylist = selectedElement.closest('.playlist');
                                let currentX = parseInt(parentPlaylist.getAttribute("transformx")) - cardWith;
                                parentPlaylist.setAttribute("transformx", currentX);
                                selectedElement.closest('.playlist').style.transform = 'translateX('+ currentX +'px) translateZ(0)'; 

                                /// 2. YÖNTEM
                                // const cardWith = selectedElement.offsetWidth + convertRemToPixels(2.04); // 20px sağdan boşluk, 4px'de border var.
                                // const parentPlaylist = selectedElement.closest('.playlist');
                                // parentPlaylist.scrollLeft += cardWith;
                            }
                            // console.log(playlistId + "-" + (index + 1));
                            event.setFocus(playlistId + "-" + (index + 1));
                            return false;
                        }
					}
					return true;
				case "up":
					if (up) {
                        if(up === "TransformTitleDetailUp"){
                            // 6 dan 5 e geçerken -1530 dan -1275 e geçiyor 
                            

                            let selectedElement = document.querySelector('.title-detail-list .selected');
                            let elementMarginBottom;
                            if(getScreenSize().width >= 1920){
                                elementMarginBottom = 4; 
                            }else{
                                elementMarginBottom = 3; 
                            } 
                            if(selectedElement){
                                let cardHeight =  selectedElement.offsetHeight + convertRemToPixels(elementMarginBottom);
                                let parentCard = selectedElement.closest('.title-detail-list');
                                let currentX = parseInt(parentCard.getAttribute("transformy"));
                                currentX = currentX + cardHeight;
                                parentCard.setAttribute("transformy", currentX); 
                                if(userPremium == false){
                                    if(cardId <= 2 ){ 
                                        selectedElement.closest('.title-detail-list').style.transform = 'translateY('+ currentX +'px) translateZ(0)'; 
                                    }else{  
                                        selectedElement.closest('.title-detail-list').style.transform = 'translateY('+ ( currentX - cardHeight) +'px) translateZ(0)'; 
                                    }
                                }else{ 
                                    selectedElement.closest('.title-detail-list').style.transform = 'translateY('+ currentX +'px)  translateZ(0)'; 
                                }
                            }
                        }else if(up === "TransformSearchUp"){ // Search Arama Sonuçları 
                            let selectedElement = document.querySelector('.search-playlist .selected');
                           // var cardRow = Math.ceil(totalCard / 4);
                           let cardRow = totalCard / 4; // Alt sayfada kalan değeri hesaplayıp 'translateY' özelliğini kapatmalıyız.
                           let int_part = Math.trunc(cardRow); // Sayının noktadan soldaki değerini verir
                           let float_part = Number((cardRow-int_part).toFixed(2)); // noktanın sağındaki değeri verir
                           let lastItemsStatus = 1 / float_part;
                           let offsetFocus;
                            if(lastItemsStatus === 4){
                                offsetFocus = 3;
                            }else if(lastItemsStatus === 3){
                                offsetFocus = 2;
                            }else if(lastItemsStatus === 2){
                                offsetFocus = 1
                            }else{
                                offsetFocus = 0
                            }
                            if(selectedElement){
                                let cardHeight =  selectedElement.offsetHeight + convertRemToPixels(2);
                                let parentCard = selectedElement.closest('.play-list-tranform');
                                let currentX = parseInt(parentCard.getAttribute("transformy"));
                                currentX = currentX + cardHeight; 
                                parentCard.setAttribute("transformy", currentX); 
                                // console.log('Index : ',index,' offsetFocus : ',offsetFocus,' TotalCard : ',totalCard);
                                if(offsetFocus === 3){ 
                                    if( index <= totalCard - 6){ 
                                        selectedElement.closest('.play-list-tranform').style.transform = 'translateY('+ currentX +'px)';
                                    } 
                                }else if(offsetFocus === 2){
                                    if( index <= totalCard - 7){ 
                                        selectedElement.closest('.play-list-tranform').style.transform = 'translateY('+ currentX +'px)';
                                    } 
                                }else if(offsetFocus === 1){
                                    if( index <= totalCard - 8){ 
                                        selectedElement.closest('.play-list-tranform').style.transform = 'translateY('+ currentX +'px)';
                                    } 
                                } 
                            }   
                            // console.log(lastItemsStatus);  
                        }else{
                            event.setFocus(up);
                            return false;
                        }
					}
					return true;
				case "down":
					if (down) {
                        if(down === "TransformTitleDetailDown"){ 
                            // console.log(cardId);
                            let selectedElement = document.querySelector('.title-detail-list .selected');
                            let elementMarginBottom;
                            if(window.innerWidth >= 1920){
                                elementMarginBottom = 4;
                            }else{
                                elementMarginBottom = 3; 
                            } 
                            if(selectedElement){
                                let cardHeight =  selectedElement.offsetHeight + convertRemToPixels(elementMarginBottom);
                                let parentCard = selectedElement.closest('.title-detail-list');
                                let currentX = parseInt(parentCard.getAttribute("transformy"));
                                currentX = currentX - cardHeight; 
                                parentCard.setAttribute("transformy", currentX); 
                                if(cardId >= 1 && userPremium == false){ 
                           
                                    selectedElement.closest('.title-detail-list').style.transform = 'translateY('+  (currentX - cardHeight) +'px)'; 
                                }else{
                                    selectedElement.closest('.title-detail-list').style.transform = 'translateY('+ currentX +'px)'; 
                                } 
                            }
                           
                        }else if(down === "TransformSearchDown"){ // Search Arama Sonuçları 
                            let selectedElement = document.querySelector('.search-playlist .selected');
                           // var cardRow = Math.ceil(totalCard / 4);
                           let cardRow = totalCard / 4; // Alt sayfada kalan değeri hesaplayıp 'translateY' özelliğini kapatmalıyız.
                           let int_part = Math.trunc(cardRow); // Sayının noktadan soldaki değerini verir
                           let float_part = Number((cardRow-int_part).toFixed(2)); // noktanın sağındaki değeri verir
                           let lastItemsStatus = 1 / float_part;
                           let offsetFocus;
                            if(lastItemsStatus === 4){
                                offsetFocus = 3;
                            }else if(lastItemsStatus === 3){
                                offsetFocus = 2;
                            }else if(lastItemsStatus === 2){
                                offsetFocus = 1
                            }else{
                                offsetFocus = 0
                            }
                            if(selectedElement){
                                let cardHeight =  selectedElement.offsetHeight + convertRemToPixels(2);
                                let parentCard = selectedElement.closest('.play-list-tranform');
                                let currentX = parseInt(parentCard.getAttribute("transformy"));
                                currentX = currentX - cardHeight; 
                                parentCard.setAttribute("transformy", currentX); 
                                //console.log('Index : ',index,' Total : ',totalCard);
                                if(offsetFocus === 3){ 
                                    if( index <= totalCard - 10){
                                        selectedElement.closest('.play-list-tranform').style.transform = 'translateY('+ currentX +'px)';
                                    } 
                                }else if(offsetFocus === 2){
                                    if( index <= totalCard - 11){
                                        selectedElement.closest('.play-list-tranform').style.transform = 'translateY('+ currentX +'px)';
                                    } 
                                }else if(offsetFocus === 1){
                                    if( index <= totalCard - 12){
                                        selectedElement.closest('.play-list-tranform').style.transform = 'translateY('+ currentX +'px)';
                                    } 
                                } 
                            }   
                            // console.log(lastItemsStatus);  

                        }else{
                            event.setFocus(down); 
                            return false;
                        }
					}
					return true;
				default:
					break;
			}
		};

		render() {
			// ... and renders the wrapped component with the fresh data!
			// Notice that we pass through any additional props
			return (
				<FocusableComponent
					onArrowPress={this.handleArrowDirection}
					{...this.props}
				/>
			);
		}
	};
}
