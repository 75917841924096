import React, { useEffect } from "react"; 
import { useNavigate, useSearchParams } from "react-router-dom";

function RedirectToVideo(props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

	useEffect(() => {
        var id = searchParams.get("id");
        var titleId = searchParams.get("titleId"); 
        navigate("/video?id=" + id+"&titleId="+ titleId);
    }, []); 

    return null;
}

export default RedirectToVideo;
