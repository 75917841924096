import React, {
    useEffect,
    useState
} from "react";
import * as Constants from "../constants/constants";
import Button from "../components/Button/button";
import BackLink from "../components/Button/backLink";
import imgBalloon from "../assets/img/balloon.svg";
import TitleDetailWrapperBox from "../components/titleDetailWrapperBox";
import withDirectionFocusable from "../components/withDirectionFocusable";
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";
import EpisodeCard from "../components/episodeCard";
// import PremiumCard from "../components/premiumCard";
import {
    pageView
} from "../services/gtm";
import {
    getTitle,
    getCurrentSeason,
    getEpisodesBySeasonID,
    getPremiumInformation,
} from "../services/gainAPI";
import {
    getLogoImage,
    getThumbnailImage
} from "../helper/imageHelper";

//Skeleton
import TitleDetailSkeleton from "../components/Skeleton/titleDetail";

const BackLinkFocusable = withDirectionFocusable(BackLink);
const ButtonFocusable = withDirectionFocusable(Button);
const EpisodeCardFocusable = withDirectionFocusable(EpisodeCard);

export default function TitleDetail(props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [currentMedia, setCurrentMedia] = useState(null);
    const [currentSeason, setCurrentSeason] = useState(null);
    const [seasonTabs, setSeasonTabs] = useState(null);
    const [cardLastFocus, setCardLastFocus] = useState('Episode-0');
    const [userPremium, setUserPremium] = useState(null);
    const [episodeLoading, setEpisodeLoading] = useState(false);
    //const currentSeasonRef = useRef(true);

    useEffect(() => {
        var id = searchParams.get("id");

        getTitle(id).then(function (response) {
            if (response !== null && response.data !== null) {
                document.title = response.data.metadata.name;
                pageView();

                props.setFocus("BackButton");
                setCurrentMedia(response.data);
                // console.log(response.data);
            }
        });

        // User Premium Bilgisi
        getPremiumInformation().then(function (result) {
            result !== null && result.isPremium === true ?
                setUserPremium(true) :
                setUserPremium(false)
            // console.log('result.isPremium',result.isPremium);

            getCurrentSeason(id).then(function (response) {
                if (response !== null && response.data !== null) {
                    // console.log(response.data);
    
                    // ilk ya da kaldığı sezon.
                    setCurrentSeason(response.data.episodes);
    
                    // sezon tablerini çek
                    setSeasonTabs(response.data.seasonInfo);
    
                    props.setFocus("Episode-0");
                }
            });
        });
        
        return () => {
            setEpisodeLoading(false);
        }

    }, []);


    useEffect(() => {
        if (episodeLoading === true) {
            setCurrentSeason(null);
        }

    }, [episodeLoading]);

    async function getPlaylistBySeasonID(seasonID) {
        getEpisodesBySeasonID(seasonID).then(function (response) {
            if (response !== null && response.data !== null) {
                //console.log(response.data);

                // ilk ya da kaldığı sezon.
                setCurrentSeason(response.data.episodes);

                // sezon tablerini çek
                setSeasonTabs(response.data.seasonInfo);
            }

            setEpisodeLoading(false);
        });
    }

    function seasonButtonChange(i) {
        props.setFocus("Episode-0");
        if (document.querySelectorAll(".episode-buttons-list button.active").length > 0) {
            document.querySelectorAll(".episode-buttons button.active")
                .forEach((item) => {
                    item.classList.remove("active");
                });
        }

        document.querySelectorAll(".episode-buttons-list button")[i].classList.add("active");
        document.querySelector('.title-detail-list').style.transform = 'translateY(0px)';
        document.querySelector('.title-detail-list').setAttribute("transformy", 0);
    }

    function renderLogoOrTitle() {
        if (currentMedia.metadata.images.logos !== null && currentMedia.metadata.images.logos.length > 0) {
            var logoImageURL = getLogoImage(currentMedia.metadata.images, 533);
            return (
                <>
                    <img className="logo" src={logoImageURL} alt=" " />
                </>
            );
        }
        else {
            return (
                <>
                    <div className="title flex-ai-end">
                        <h2>{currentMedia.metadata.name}</h2></div>
                </>
            );
        }
    }

    return (
        <>
            <TitleDetailWrapperBox pageClass={"title-detail-wrapper"} focusKey={"MainWrapper"} key={"MainWrapper"}>
                <div className="page-gradient"></div>

                <span id="logo-gain"></span>

                <BackLinkFocusable
                    down={"SeasonTab-0"}
                    right={"Episode-0"}
                    focusKey={"BackButton"}
                    firstText={"geri için"}
                    onEnterPress={
                        () => {
                            navigate("/title?id=" + searchParams.get("id"));
                        }
                    }
                    link={
                        "/title?id=" + searchParams.get("id")
                    }
                    icon={
                        <i>
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg" >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.6667 -8.46519e-05C25.8714 -8.50542e-05 33.3333 7.46184 33.3333 16.6666C33.3333 25.8713 25.8714 33.3333 16.6667 33.3333C7.46192 33.3333 -3.26171e-07 25.8713 -7.28523e-07 16.6666C-1.13088e-06 7.46184 7.46192 -8.42495e-05 16.6667 -8.46519e-05ZM8.39289 15.4073C8.12322 15.751 7.9614 16.1833 7.95841 16.6533L7.95837 16.6665L7.95837 16.6666C7.95837 17.2374 8.19262 17.7535 8.57023 18.124L15.223 24.7768C16.0203 25.5741 17.3131 25.5741 18.1104 24.7768C18.9077 23.9795 18.9077 22.6868 18.1104 21.8894L14.9292 18.7083L23.3334 18.7083C24.461 18.7083 25.375 17.7942 25.375 16.6666C25.375 15.539 24.461 14.6249 23.3334 14.6249L14.9289 14.6249L18.1104 11.4435C18.9077 10.6461 18.9077 9.35343 18.1104 8.55611C17.3131 7.75879 16.0203 7.75879 15.223 8.55611L8.55636 15.2228C8.4979 15.2812 8.44335 15.3429 8.39289 15.4073Z"
                                    fill="currentColor" />
                            </svg></i>
                    }
                />

                {!currentMedia && !currentSeason &&
                    <TitleDetailSkeleton onlyEpisodes={false} />}

                {episodeLoading === true &&
                    <TitleDetailSkeleton onlyEpisodes={true} />}
                {
                    currentMedia &&
                    (
                        <div id="featured-media-summary" className="media-summary">
                            {renderLogoOrTitle()}

                            <ul className="info">
                                <li className="li-balloon" style={
                                    {
                                        display: currentMedia.metadata.isGainOriginals !== undefined && currentMedia.metadata.isGainOriginals === true ? "flex" : "none"
                                    }
                                }>
                                    <img className="balloon" src={imgBalloon} alt=" " />
                                </li>

                                <li><span>{currentMedia.metadata.category}</span></li>

                                {
                                    currentMedia.seasonCount !== "" &&
                                    (
                                        <><li><span>{currentMedia.seasonCount} Sezon</span><i></i></li> </>
                                    )
                                }

                                {
                                    currentMedia.contentRatings != null &&
                                    currentMedia.contentRatings.length > 0 && (
                                        <>
                                            <li> {
                                                currentMedia.contentRatings.map((item) => (
                                                    <span key={'rtuk-item-' + item} className={"rtuk " + item} ></span>
                                                ))
                                            } </li>
                                        </>
                                    )
                                }

                                {
                                    currentMedia.metadata.imdbScore !== "" && (<li><span className="imdb" > {currentMedia.metadata.imdbScore}</span></li>)
                                }

                            </ul>

                            <div className="episode-buttons">
                                <span className="episode-title"> Sezonlar ve Bölümler </span>
                                <div className="episode-buttons-list"> {
                                    seasonTabs !== null &&
                                    seasonTabs.map((p, index) => (
                                        <ButtonFocusable
                                            focusKey={"SeasonTab-" + index}
                                            key={"season-tab-" + index}
                                            up={index === 0 ? "BackButton" : null}
                                            right={cardLastFocus}
                                            down={
                                                index + 1 === seasonTabs.length ?
                                                    'SeasonTab-' + index :
                                                    null
                                            }
                                            className={
                                                (currentMedia.continueWatch !== undefined && currentMedia.continueWatch !== null && currentMedia.continueWatch.seasonId === p.seasonId) ?
                                                    Constants.BUTTON_MEDIUM +
                                                    Constants.BUTTON_SEASON +
                                                    Constants.BUTTON_ACTIVE 
                                                    :
                                                    (currentMedia.continueWatch === undefined || currentMedia.continueWatch === null) && index === 0 ? 
                                                    Constants.BUTTON_MEDIUM +
                                                    Constants.BUTTON_SEASON +
                                                    Constants.BUTTON_ACTIVE 
                                                    :
                                                    Constants.BUTTON_MEDIUM +
                                                    Constants.BUTTON_SEASON
                                            }
                                            title={<span className={"left-span"}> {"Sezon " + (p.seasonNumber)} </span>}
                                            onEnterPress={
                                                () => {
                                                    // console.log(index);
                                                    setEpisodeLoading(true);
                                                    getPlaylistBySeasonID(p.seasonId);
                                                    seasonButtonChange(index);
                                                }
                                            }
                                            onClick={
                                                () => {
                                                    // console.log(index); 
                                                    setEpisodeLoading(true);
                                                    getPlaylistBySeasonID(p.seasonId);
                                                    seasonButtonChange(index);
                                                }
                                            }
                                        />
                                    ))
                                }
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    currentSeason !== null && userPremium !== null && (
                        <div className="title-detail-content" key="titleDetailContent">
                            <div className="title-detail-list" transformy="0" key="titleDetailList">
                                {/* {console.log(currentSeason)} */}
                                {
                                    currentSeason.map((episode, index) => {
                                        
                                        {/* let premiumCard;
                                        let hasAnyPremiumContent = currentSeason.filter(obj => obj.metadata.isFree === false).length > 0;
                                        if (hasAnyPremiumContent && index === 2 && userPremium !== null && userPremium !== true) {
                                            premiumCard = <PremiumCard key={"Premium-" + index} />
                                        } */}

                                        var progress = 0;
                                        if (episode.continueWatch !== null && episode.position !== 0) {
                                            progress = (Math.floor(episode.continueWatch.position * 100) / Math.floor(episode.metadata.duration));
                                            // console.log(episode.title,'-',continueTime,'- Yüzde: ',progress);
                                            // console.log(episode.metadata.images.thumbnails);
                                        }

                                        return (
                                            <React.Fragment key={index}>
                                                {/* {premiumCard} */}
                                                <div className="title-detail-track" key={"title-detail-track-" + index}>
                                                    {/* {console.log(index, currentSeason.length)} */}
                                                    <EpisodeCardFocusable
                                                        focusKey={"Episode-" + index}
                                                        key={"episode-card-" + index}
                                                        left={"SeasonTab-0"}
                                                        up={index === 0 || index >= currentSeason.length - 3 ? null : "TransformTitleDetailUp"}
                                                        down={index >= currentSeason.length - 4 ? null : "TransformTitleDetailDown"}
                                                        userPremium={userPremium}
                                                        link={"/video?id=" + episode.mediaId + "&titleId=" + searchParams.get("id")}
                                                        onEnterPress={() => { navigate("/video?id=" + episode.mediaId + "&titleId=" + searchParams.get("id")); }}
                                                        onBecameBlurred={() => { setCardLastFocus("Episode-" + index); }}
                                                        onClick={() => { navigate("/video?id=" + episode.mediaId + "&titleId=" + searchParams.get("id")); }}
                                                        img={getThumbnailImage(episode.metadata.images, 320)}
                                                        cardId={index}
                                                        titleSection={""}
                                                        titleName={episode.metadata.name}
                                                        detail={episode.metadata.description}
                                                        time={Math.floor(episode.metadata.duration / 60) + " dakika"}
                                                        remainingTime={""}
                                                        progress={progress}
                                                        forgetLastFocusedChild={true}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )
                }

            </TitleDetailWrapperBox> </>
    );
}