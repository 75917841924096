import React, { useState } from "react"; 

export default function ActionButton(props) {

  return (
    <>
      {props.likeBtn === true && (
        <>
          <button
            type="button" 
            className={`
              ${props.className}   
              ${props.rateStatus ? `${props.rateStatus === 1  ? "active" : ""}` : ""}  
              ${props.focused ? "selected" : ""}`}
              onClick={props.onClick}
          >
            {props.icon}
          </button>
        </>
      )}

      {props.dislikeBtn === true && (
        <>
          <button
            type="button" 
            className={`
              ${props.className}    
              ${props.rateStatus ? `${props.rateStatus === -1  ? "active" : ""}` : ""}  
              ${props.focused ? "selected" : ""}`}
              onClick={props.onClick}
          >
            {props.icon}
          </button>
        </>
      )}

      {props.addListBtn === true && (
        <>
          <button
            type="button" 
            className={`
              ${props.className}    
              ${props.focused ? "selected" : ""}`}
              onClick={props.onClick}
          >
            {props.icon}
            {props.title}
          </button>
        </>
      )}

    </>
  );
}

// butona selected classı eklenmesi gerekirse
// return <Link to={link} className={`btn btn-medium ${focused ? "selected":""}`} >
