import React from "react"; 

export default function SliderSkeleton(props) {

	return (
		// console.log("props.data:", props.data),

		<div className="playlist-wrapper">
			<div id="playlist-transformable">
				<div className="playlist-container skeleton">
					<span className="text-large animate"></span>
					<div className="playlist">
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
					</div>
				</div>
				<div className="playlist-container skeleton">
					<span className="text-large animate"></span>
					<div className="playlist">
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
						<span className="item animate"></span>
					</div>
				</div>
			</div>
		</div>
	);
}
