import React from "react";
import { Link } from "react-router-dom";

export default function SidebarItem(props) {
	return (
		<>
			<Link to={props.link} className={(props.cssClassName) + (props.focused ? "selected" : "")}>
				{props.icon}
				<span>{props.title}</span>
			</Link>
		</>
	);
}
