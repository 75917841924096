import Cookies from "universal-cookie";

let GAIN_API_URL = process.env.REACT_APP_GAIN_API_URL;

function getNoCacheParams(isFirstParams) {
    let startChar = isFirstParams ? '?' : '&';
    return startChar + 'rnd=' + new Date().getTime();
}

export async function login(email, password) {
    var formBody = JSON.stringify({
        username: email,
        password: password,
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // 'X-Client-Ip': IP,
            // 'X-Forwarded-For': IP
        },
        body: formBody
    };

    const data = await fetch(
        GAIN_API_URL + `/accounts/authenticate` + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            if (result !== null) {
                return {
                    uuid: result.account.uuid,
                    access_token: result.access_token,
                    refresh_token: result.refresh_token,
                    created_at: result.account.created_at,
                    is_premium: result.account.isPremium
                };
            } else {
                return null;
            }
        })
        .catch(error => {
            return null;
        });
    return data;
}

export async function getUserDetails() {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = await fetch(
        GAIN_API_URL + `/accounts` + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                //console.log(res.json());
                return res.json();
            } else if (res.status === 401) {
                var data = refreshToken().then((result) => {
                    return result
                });
                return data;
            } else {
                return null;
            }
        })
        .then((result) => {
            if (result !== null) {
                // {
                //     "id": 61436,
                //     "uuid": "162a5e67-539f-4c32-b745-f34316a25730",
                //     "email": "fixer123@fixer.com.tr",
                //     "username": "fixer123@fixer.com.tr",
                //     "full_name": "Uğur Çelenk",
                //     "referrer": "",
                //     "metadata": {
                //         "isVerified": "false",
                //         "iysPermitted": "false",
                //         "iysUpdateTime": "1645440933",
                //         "register_source": "inplayer",
                //         "source": "Web"
                //     },
                //     "social_apps_metadata": [],
                //     "roles": ["consumer"],
                //     "completed": true,
                //     "merchant_id": 51966,
                //     "merchant_uuid": "6a533428-5a4f-4144-9dc2-0b4f092d1f0a",
                //     "organisation_id": 0,
                //     "tenant_id": 61436,
                //     "tenant_uuid": "162a5e67-539f-4c32-b745-f34316a25730",
                //     "created_at": 1645440934,
                //     "updated_at": 1645440934,
                //     "date_of_birth": null
                // }
                return {
                    id: result.id,
                    uuid: result.uuid,
                    email: result.email,
                    full_name: result.full_name,
                    isPremium: result.isPremium,
                    created_at: result.created_at
                };
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });
    return data;
}

export function clearCookies() {
    const cookies = new Cookies();
    cookies.remove('access_token', {
        path: "/"
    });
    cookies.remove('refresh_token', {
        path: "/"
    });
    cookies.remove('uuid', {
        path: "/"
    });
}

export async function refreshToken() {
    const cookies = new Cookies();
    var refreshToken = cookies.get("refresh_token");
    if (refreshToken === undefined || refreshToken === null) {
        return null;
    }

    var url = GAIN_API_URL + "/accounts/authenticate/refresh-token";

    var formBody = JSON.stringify({
        grant_type: "refresh_token",
        refresh_token: refreshToken
    });

    // var formBody = new URLSearchParams();
    // formBody.append("grant_type", "refresh_token");
    // formBody.append("refresh_token", refreshToken);

    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            //"Content-Type": 'application/x-www-form-urlencoded'
        },
        body: formBody
    };

    const data = await fetch(url, requestOptions)
        .then((res) => {
            return res.status === 200 || res.status === 201 ? res.json() : clearCookies();
        })
        .then((result) => {
            // console.log("result:", result);
            if (result !== null) {
                // ref: https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie
                cookies.set("uuid", result.uuid, {
                    path: "/",
                    maxAge: 60 * 60 * 24 * 90,
                }); // 90 gün
                cookies.set("access_token", result.access_token, {
                    path: "/",
                    maxAge: 60 * 60 * 24 * 90,
                }); // 90 gün
                cookies.set("refresh_token", result.refresh_token, {
                    path: "/",
                    maxAge: 60 * 60 * 24 * 90,
                }); // 90 gün

                return {
                    uuid: result.account.uuid,
                    access_token: result.access_token,
                    refresh_token: result.refresh_token,
                    created_at: result.account.created_at,
                    is_premium: result.account.isPremium
                };
            } else {
                return null;
            }
        })
        .catch(error => {
            return null;
        });

    return data;
}

export async function getPremiumInformation() {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = await fetch(
        GAIN_API_URL + `/v2/accounts/premium` + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            // 200 değilse
            //{
            //    "data":
            //    {
            //        "isPremium": false,
            //    },
            //    "error": { }
            //}
            // console.log('login hatası');
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            if (result !== null) {
                //{
                //    "data": {
                //    "userId": 56557,
                //    "userUuid": "1b24461e-8c78-4a2c-9f68-e78c166b31b6",
                //    "isPremium": true,
                //    "createdAt": 1649164074,
                //    "startedAt": 1649164074,
                //    "expireDate": 1649779099
                //    },
                //    "error": { }
                //}
                return {
                    id: result.data.userId,
                    uuid: result.data.userUuid,
                    isPremium: result.data.isPremium,
                    createdAt: result.data.createdAt,
                    startedAt: result.data.startedAt,
                    expireDate: result.data.expireDate,
                };
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });
    return data;
}

export async function getSubscriptionDetails() {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = await fetch(
        GAIN_API_URL + `/accounts/subscription/details` + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            // 200 değilse
            //{
            //    "data":
            //    {
            //        "isPremium": false,
            //    },
            //    "error": { }
            //}
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            if (result !== null) {
                // annualPrice: undefined
                // currentPlan: "monthly"
                // discountRatio: null
                // hasUpgradableRequest: false
                // isPremium: true
                // lastBillingDate: "2022-03-22 17:12:04"
                // nextBillingDate: "2022-04-22 17:12:02"
                // promotionEndDate: null
                // upgradable: true
                return {
                    isPremium: result.isPremium,
                    lastBillingDate: result.lastBillingDate,
                    nextBillingDate: result.nextBillingDate,
                    currentPlan: result.currentPlan,
                    upgradable: result.upgradable,
                    hasUpgradableRequest: result.hasUpgradableRequest,
                    annualPrice: result.annualPrice,
                    discountRatio: result.discountRatio,
                    promotionEndDate: result.promotionEndDate,
                };
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });
    return data;
}

export async function getUserRate(titlePlaylistID, mediaID) {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = await fetch(
        GAIN_API_URL + `/rates?titleId=` +
        titlePlaylistID +
        "&mediaId=" +
        mediaID + getNoCacheParams(false),
        requestOptions
    )
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            if (result !== null) {
                // 0 dönerse puan vermemiştir, -1 dönerse dislike, 1 dönerse like anlamına geliyor.
                return result["data"]["rate"] !== null ?
                    parseInt(result["data"]["rate"]) :
                    0;
            } else {
                return 0;
            }
        }).catch(error => {
            return 0;
        });
    return data;
}

export async function addRate(titlePlaylistID, mediaID, rate) {
    // rate: 1 ya da -1
    //  //// Daha önce like edip, sonra dislike ettiyse ya da tam tersi olduysa önceki oyu silinir.
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return false;
    }

    var formBody = JSON.stringify({
        // titleId: titlePlaylistID,
        // mediaId: mediaID,
        // ratingValue: rate,
        rate: rate
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
        body: formBody,
    };

    var requestUrl = null;
    // todo : eski versiyonda requestUrl sonudna ' + getNoCacheParams(true)' vardı. 
    if (mediaID !== '') {
        requestUrl = GAIN_API_URL + `/v2/users/rate/media/` + mediaID
    } else {
        requestUrl = GAIN_API_URL + `/v2/users/rate/title/` + titlePlaylistID
    }

    const data = await fetch(
        requestUrl,
        requestOptions
    ).then((res) => {
        return res.status === 200 ? true : false;
    }).catch(error => {
        return false;
    });

    return data;
}

export async function deleteRate(titlePlaylistID, mediaID, rate) {
    // daha önce like vermiş ve like'ı silmek istemiş ise : deleteRate(titlePlaylistID, mediaID, 1);
    // daha önce dislike vermiş ve dislike'ı silmek istemiş ise : deleteRate(titlePlaylistID, mediaID, -1);
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return false;
    }

    var formBody = JSON.stringify({
        // titleId: titlePlaylistID,
        // mediaId: mediaID,
        // ratingValue: rate,
        rate: rate
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
        body: formBody,
    };

    var requestUrl = null;
    // todo : eski versiyonda requestUrl sonudna ' + getNoCacheParams(true)' vardı. 
    if (mediaID !== '') {
        requestUrl = GAIN_API_URL + `/v2/users/rate/media/` + mediaID
    } else {
        requestUrl = GAIN_API_URL + `/v2/users/rate/title/` + titlePlaylistID
    }

    const data = await fetch(
        requestUrl,
        requestOptions
    ).then((res) => {
        return res.status === 200 ? true : false;
    }).catch(error => {
        return false;
    });

    return data;
}

export async function videoTimeTrack(
    titlePlaylistID,
    mediaID,
    lastEngagementTime,
    isMediaWatched
) {
    // videonun tamamı izlenip bittiğinde isMediaWatched: true olarak gönderilir, aksi takdirde isMediaWatched:false yollanır.

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return false;
    }

    // örnek:
    // https://www.gain.tv/v/0UA9eVSM/ayak-isleri-Ozel-bolum-kamera-arkasi-
    // titleId: 99bGb1jA
    // mediaid: 0UA9eVSM
    // lastEngagementTime: 9
    // isMediaWatched: false

    var formBody = JSON.stringify({
        titleId: titlePlaylistID,
        mediaId: mediaID,
        lastEngagementTime: lastEngagementTime,
        isMediaWatched: isMediaWatched,
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken
        },
        body: formBody,
    };

    const data = await fetch(
        GAIN_API_URL + `/users/continue-watch` + getNoCacheParams(true),
        requestOptions
    ).then((res) => {
        return res.status === 200 ? true : false;
    }).catch(error => {
        return false;
    });

    return data;
}

// video sayfasında tetiklenecek, daha önce yarıda kaldıysa kaldığı süre döner, daha önce izlemediyse null döner.
export async function getMediaContinueWatchDetails(mediaID) {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = await fetch(
        GAIN_API_URL + `/users/continue-watch/media/` + mediaID + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            if (result !== null) {
                var data = result["data"];

                var lastEngagementTime = null;
                if (
                    data["currentStream"] !== null &&
                    data["currentStream"]["mediaId"] === mediaID &&
                    data["currentStream"]["lastEngagementTime"] !== null
                ) {
                    lastEngagementTime = parseInt(
                        data["currentStream"]["lastEngagementTime"]
                    );
                }

                return lastEngagementTime;
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });
    return data;
}

export async function getTitleContinueWatchDetails(titlePlaylistID) {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = await fetch(
        GAIN_API_URL + `/users/continue-watch/title/` + titlePlaylistID + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            if (
                result !== null &&
                result["data"]["currentStream"]["mediaId"] !== undefined
            ) {
                // data şu şekilde de dönebiliyor {"data":{"currentStream":{},"season":{}},"error":{}}
                // console.log(result);
                var data = result["data"];

                var activeSeason = 1;
                var lastEngagementTime = null;
                var mediaID = "";

                if (data["currentStream"] !== null) {
                    activeSeason = parseInt(data["currentStream"]["seasonNumber"]); // kişinin son kaldığı sezon
                    mediaID = data["currentStream"]["mediaId"]; // kişinin son kaldığı bölümün mediaID'si
                    lastEngagementTime = parseInt(
                        data["currentStream"]["lastEngagementTime"]
                    );
                }

                return {
                    activeSeason: activeSeason,
                    mediaID: mediaID,
                    lastEngagementTime: lastEngagementTime,
                };
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });
    return data;
}

export async function getSeasonContinueWatchDetails(
    titlePlaylistID,
    seasonNumber
) {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken
        },
    };

    const data = await fetch(
        GAIN_API_URL + `/users/continue-watch/title/` +
        titlePlaylistID +
        "/season/" +
        seasonNumber + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            if (result !== null) {
                // console.log(result);
                return result["data"]["season"]["episodes"];
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });
    return data;
}

// ana ekranda gösterilmek üzere izlemeye devam edebileceği videoların listesi
export async function getContinueWatchPlaylist() {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = await fetch(GAIN_API_URL + `/users/continue-watch` + getNoCacheParams(true),
        requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        }).then((result) => {
            if (result !== null) {
                //console.log(result);
                return result["data"]["list"];
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });

    return data;
}


// TV için planlanmış özel/isimsiz title listesine ekleniyor.
export async function addTitleToPlaylist(titleID) {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken
        }
    };

    const data = await fetch(`${GAIN_API_URL}/v2/accounts/playlist/title?titleId=` + titleID, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : false;
        }).then((result) => {
            if (result !== null) {
                return result.data;
            } else {
                return false;
            }
        }).catch(error => {
            return false;
        });

    return data;
}


// TV için planlanmış özel/isimsiz title listesinde ilgili title yer alıyor mu?
export async function isTitleInPlaylist(titleID) {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken
        }
    };

    const data = await fetch(`${GAIN_API_URL}/v2/accounts/playlist/title?titleId=` + titleID, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : false;
        }).then((result) => {
            // dönen değer check edilecek.
            if (result !== null) {
                return result.data.isTitleInPlaylist;
            } else {
                return false;
            }
        }).catch(error => {
            return false;
        });

    return data;
}

// TV için planlanmış özel/isimsiz title listeden ilgili title'ın silinmesi sağlanır.
export async function deleteTitleFromPlaylist(titleID) {
    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken
        }
    };

    const data = await fetch(`${GAIN_API_URL}/v2/accounts/playlist/title?titleId=` + titleID, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        }).then((result) => {
            if (result !== null) {
                return result.data;
            } else {
                return false;
            }
        }).catch(error => {
            return null;
        });

    return data;
}





// YENİ EKLENENLER:



// ana sayfa hero alanında gözükecek içerik.
export function getRandomVideoFromPlaylist(playlistId) {

    let params = new URLSearchParams({
        playlistId: playlistId,
        videoLimit: 1,
        hero: true,
        shuffle: true,
        rnd: new Date().getTime()
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    // console.log(process.env);
    const data = fetch(`${GAIN_API_URL}/v2/content/playlist?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            var featuredMedia = null;
            if (result.data.list.length > 0) {
                featuredMedia = result.data.list[0];
                featuredMedia.isTitle = featuredMedia.type === 'title' || featuredMedia === 'singleTitle';
            }

            return featuredMedia;
        });
    return data;
}


// title bilgisi
export function getTitle(titleId) {

    let params = new URLSearchParams({
        titleId: titleId,
        rnd: new Date().getTime()
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/content/title?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result;
        });
    return data;
}


// benzer içerikler playlisti
export function getSimilarPlaylist(titleId) {

    let params = new URLSearchParams({
        videoLimit: 10,
        rnd: new Date().getTime()
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/content/title/${titleId}/similar?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result;
        });
    return data;
}


// category playlistleri
export function getCategoryPlaylists(categoryId, param_playlistLimit = 50, param_lastPlaylistIndex = 0) {

    let params = new URLSearchParams({
        categoryId: categoryId,
        playlistLimit: param_playlistLimit,
        videoLimit: 100,
        lastPlaylistIndex: param_lastPlaylistIndex,
        rnd: new Date().getTime()
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/content/playlist/category?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result.data;
        });
    return data;
}


// güncel playlisti -iki- parçalı olarak çekiliyor.
export function getGuncelPlaylists(playlistLimit, lastPlaylistIndex) {

    let params = new URLSearchParams({
        categoryId: "pFlIW25nHIeRkjjIBLLg",
        playlistLimit: playlistLimit,
        videoLimit: 100,
        lastPlaylistIndex: lastPlaylistIndex,
        cw: "true",
        rnd: new Date().getTime()
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    return fetch(`${GAIN_API_URL}/v2/content/playlist/category?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        });
}


// title'ın -kullanıcının izlediğine göre- aktif sezonun bölümleri + tüm sezonların id'leri 
export function getCurrentSeason(titleId) {

    let params = new URLSearchParams({
        titleId: titleId,
        rnd: new Date().getTime()
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/content/season?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result;
        });
    return data;
}


export function getEpisodesBySeasonID(seasonId) {

    let params = new URLSearchParams({
        seasonId: seasonId,
        rnd: new Date().getTime()
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/content/season?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result;
        });
    return data;
}



// En Çok İzlenenler
export function getRecommendedVideos() {

    let params = new URLSearchParams({
        playlistId: 'mostWatched',
        videoLimit: 12
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/content/playlist?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result.data;
        });
    return data;
}




// Arama Sonuçları
export function getSearch(searchTerm) {

    let params = new URLSearchParams({
        keyword: searchTerm
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/search?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result.data;
        });
    return data;
}


// Video Player için gerekli video bilgileri
export function getVideo(mediaId, titleId) {

    let params = new URLSearchParams({
        mediaId: mediaId,
        titleId: titleId
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/content/media?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : {
                status: res.status,
                data: null
            };
        })
        .then((result) => {
            return {
                status: result.status !== undefined ? result.status : 200,
                data: result.data
            };
        });
    return data;
}



// Title İzleme Listesi
export function getWatchlistTitle() {

    let params = new URLSearchParams({
        videoLimit: 100
    });

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/accounts/playlist/title?` + params, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result.data;
        });
    return data;
}

// Media İzleme Listesi
export function getWatchlistMedia() {

    let requestOptions = {
        method: "GET",
        headers: {
            "X-App-Language": "TR"
        }
    };

    const cookies = new Cookies();
    var accessToken = cookies.get("access_token");
    if (accessToken === undefined || accessToken === null) {
        return null;
    }

    if (accessToken !== null) {
        requestOptions.headers.Authorization = "Bearer " + accessToken;
    }

    const data = fetch(`${GAIN_API_URL}/v2/users/playlist`, requestOptions)
        .then((res) => {
            return res.status === 200 ? res.json() : null;
        })
        .then((result) => {
            //console.log(result);
            return result.data;
        });
    return data;
}


// QR Login

// QR oluşturma get isteği
export async function getGenerateCode() {
    const requestOptions = {
        method: "GET"
    };

    const data = await fetch(
        GAIN_API_URL + `/v2/accounts/tv/codes` + getNoCacheParams(true),
        requestOptions
    )
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                return null;
            }
        })
        .then((result) => {
            if (result !== null) {
                return {
                    qrCode: result.data.qrCode,
                    tvCode: result.data.tvCode,
                    cacheDuration: result.data.cacheDuration,
                };
            } else {
                return null;
            }
        }).catch(error => {
            return null;
        });
    return data;
}


// QR check için belli aralıklarla çalışan sorgu.
export async function getQRCheck(tvCode) {
    const requestOptions = {
        method: "GET"
    };

    const data = await fetch(
        GAIN_API_URL + `/v2/accounts/tv/check?tvCode=${tvCode}`,
        requestOptions
    ).then((res) => {
        if (res.status === 200) {
            return res.json();
        } else if (res.status === 403) {
            return 403;
        } else if (res.status === 404) {
            return 404
        }

    }).catch(error => {
        return null;
    });

    return data;
}
