import React, { useState, useContext } from "react";
import SidebarItem from "./sidebarItem";
import { useNavigate } from "react-router-dom";
import withDirectionFocusable from "../components/withDirectionFocusable";
import { MediaContext } from "../provider/generalProvider";

const MenuItemFocusable = withDirectionFocusable(SidebarItem);

// const RETURN_KEY = 8594;
export default function Sidebar(props) {
	const navigate = useNavigate();
    const { ActiveSidebarItem } = useContext(MediaContext);
	const [menu] = useState([
		{
            key: "home",
			link: "/",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#111111" />
					<path
						d="M23 31.0003L35 21.667L47 31.0003V45.667C47 46.3742 46.719 47.0525 46.219 47.5526C45.7189 48.0527 45.0406 48.3337 44.3333 48.3337H25.6667C24.9594 48.3337 24.2811 48.0527 23.781 47.5526C23.281 47.0525 23 46.3742 23 45.667V31.0003Z"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M31 48.3333V35H39V48.3333"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			title: "Keşfet",
		},
		{
            key: "Dizi",
			link: "/category?name=Dizi&id=cD9F0AS65RIGrLoPr3Ea",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#111111" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M39 32.333C39 31.5966 38.403 30.9997 37.6666 30.9997L25.6666 30.9997C24.9302 30.9997 24.3333 31.5966 24.3333 32.333L24.3333 44.333C24.3333 45.0694 24.9302 45.6663 25.6666 45.6663L37.6666 45.6663C38.403 45.6663 39 45.0694 39 44.333L39 32.333ZM37.6666 28.333C39.8758 28.333 41.6666 30.1239 41.6666 32.333L41.6666 44.333C41.6666 46.5421 39.8758 48.333 37.6666 48.333L25.6666 48.333C23.4575 48.333 21.6666 46.5421 21.6666 44.333L21.6666 32.333C21.6666 30.1239 23.4575 28.333 25.6666 28.333L37.6666 28.333Z"
						fill="#AAAAAA"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M30.6667 25.6667C30.6667 25.2246 30.8423 24.8007 31.1548 24.4882C31.4674 24.1756 31.8913 24 32.3333 24L44.3333 24C44.7754 24 45.1993 24.1756 45.5118 24.4882C45.8244 24.8007 46 25.2246 46 25.6667L46 37.6667C46 38.1087 45.8244 38.5326 45.5118 38.8452C45.1993 39.1577 44.7754 39.3333 44.3333 39.3333L44.3333 41.3333C45.3058 41.3333 46.2384 40.947 46.9261 40.2594C47.6137 39.5718 48 38.6391 48 37.6667L48 25.6667C48 24.6942 47.6137 23.7616 46.9261 23.0739C46.2384 22.3863 45.3058 22 44.3333 22L32.3333 22C31.3609 22 30.4282 22.3863 29.7406 23.0739C29.053 23.7616 28.6667 24.6942 28.6667 25.6667L30.6667 25.6667Z"
						fill="#AAAAAA"
					/>
				</svg>
			),
			title: "Dizi",
		},
		{
            key: "Film",
			link: "/category?name=Film&id=mhigtLvBWTvzEwmfrMyz",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#111111" />
					<path
						d="M44.384 23H25.616C24.1712 23 23 24.1712 23 25.616V44.384C23 45.8288 24.1712 47 25.616 47H44.384C45.8288 47 47 45.8288 47 44.384V25.616C47 24.1712 45.8288 23 44.384 23Z"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M29 23V47"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M41 23V47"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M23 35H47"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M23 29H29"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M23 41H29"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M41 41H47"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M41 29H47"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			title: "Film",
		},
		{
            key: "Program",
			link: "/category?name=Program&id=OCkt0rEtp1n5GwZqzSbe",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#111111" />
					<path
						d="M35 48.3337C42.3638 48.3337 48.3333 42.3641 48.3333 35.0003C48.3333 27.6365 42.3638 21.667 35 21.667C27.6362 21.667 21.6666 27.6365 21.6666 35.0003C21.6666 42.3641 27.6362 48.3337 35 48.3337Z"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M32.3334 29.667L40.3334 35.0003L32.3334 40.3337V29.667Z"
						stroke="#AAAAAA"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			title: "Program",
		},
        {
			key: "Discovery+",
			link: "/category?name=Discovery+&id=UjuwqxQJGg7L42AvSCSA",
			icon: (
				<svg width="70" 
                height="71" 
                viewBox="0 0 70 70" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#111111" />
                    <path d="M42 41.5L41.6637 42.4417L41 42.2047V41.5H42ZM42 36.5H41V35.7953L41.6637 35.5583L42 36.5ZM47.6637 34.4773L47.3273 33.5355L47.6637 34.4773ZM42.3363 40.5583L45.8363 41.8083L45.1637 43.6917L41.6637 42.4417L42.3363 40.5583ZM45.8363 41.8083L48 42.581L47.3273 44.4645L45.1637 43.6917L45.8363 41.8083ZM48 42.581V35.419H50V42.581H48ZM48 35.419L42.3363 37.4417L41.6637 35.5583L47.3273 33.5355L48 35.419ZM43 36.5V41.5H41V36.5H43ZM48 35.419L47.3273 33.5355C48.6298 33.0703 50 34.0359 50 35.419H48ZM48 42.581L48 42.581H50C50 43.9641 48.6298 44.9297 47.3273 44.4645L48 42.581Z" fill="#AAAAAA"/>
                    <path d="M40.2 31H25.8C24.8059 31 24 31.8357 24 32.8667V43.1333C24 44.1643 24.8059 45 25.8 45H40.2C41.1941 45 42 44.1643 42 43.1333V32.8667C42 31.8357 41.1941 31 40.2 31Z" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="28" cy="28" r="3" stroke="#AAAAAA" strokeWidth="2"/>
                    <circle cx="35" cy="27" r="4" stroke="#AAAAAA" strokeWidth="2"/>
                </svg>
			),
			title: "Discovery+",
		},
		{
            key: "list",
			link: "/list",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#222222" />
					<path
						d="M35 25.667V44.3337"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M25.6667 35H44.3334"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			title: "Listelerim",
		},
		{
            key: "search",
			link: "/search",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#222222" />
					<path
						d="M33.6667 44.3333C39.5577 44.3333 44.3333 39.5577 44.3333 33.6667C44.3333 27.7756 39.5577 23 33.6667 23C27.7756 23 23 27.7756 23 33.6667C23 39.5577 27.7756 44.3333 33.6667 44.3333Z"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M47 47.0002L41.2 41.2002"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			title: "Ara",
		},
		{
            key: "profile",
			link: "/profile",
			icon: (
				<svg
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
                    <rect className="border-line" x="1.5" y="1" width="68" height="68" rx="34" stroke="#8E8E8E" strokeWidth="2"/>
					<rect width="70" height="70" rx="35" fill="#222222" />
					<path
						d="M45.6666 47V44.3333C45.6666 42.9188 45.1047 41.5623 44.1045 40.5621C43.1043 39.5619 41.7477 39 40.3333 39H29.6666C28.2521 39 26.8955 39.5619 25.8953 40.5621C24.8952 41.5623 24.3333 42.9188 24.3333 44.3333V47"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M35.0001 33.6667C37.9456 33.6667 40.3334 31.2789 40.3334 28.3333C40.3334 25.3878 37.9456 23 35.0001 23C32.0546 23 29.6667 25.3878 29.6667 28.3333C29.6667 31.2789 32.0546 33.6667 35.0001 33.6667Z"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			title: "Profil",
		},
	]);

	const [isFocused, setIsFocused] = useState(false); 
	return (
		<>
			<div
				id="sidebar"
				className={props.hasFocusedChild || isFocused ? "opened" : ""}
			>
				<ul>
					{menu.map((item, key) => {
						return (
							<li key={"sidebar-li" + key}>
								<MenuItemFocusable
									focusKey={"MENU-" + key}
									link={item.link}
									icon={item.icon}
									title={item.title}
                                    up={key === 0 ? "MENU-" + key : null}
                                    down={key + 1 === menu.length ? "MENU-" + key : null}
									right={"Outlet"}
                                    left={"MENU-" + key}
                                    cssClassName={item.key === ActiveSidebarItem ? "active ": ""}
									onEnterPress={() => {
										navigate(item.link);
										props.setFocus("Outlet");
									}}
									onBecameFocused={() => {
										setIsFocused(true);
									}}
									onBecameBlurred={() => {
										setIsFocused(false);
									}}
								/>
							</li>
						);
					})}
				</ul>
			</div>
			<div id="gradient-element"></div>
		</>
	);
}
