import React, { useState, useEffect, useContext } from "react";
import { MediaContext } from "../provider/generalProvider";

export default function InputSearch(props) {

	const { changeSearchInput } = useContext(MediaContext);
    const [searchText, setSearchText] = useState(null);
     
    useEffect(() => {
		// Inputa yazılan içeriği contexte gönderme işlemi
        changeSearchInput(searchText);
	}, [searchText]);

  return (
        <input
                type="text" // type="search"
                className={`textbox ${props.focused ? "selected" : ""}`} 
                id="search"
                name="search"
                placeholder="Anahtar kelime girin..." 
                autoComplete="off" 
                maxLength="100"   
                onChange={(event) => {
                    setSearchText(event.target.value); 
                }} 
            /> 
  );
}