import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import useState from "react-usestateref";
import * as Constants from "../constants/constants";
import imgNoAccess from "../assets/img/bg-no-access.jpg";
import withDirectionFocusable from "../components/withDirectionFocusable";
// import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import { useNavigate, useSearchParams } from "react-router-dom";
//import videojs from "video.js";
import VideoButton from "../components/Video/videoButton";
import VideoBackButton from "../components/Video/videoBackButton";
import Button from "../components/Button/button";
import ActionButton from "../components/Button/actionButton";
import SliderCard from "../components/Slider/sliderCard";
import PremiumFree from "../components/premiumFree";
import LoadingFullscreen from "../components/loadingFullscreen";
import BackButton from "../components/Button/backButton";
import { getScreenSize } from "../helper/platform";
import * as Sentry from "@sentry/react";
// import PlayerPreview from "../components/Video/playerPreview";
import {
    getVideo,
    refreshToken,
    addRate,
    deleteRate,
    videoTimeTrack,
    getSimilarPlaylist
} from "../services/gainAPI";
import {
    pageView,
    videoWatchEvent,
    videoRewind as videoRewindEvent,
    videoSkipIntro as videoSkipIntroEvent,
    videoNextEpisode as videoNextEpisodeEvent,
    rating as ratingEvent,
} from "../services/gtm";
import { getThumbnailImage } from "../helper/imageHelper";
import getRtukCard from "../helper/getRtukCard";
// import { checkOrSetAlreadyCaught } from "@sentry/utils";

//window.videojs = videojs;

const ButtonFocusable = withDirectionFocusable(Button);
const VideoBackButtonFocusable = withDirectionFocusable(VideoBackButton);
const VideoButtonFocusable = withDirectionFocusable(VideoButton);
const SliderCardFocusable = withDirectionFocusable(SliderCard);
const ActionButtonFocusable = withDirectionFocusable(ActionButton);
const PremiumFreeFocusable = withDirectionFocusable(PremiumFree);

const BackButtonFocusable = withDirectionFocusable(BackButton);
// const ButtonTextTrack = withDirectionFocusable(Button);
// const ButtonAudioTrack = withDirectionFocusable(Button);

export default function Video(props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [currentMedia, setCurrentMedia] = useState(null);
    const activeMediaRef = useRef(null); // setTimeout içerisinde currentMedia ilk set edilen değer olarak geldiğinden currentMedia ile senkron olacak şekilde bu ref kullanılıyor.
    const [loading, setLoading] = useState(true);
    const [playing, setPlaying] = useState(true);
    const subtitleToggleRef = useRef(false);
    const [visibleSubtitleButton, setVisibleSubtitleButton] = useState(false);
    const [videoControlToggle, setVideoControlToggle] = useState(true);
    const [skipIntroButtonToggle, setSkipIntroButtonToggle] = useState(false);
    const [nextEpisode, setNextEpisode] = useState(null);
    //const [episodeChanged, setEpisodeChanged] = useState(false);
    const [recommendedVideos, setRecommendedVideos] = useState(null);
    const [rate, setRate] = useState(0);
    const intervalRef = useRef(null); // video overlay show/hide etmek için kullanılıyor.
    const intervalTimeTrackingRef = useRef(null); // belli aralıklarla videonun kaçıncı saniyesinde olduğu bilgisini göndermek için kullanılıyor.

    const loadedmetadataRef = useRef(null); // video eventi için kullanılıyor.
    const playingRef = useRef(null); // video eventi için kullanılıyor.
    const pauseRef = useRef(null); // video eventi için kullanılıyor.
    const waitingRef = useRef(null); // video eventi için kullanılıyor.
    const endedRef = useRef(null); // video eventi için kullanılıyor.
    const timeupdateRef = useRef(null); // video eventi için kullanılıyor.
    const videoErrorRef = useRef(null); // video eventi için kullanılıyor.

    const timeIntervalForLiveStreamRef = useRef(null); // canlı yayının bitip bitmediği kontrolü için kullanılıyor.
    const timeOutForSkipIntroRef = useRef(null); // skip intro butonunun hangi saniyede gösterileceği kontrolü için kullanılıyor.
    const timeOutForSkipIntro2Ref = useRef(null); // skip intro butonunun hangi saniyede gizleneceği kontrolü için kullanılıyor.

    const [premiumCheck, setPremiumCheck] = useState(null); // kişinin premium olması ya da videonun ücretsiz olması duruma göre videoyu izleyebilir/izleyemez

    const playerRef = React.useRef(null); // videojs player
    const [isPlayerReady, setIsPlayerReady] = useState(false); // player yüklendi ve hazır durumdaysa
    const [playingVideoCount, setPlayingVideoCount] = useState(1); // art arda kaçıncı videoyu oynattığı bilgisi, video bittiğinde değişiyor ve sıradaki bölümün set edilmesinde tetikleyici olarak kullanılıyor.
    const [nextEpisodeLayout, setNextEpisodeLayout] = useState(false); // Video sonu "Sonraki Bölüm" butonu için kullanıldı
    const preventMultiTriggerForNextEpisode = useRef(false); // Videonun skipNextEpisode değeri varsa video sonlarında sadece 1 kez videonun bittiği bilgisinin iletilmesi için kullanılıyor.
    //const preventMultiTriggerForNextEpisode2 = useRef(false); // Videonun skipNextEpisode değeri varsa video sonlarında sadece 1 kez videonun bittiği bilgisinin iletilmesi için kullanılıyor.

    const subtitleProblematicDevices = useRef(["MB180", "MB181"]); // Vestel'in bu modellerinde altyazı sorunu vardı, bu modeller eğer cihazın userAgentında geçiyorsa altyazıları farklı bir şekilde gösteriliyor/gizleniyor.
    const [toggleBox, setToggleBox] = useState("");
    const [subTitle, setSubTitle] = useState("off");
    const [audioTrack, setAudioTrack] = useState(0);
    const videoMainWrapper = useRef(null);
    const [audioTracks, setAudioTracks] = useState({});
    const setTimeOutRtukRef = useRef(null); // bir süre sonra Rtük ikonların kapanmasını sağlar
    const similarVideoInterval = useRef(null);
    // sayfadan çıkıldığında dispose işlemleri yapılır.
    useEffect(() => {
        return () => {
            //console.log("dispose");
            playerRef.current !== null && playerRef.current.dispose();
            playerRef.current = null;
            setCurrentMedia(null);
            activeMediaRef.current = null;
            setPlaying(false);
            setRate(0);
            setNextEpisode(null);
            setRecommendedVideos(null);
            setPremiumCheck(null);
            setIsPlayerReady(false);
        };
    }, []);


    //  currentMedia, premium ve rating bilgisi sorgulanır.
    useLayoutEffect(() => {
        var mediaId = searchParams.get("id");
        var titleId = searchParams.get("titleId");

        if (mediaId === null || titleId === null) {
            navigate(-1);
            return;
        }

        window.titlePageURL = "/title?id=" + titleId;

        getVideo(mediaId, titleId).then(function (response) {
            if (response === null) {
                navigate(-1);
                return;
            }
            else if (response.status === 200) {
                // videoyu izleyebilir
                //console.log("currentMedia set ediliyor:", response);

                document.title = response.data.metadata.name;
                pageView();

                var hlsVideos = response.data.mediaSources.filter(function (source) { return source.src.includes('.m3u8'); });
                var hlsVideoURL = hlsVideos != null && hlsVideos.length > 0 ? hlsVideos[0].src.replace("http:", "https:") : null;
                // todo: hls videosu yoksa? mediaSources null gelmişse bla bla önceki sayfaya yönlendir.
                response.data.videoURL = hlsVideoURL;

                setRate(response.data.userRate);
                setCurrentMedia(response.data);
                checkNextEpisode(response.data);
                activeMediaRef.current = response.data;
                setPremiumCheck(true);
            }
            else if (response.status === 401) {
                // token ölmüşse
                refreshToken().then((result) => {
                    const windowHref = window.location.href
                    window.location.href = windowHref;
                });
            }
            else if (response.status === 403) {
                // premium değil 
                setPremiumCheck(false);
                setNextEpisode(null);
            } else if (response.status === 422) {
                // üye girişi yapmamış 
                navigate("/qr-login", { replace: true });
            } else if (response.status === 451) {
                // geo blocking
                // todo: ilgili ekranı göster.
                navigate("/", { replace: true });
            } else if (response.status === 500) {
                navigate(-1);
                return;
            } else {
                console.log("beklenmedik bir durum oluştu!");
                navigate(-1);
                return;
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isSubtitleProblematicDevice = useCallback(() => {
        var isExcluded = false;
        var userAgent = navigator.userAgent;
        for (let index = 0; index < subtitleProblematicDevices.current.length; index++) {
            const ua = subtitleProblematicDevices.current[index];
            if (userAgent.indexOf(ua) > -1) {
                isExcluded = true;
            }
        }
        return isExcluded;
    }, [subtitleProblematicDevices])

    const initVideoJS = useCallback((overrideNative) => {

        playerRef.current !== null && playerRef.current.dispose(); // daha önce oluşturulduysa dispose et.

        var videoElement = document.getElementById("player");
        if (videoElement === null) {
            document.querySelector('.video-player').innerHTML = '<video id="player" className="video-js" crossOrigin="anonymous" autoPlay></video>';
            videoElement = document.getElementById("player");
        }

        if (activeMediaRef.current.metadata.tags !== undefined && activeMediaRef.current.metadata.tags !== null && activeMediaRef.current.metadata.tags.includes('vertical')) {
            // console.log("aa");
            videoElement.style.width = "auto";
            videoElement.style.height = (getScreenSize().height / 10) + "rem";
        }
        else {
            // console.log("bb");
            videoElement.style.width = (getScreenSize().width / 10) + "rem";
            videoElement.style.height = (getScreenSize().height / 10) + "rem";
        }
        // console.log(currentMedia);
        // console.log(videoElement);
        var sourceTag = document.createElement("source");
        sourceTag.type = "application/x-mpegURL"; // "application/vnd.apple.mpegurl" todo: Arçelik canlı yayın için type'ı apple'lı olanı dene!
        sourceTag.src = currentMedia.videoURL;
        videoElement.appendChild(sourceTag);

        // video.js version notu.
        // The github release 7.10.1 will work is because it defaults to use native HLS.
        // 7.10.2 use MediaSource

        playerRef.current = window.videojs(
            "player",
            {
                children: ["mediaLoader"],
                //retryOnError: true,
                //handleManifestRedirects: true, // https://github.com/videojs/http-streaming#handlemanifestredirects
                html5: {
                    // <-- hls yerine vhs yazmak gerekiyor yeni video.js sürümlerinde. 7.8.3 ise hls, 7.18.1 ise vhs.
                    hls: {
                        // https://github.com/videojs/http-streaming#overridenative
                        overrideNative: overrideNative, // Gain ofisteki Grundig'de false olduğunda canlı yayın çalışıyor ama evdeki Arçelik'te true olduğunda çalışıyor. window.platform === "arcelik" 
                    },
                    nativeAudioTracks: false,
                    //nativeVideoTracks: false,
                    nativeVideoTracks: isSubtitleProblematicDevice() === true ? true : false,
                    nativeTextTracks: isSubtitleProblematicDevice() === true ? true : false, //window.platform === "tizen" ? false : true // ref: https://videojs.com/guides/text-tracks/
                }
            },
            function () {
                //console.log("player hazır");
                // handlePlayerReady(playerRef.current);
                setIsPlayerReady(true);
                // videojs.log.history(); // tüm logları array olarak veriyor. https://github.com/videojs/http-streaming#debugging
            }
        );
    }, [currentMedia, isSubtitleProblematicDevice])

      const setSkipIntroButton = useCallback(() => {
        // skip intro butonunun gösterilmesi/gizlenmesiyle ilgili ayarlamalar
        timeIntervalForLiveStreamRef.current && clearInterval(timeIntervalForLiveStreamRef.current);
        timeOutForSkipIntroRef.current && clearTimeout(timeOutForSkipIntroRef.current);
        timeOutForSkipIntro2Ref.current && clearTimeout(timeOutForSkipIntro2Ref.current);
        //console.log("setSkipIntroButton");

        let activeMedia = activeMediaRef.current;
        if (
            activeMedia !== null &&
            activeMedia.playerMediaMetaData !== undefined &&
            activeMedia.playerMediaMetaData.startIntro !== null &&
            activeMedia.playerMediaMetaData.introDuration !== null
            //activeMedia.playerMediaMetaData.introDuration !== "0"
        ) {
            //console.log('intro button ayarlamaları yapılıyor.' + activeMedia.playerMediaMetaData.startIntro + ' ' + activeMedia.playerMediaMetaData.introDuration);
            //console.log(activeMedia.id);
            timeOutForSkipIntroRef.current = setTimeout(function () {
                setSkipIntroButtonToggle(true);
                props.setFocus("SkipIntroButton");

                timeOutForSkipIntro2Ref.current = setTimeout(function () {
                    setSkipIntroButtonToggle(false); // "intoyu atla" butonu 8 saniye sonra tekrar gizlenir
                    props.setFocus("VideoPlayButton");
                }, 10000);
            }, parseInt(activeMedia.playerMediaMetaData.startIntro) * 1000); // startIntro saniyesi geldiğinde "introyu atla" butonu gözükür
        }
        else {
            props.setFocus("VideoPlayButton");
        }
    }, [setSkipIntroButtonToggle, props])


    // currentMedia bilgisi çekildiğinde player hazırlanır ve içine video eklenir. currentMedia değiştiğinde tetiklenir.
    useEffect(() => {
        var videoElement = document.getElementById("player");
        if (currentMedia !== null && videoElement !== null) {
            //playerRef.current && playerRef.current.dispose(); // varsa eski player silinir
            if (playerRef.current === null) {
                initVideoJS(true); // overrideNative: true -> ref: https://github.com/videojs/http-streaming#overridenative
            } else {
                // Remove all source elements
                while (playerRef.current.firstChild) {
                    // todo: şu hatayı verebiliyor tizen'da: NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
                    playerRef.current.removeChild(playerRef.current.firstChild);
                    //console.log("source silindi");
                }

                // playerRef.current.load(); ref: https://webostv.developer.lge.com/common-page/faqs/general-i-removed-source-element-played-video-element-and-added-source-element-new-video-content-video-would-not-play/?

                playerRef.current.src({
                    src: currentMedia.videoURL,
                    type: "application/x-mpegurl",
                });
            }
        }
    }, [premiumCheck, currentMedia, initVideoJS]);


    // video player hazır olduğunda cont. watch bilgisi, altyazı butonu görünürlüğü, sonraki bölüm butonu görürlüğü ve video eventleri ayarlanır.
    useEffect(() => {
        if (isPlayerReady === true) {
            currentMedia.type !== 'live' && getMediaContinueWatchDetail();
            setVisibleSubtitleButton(getCaption(currentMedia).length > 0 ? true : false); // altyazı butonunu göster ya da gizle
            showVideoOverlay();

            // Video progress bar click/seek işlemi
            const playerProgress = document.getElementById("player-progress");
            if (playerProgress) {
                const progressWidth = playerProgress.clientWidth;
                playerProgress.addEventListener("click", function (event) {
                    var x = Math.floor((event.offsetX / progressWidth) * 100);
                    changeProgress(x);
                });
            }

            var nativeVideoElement = document.getElementsByTagName("video");
            var videoElement = nativeVideoElement[0];
            // tüm video eventleri: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/ended_event


            // Player yüklendiğinde altyazıların set edilmesi sağlandı.
            var captions = getCaption(currentMedia);
            if (captions.length > 0 && videoElement) {
                captions.forEach(item => {
                    var track = document.createElement("track");
                    track.kind = "captions";
                    track.label = item.label;
                    track.srclang = item.language.split('-')[0];
                    track.src = item.src;
                    videoElement.appendChild(track);
                })
            }

            if (nativeVideoElement.length > 0) {
                loadedmetadataRef.current = videoElement.addEventListener(
                    "loadedmetadata",
                    (event) => {
                        // console.log('loadedmetadata event');
                        //playerRef.current.currentTime(0); // LG'de 4. saniyeden başlıyordu, önlemek adına.
                        //playerRef.current.play();
                        showTotalTime();
                        if (isSubtitleProblematicDevice() === true) {
                            // her video açıldığında/değiştiğinde altyazı açıksa açık getirilir.
                            subtitleToggleRef.current === false && closeCaptionWithCSS();
                            subtitleToggleRef.current === true && showCaptionWithCSS();
                        }
                        // subtitle'ları bu aşamada eklemek gerekebilir? UYARIYI OKU: https://videojs.com/guides/options/#metadata

                        // Seslendirme 
                        var playerAudioTracks = playerRef.current.audioTracks();
                        const updatedAudioTracks = {
                            ...audioTracks,
                            ...playerAudioTracks
                        };

                        setAudioTracks(updatedAudioTracks)


                        // "Sonraki Bölüm" butonuna tıklandığında seslendirme set edilmişse tekrar set et. 
                        var videoMainWrapper = document.getElementById("video-main-wrapper");
                        var audioLang = videoMainWrapper.dataset.activeAudioIndex;
                        if (audioLang) {
                            var tracks = playerAudioTracks.tracks_;
                            const index = tracks.findIndex(track => track.label === audioLang);

                            if (index !== -1) {
                                changePlayerAudio(index);
                            }
                        }

                    }
                );

                playingRef.current = videoElement.addEventListener(
                    "playing",
                    (event) => {
                        onPlaying();
                    }
                );

                pauseRef.current = videoElement.addEventListener(
                    "pause",
                    (event) => {
                        onPause();
                    }
                );

                waitingRef.current = videoElement.addEventListener(
                    "waiting",
                    (event) => {
                        onWaiting();
                    }
                );

                endedRef.current = videoElement.addEventListener(
                    "ended",
                    (event) => {
                        triggerVideoEnded(true);
                    }
                );

                timeupdateRef.current = videoElement.addEventListener(
                    "timeupdate",
                    (event) => {
                        // video oynatılırken saniyede 1-2 kez tetiklenir.
                        timeUpdate();
                    }
                );

                videoErrorRef.current = videoElement.addEventListener(
                    "error",
                    (event) => {
                        // video'da bir hata oluştuğunda tetiklenir.
                        onError();
                    }
                );

                // playerRef.current.on("waiting", () => {
                //     playerRef.current.log("player is waiting");
                // });

                // playerRef.current.on("dispose", () => {
                //     playerRef.current.log("player will dispose");
                // });
            }

            setLoading(false);
        }

        return () => {
            loadedmetadataRef.current &&
                loadedmetadataRef.current.removeEventListener("loadedmetadata", showTotalTime);
            playingRef.current &&
                playingRef.current.removeEventListener("playing", onPlaying);
            pauseRef.current &&
                pauseRef.current.removeEventListener("pause", onPause);
            waitingRef.current &&
                waitingRef.current.removeEventListener("waiting", onWaiting);
            endedRef.current &&
                endedRef.current.removeEventListener("ended", triggerVideoEnded);
            timeupdateRef.current &&
                timeupdateRef.current.removeEventListener("timeupdate", timeUpdate);
            videoErrorRef.current &&
                videoErrorRef.current.removeEventListener("error", onError);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlayerReady]);


    // skip intro butonunun gösterilmesi/gizlenmesiyle ilgili ayarlamalar ve canlı yayın ise yayının sonlanıp/sonlanmadığı kontrol edilir.
    useLayoutEffect(() => {
        props.setFocus("VideoPlayButton");
        // console.log(currentMedia);
        // console.log("isPlayerReady", isPlayerReady);

        if (isPlayerReady === true) {
            // setSkipIntroButton();

            // canlı yayın ise yayının sonlanıp/sonlanmadığı belli saniye aralıklarıyla kontrol edilir.
            // if(currentMedia.type === 'live' && currentMedia.liveChannelID !== undefined)
            // {
            //     timeIntervalForLiveStreamRef.current = setInterval(function(){
            //         getStatusOfLiveChannel(currentMedia.liveChannelID).then(function(status){
            //             //console.log("live status:", status);
            //             if(status === "idle"){
            //                 navigate("/");
            //             }
            //         });
            //     }, 5000);
            // }
        }

        return () => {
        //    timeIntervalForLiveStreamRef.current && clearInterval(timeIntervalForLiveStreamRef.current);
            timeOutForSkipIntroRef.current && clearTimeout(timeOutForSkipIntroRef.current);
            timeOutForSkipIntro2Ref.current && clearTimeout(timeOutForSkipIntro2Ref.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlayerReady, timeIntervalForLiveStreamRef]);


    // klavye tuşlarına basıldığında ve/veya mouse hareket ettiğinde yapılacak işlemler ayarlanır.
    useLayoutEffect(() => {
        function keyDown(e) {
            showVideoOverlay();

            // Right 39
            // Left 37
            // FF: 417
            // RW: 412
            // ENTER: 13
            // PLAY: 415
            // PAUSE: 19
            // STOP: 413
            // KEY_PLAY_PAUSE: 10252
            // BACK (SAMSUNG) : 10009 

            // ref: https://crossbrowdy.com/_html/_doc/api/CrossBase_input_CB_Keyboard.js.html  (158. satırdan başlıyor)
            // ref: https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html

            //console.log(e.keyCode);

            setSkipIntroButtonToggle(false); // introyu atla butonu gizlenir.

            let activeMedia = activeMediaRef.current;

            //document.getElementsByClassName('btn-video-back')[0].innerHTML = e.keyCode;

            if (e.keyCode === window.VK_FAST_FWD || e.keyCode === 417) {
                // Fast Forward
                setLoading(false);
                let currentTime = Math.floor(playerRef.current.currentTime());
                let duration = Math.floor(playerRef.current.duration());
                if (currentTime + 10 < duration) {
                    playerRef.current.currentTime(currentTime + 10); // saniye cinsinden
                }

                activeMedia !== null &&
                    videoWatchEvent(
                        "seeked",
                        activeMedia.metadata.name,
                        activeMedia.type === 'live',
                        activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                        activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                        activeMedia.id,
                        activeMedia.titleId,
                        activeMedia.metadata.category,
                        currentTime,
                        duration
                    );
            } else if (e.keyCode === window.VK_RIGHT || e.keyCode === 39) {
                // play/pause butonuna focus durumdayken sağ tuşa basıldığında
                if (
                    document.querySelector(".btn-play-pause") !== null && document.querySelector(".btn-play-pause").classList.contains("selected")
                ) {
                    // let nativeVideoElement = document.getElementsByTagName("video")[0];
                    // let currentTime = nativeVideoElement.currentTime;
                    let currentTime = Math.floor(playerRef.current.currentTime());
                    let duration = Math.floor(playerRef.current.duration());
                    if (currentTime + 10 < duration) {
                        playerRef.current.currentTime(currentTime + 10); // saniye cinsinden
                    }
                    activeMedia !== null &&
                        videoWatchEvent(
                            "seeked",
                            activeMedia.metadata.name,
                            activeMedia.type === 'live',
                            activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                            activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                            activeMedia.id,
                            activeMedia.titleId,
                            activeMedia.metadata.category,
                            currentTime,
                            duration
                        );
                }
            } else if (e.keyCode === window.VK_REWIND || e.keyCode === 412) {
                // Rewind
                setLoading(false);
                let currentTime = Math.floor(playerRef.current.currentTime());
                let duration = Math.floor(playerRef.current.duration());
                if (currentTime > 10) {
                    playerRef.current.currentTime(currentTime - 10); // saniye cinsinden
                } else {
                    playerRef.current.currentTime(0);
                }
                activeMedia !== null &&
                    videoWatchEvent(
                        "seeked",
                        activeMedia.metadata.name,
                        activeMedia.type === 'live',
                        activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                        activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                        activeMedia.id,
                        activeMedia.titleId,
                        activeMedia.metadata.category,
                        currentTime,
                        duration
                    );
            } else if (e.keyCode === window.VK_LEFT || e.keyCode === 37) {
                // play/pause butonuna focus durumdayken sol tuşa basıldığında
                if (
                    document.querySelector(".btn-play-pause") !== null && document.querySelector(".btn-play-pause").classList.contains("selected")
                ) {
                    let currentTime = Math.floor(playerRef.current.currentTime());
                    let duration = Math.floor(playerRef.current.duration());
                    if (currentTime > 10) {
                        playerRef.current.currentTime(currentTime - 10); // saniye cinsinden
                    } else {
                        playerRef.current.currentTime(0);
                    }
                    activeMedia !== null &&
                        videoWatchEvent(
                            "seeked",
                            activeMedia.metadata.name,
                            activeMedia.type === 'live',
                            activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                            activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                            activeMedia.id,
                            activeMedia.titleId,
                            activeMedia.metadata.category,
                            currentTime,
                            duration
                        );
                }
            } else if (e.keyCode === window.VK_ENTER || e.keyCode === 13 || e.keyCode === 16777221) {
                // ENTER
                // try{
                //     if(document.activeElement.classList.contains("btn-play-pause")){
                //         onClickPlaying();
                //     }
                // }
                // catch{}
            } else if (e.keyCode === window.VK_PLAY || e.keyCode === 415 || e.keyCode === 71) {
                // Play
                if (playerRef.current && playerRef.current.paused()) {
                    playerRef.current.play();
                }
            } else if (e.keyCode === window.VK_PAUSE || e.keyCode === 19 || e.keyCode === 74) {
                // Pause
                playerRef.current && playerRef.current.pause();
            } else if (e.keyCode === window.VK_STOP || e.keyCode === 413 || e.keyCode === 70) {
                // Stop
                playerRef.current && playerRef.current.currentTime(0);
                (playerRef.current && playerRef.current.paused() === false) && playerRef.current.pause();
                navigate(-1);
            } else if (e.keyCode === window.VK_PLAY_PAUSE || e.keyCode === 10252 || e.keyCode === 503) {
                // KEY_PLAY_PAUSE
                onClickPlaying();
            } else if (document.getElementById("video-main-wrapper") && document.getElementById("video-main-wrapper").classList.contains("video-bottom-toggle-box-active") === false && (e.keyCode === window.VK_BACK || e.keyCode === 10009 || e.keyCode === 461 || e.keyCode === 465)) {
                playerRef.current && playerRef.current.pause();
                //navigate(-1);
            } else if (e.keyCode === window.VK_SUBTITLE || e.keyCode === 65 || e.keyCode === 112 || e.keyCode === 10221 || e.keyCode === 460) { // Kumandada altyazı aç/kapat 
                //console.log('Subtitle Aç'+ subtitleToggle);
                document.querySelector('.btn-subtitle').click(); //onClickSubTitle() çalışmadı.
            } else if (e.keyCode === window.VK_NEXT || e.keyCode === 10233 || e.keyCode === 425) {
                // MediaTrackNext: 10233
                // MediaTrackPrevious: 10232
                document.getElementById("BottomNextEpisodeButton").click();
            }
        }

        function mouseMove() {
            //console.log('Mouse hareket etti');
            showVideoOverlay();
        }

        window.addEventListener("mousemove", mouseMove);
        window.addEventListener("keydown", keyDown);
        // window.addEventListener("mouseout", mouseOut);

        return () => {
            window.removeEventListener("mousemove", mouseMove);
            window.removeEventListener("keydown", keyDown);
            // window.removeEventListener("mouseout", mouseOut);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        //console.log("playingVideoCount:" + playingVideoCount);
        if (playingVideoCount > 1) {
            playerRef.current !== null && playerRef.current.paused() === false && playerRef.current.pause();
            if (nextEpisode !== null) {
                //console.log("currentMedia set edildi: 2", nextEpisode);

                setCurrentMedia(nextEpisode);
                setRate(nextEpisode.userRate);
                activeMediaRef.current = nextEpisode;

                //console.log("oynatılacak yeni bölüm varsa set ediliyor.");
                checkNextEpisode(nextEpisode);

                //checkPremium(nextEpisode);

                // eski altyazı silinip, yeni bölümün altyazısı ekleniyor
                var nativeVideoElement = document.getElementsByTagName("video")[0];
                if (nativeVideoElement !== null) {
                    nativeVideoElement.querySelectorAll("track").forEach(function (track) {
                        track.remove();
                    });
                    // videojs ile altyazı ekleme/silme ihtiyacı olursa: https://videojs.com/guides/text-tracks/
                    // yeni altyazı ekleniyor.
                    var captions = getCaption(nextEpisode);
                    if (captions.length > 0) {
                        captions.forEach(item => {
                            var track = document.createElement("track");
                            track.kind = "captions";
                            track.label = item.label;
                            track.srclang = item.language.split('-')[0];
                            track.src = item.src;
                            nativeVideoElement.appendChild(track);
                        })
                    }
                    setVisibleSubtitleButton(captions.length > 0 ? true : false); // altyazı butonunu göster ya da gizle

                    setSkipIntroButton();
                }
            }
            else {
                setNextEpisode(null);
            }

            // todo: .userRate ile çekilio setRate edilecek.
            // getUserRate(titleId, mediaid).then(function (rate) {
            // 	setRate(rate);
            // });
        }
    }, [playingVideoCount, setSkipIntroButton]);


    // Videonun kaçıncı saniyesini izlediği bilgisi iletiliyor
    useEffect(() => {

        intervalTimeTrackingRef.current = setInterval(() => {
            sendTimeTrackingInfo(); // 10 saniye aralıklarla video zamanını gönderiyoruz
        }, 60000);

        return () => {
            clearInterval(intervalTimeTrackingRef.current);
            intervalTimeTrackingRef.current = null;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlayerReady]);

    useEffect(() => {

        setTimeOutRtukRef.current = setTimeout(() => {
            const target = document.getElementById("player-rtuk-container");
            if (target) {
                target.classList.add("hide");
            }
        }, 5000);

        return () => {
            clearTimeout(setTimeOutRtukRef.current);
            setTimeOutRtukRef.current = null;
        }
    }, [isPlayerReady]);


    useEffect(() => {
        createInterval();

        return () => clearInterval(intervalRef.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const changePlayerAudio = useCallback((index) => {
        var playerAudioTracks = playerRef.current.audioTracks();
        playerAudioTracks.tracks_[index].enabled = true
        setAudioTrack(index);
        var videoMainWrapper = document.getElementById("video-main-wrapper");
        videoMainWrapper.dataset.activeAudioIndex = playerAudioTracks.tracks_[index].label;

    }, [])

    // const getActiveAudio = () => {
    //     const tracks = playerRef.current.audioTracks().tracks_;
    //     const index = tracks.findIndex(track => track.enabled === true);
    //     return index;
    // }

    const sendTimeTrackingInfo = () => {
        //console.log("sendTimeTrackingInfo",activeMedia);
        let activeMedia = activeMediaRef.current;
        if (activeMedia !== null && playerRef.current !== null && playerRef.current.paused() === false) {
            //checkNextEpisode();
            const totalTime = playerRef.current.duration();
            const currentTime = Math.floor(playerRef.current.currentTime());
            //console.log( 'Toplam Süre : ' + totalTime + ' / Anlık : ' + currentTime);

            var skipNextEpisode = (activeMedia.playerMediaMetaData !== undefined && activeMedia.playerMediaMetaData !== null && activeMedia.playerMediaMetaData.skipNextEpisode !== null) ? parseInt(activeMedia.playerMediaMetaData.skipNextEpisode) : 0;

            try {
                if (currentTime !== 0 && playing === true) {
                    videoWatchEvent(
                        "playing",
                        activeMedia.metadata.name,
                        activeMedia.type === 'live',
                        activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                        activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                        activeMedia.id,
                        activeMedia.titleId,
                        activeMedia.metadata.category,
                        playerRef.current !== null ? currentTime : 0,
                        playerRef.current !== null ? playerRef.current.duration() : 0
                    );
                }
            }
            catch (err) { console.log(err); }

            if (activeMedia.type !== 'live') {
                if (currentTime !== 0 && (currentTime <= totalTime - 10 || skipNextEpisode > currentTime)) {
                    if (
                        playing === true &&
                        (
                            (activeMedia.episodeNumber !== null && activeMedia.episodeNumber !== 0) ||
                            activeMedia.episodeNumber === null
                        )
                    ) {
                        // fragman değilse
                        if (preventMultiTriggerForNextEpisode.current === false) { // Video sonundaki "Sonraki Bölüm" butonu henüz gözükmemiş ise
                            videoTimeTrack(
                                activeMedia.titleId,
                                activeMedia.id,
                                currentTime,
                                false
                            ).then(function (response) { });
                        }
                    }
                } else {
                    // Video son 10 saniyesi
                    // console.log('Video Bitmek Üzere');
                    if (
                        playing === true &&
                        ((activeMedia.episodeNumber !== null &&
                            activeMedia.episodeNumber !== 0) ||
                            activeMedia.episodeNumber === null)
                    ) {
                        // fragman değilse
                        // Video Açık
                        videoTimeTrack(
                            activeMedia.titleId,
                            activeMedia.id,
                            currentTime,
                            true
                        ).then(function (response) {
                            // console.log("activeMedia.playerMediaMetaData.skipNextEpisode", activeMedia.playerMediaMetaData.skipNextEpisode);
                            activeMedia.playerMediaMetaData.skipNextEpisode === null && triggerVideoCompletedGTMEvent(); // skipNextEpisode değeri varsa başka bir noktada bu event tetikleniyor.
                        });
                    }
                }
            }
        }
    }

    const checkNextEpisode = (data) => {
        if (data !== null && data.nextEpisodeId !== undefined && data.nextEpisodeId !== null) {
            getVideo(data.nextEpisodeId, data.titleId).then(function (response) {
                if (response === null) {
                    setNextEpisode(null);
                    return;
                }
                else if (response.status === 200 && response.data !== null) {
                    // videoyu izleyebilir
                    //pageView();

                    var hlsVideos = response.data.mediaSources.filter(function (source) { return source.src.includes('.m3u8'); });
                    var hlsVideoURL = hlsVideos != null && hlsVideos.length > 0 ? hlsVideos[0].src.replace("http:", "https:") : null;
                    response.data.videoURL = hlsVideoURL;

                    setNextEpisode(response.data);
                }
                else {
                    setNextEpisode(null);
                }
            });
        }
        else {
            setNextEpisode(null);
        }
    }

    const redirectToVideo = useCallback((id, titleId, type) => {
        if (type === 'title') {
            navigate("/title?id=" + titleId);
        } else {
            navigate("/redirect-to-video?id=" + id + "&titleId=" + titleId);
        }
    }, [navigate])

  
    const createInterval = () => {
        intervalRef.current && clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            hideVideoOverlay();
        }, 4000);
    }

    const rating = (newRate) => {
        var activeMedia = activeMediaRef.current;
        if (activeMedia !== null) {
            var isTitle = activeMedia.type === "title" || activeMedia.type === "singleTitle";
            if (rate === newRate) {
                newRate = 0;
            }
            if (rate === 0) {
                // eğer daha önce like ya da dislike edilmemişse 1 ya da -1 olarak kaydedilir.
                addRate(
                    activeMedia.titleId,
                    isTitle === false ? activeMedia.id : "",
                    newRate
                ).then(function (result) {
                    setRate(newRate);
                });

                ratingEvent(
                    activeMedia.title,
                    isTitle,
                    activeMedia.titleId,
                    activeMedia.id,
                    activeMedia.metadata.category,
                    newRate === 1 ? "like" : "dislike",
                    ""
                );
            } else {
                if (rate !== newRate) {
                    // eski ratingi siliniyor
                    deleteRate(
                        activeMedia.titleId,
                        isTitle === false ? activeMedia.id : "",
                        rate
                    ).then(function (result) {
                        setRate(0);
                        // yeni verdiği rating ekleniyor
                        addRate(
                            activeMedia.titleId,
                            activeMedia.id,
                            newRate
                        ).then(function (result) {
                            setRate(newRate);
                        });
                    });

                    ratingEvent(
                        activeMedia.metadata.name,
                        isTitle,
                        activeMedia.titleId,
                        activeMedia.id,
                        activeMedia.metadata.category,
                        newRate === 1 ? "like" : "dislike",
                        rate === 1 ? "like" : "dislike"
                    );
                } else {
                    // eski ratingi siliniyor
                    deleteRate(
                        activeMedia.titleId,
                        isTitle === false ? activeMedia.id : "",
                        newRate
                    ).then(function (result) {
                        setRate(0);
                    });
                }
            }
        }
    }

    const getMediaContinueWatchDetail = () => {
        // ref: https://webostv.developer.lge.com/develop/developer-guide/resuming-media-quickly-mediaoption/
        // kaldığı yerden devam edebilmesi için loadedmetadata eventi içerisinde sürenin set edilmesi gerekiyor. Yani video datası yüklendikten sonra set edilebilir.
        if (currentMedia.continueWatch !== undefined && currentMedia.continueWatch !== null) {
            let contTime = currentMedia.continueWatch.position;
            //console.log("contTime:" + contTime);

            if (playerRef.current !== null && contTime !== null) {
                //console.log(currentMedia.playerMediaMetaData);
                if (contTime > 30 && contTime + 20 < currentMedia.metadata.duration) {
                    //console.log("contTime", contTime);
                    // console.log("currentMedia.playerMediaMetaData.skipNextEpisode", currentMedia.playerMediaMetaData.skipNextEpisode);

                    // skipNextEpisode değeri var ve bulunduğu saniye skipNextEpisode değerinden küçükse eğer o saniyeye atlatılır.
                    if (
                        (currentMedia.playerMediaMetaData === undefined || currentMedia.playerMediaMetaData.skipNextEpisode === null) ||
                        (currentMedia.playerMediaMetaData.skipNextEpisode !== null && parseInt(currentMedia.playerMediaMetaData.skipNextEpisode) > Math.floor(contTime))) {
                        playerRef.current.currentTime(Math.floor(contTime));
                        videoWatchEvent(
                            "continue",
                            currentMedia.metadata.name,
                            currentMedia.type === 'live',
                            currentMedia.episodeNumber !== null ? currentMedia.episodeNumber : "",
                            currentMedia.seasonNumber !== null ? currentMedia.seasonNumber : "",
                            currentMedia.id,
                            currentMedia.titleId,
                            currentMedia.metadata.category,
                            contTime,
                            playerRef.current !== null ? Math.floor(playerRef.current.duration()) : 0
                        );
                        setLoading(false);

                        setSkipIntroButtonToggle(false); // kaldığı yerden devam ediyorsa introyu atla butonu gösterilmez.
                    }
                }
                else {
                    setSkipIntroButton();
                }
            }
            else {
                setSkipIntroButton();
            }
        }
        // else{
        //     console.log("oo");
        // }
    }

    const onError = () => {
        try {
            var nativeVideoElement = document.getElementsByTagName("video");
            if (nativeVideoElement !== null && nativeVideoElement.length > 0) {
                Sentry.captureMessage(
                    "Video yüklenirken bir hata oluştu. " + currentMedia.metadata.name
                    + "; Error code:" + nativeVideoElement[0].error.code
                    + "; Error message: " + nativeVideoElement[0].error.message
                    + "; user:" + (window.userEmail !== undefined ? window.userEmail : "undefined")
                    + "; platform:" + (window.platform !== undefined ? window.platform : "undefined")
                    + "; brand:" + (window.brand !== undefined ? window.brand : "undefined")
                    + "; modelName:" + (window.modelName !== undefined ? window.modelName : "undefined")
                    + "; version:" + (window.version !== undefined ? window.version : "undefined")
                );
                //console.log("Video yüklenirken bir hata oluştu." + " Error code:" + nativeVideoElement[0].error.code + "; details: " + nativeVideoElement[0].error.message);
            }
        }
        catch (err) {
            Sentry.captureMessage("Video yüklenirken bir hata oluştu. Error code: " + nativeVideoElement[0].error.code + "; details: " + nativeVideoElement[0].error.message);
        }

        initVideoJS(false); // ilk olarak true olarak deneniyor, hata oluştuğunda bir de false olarak tekrar deneniyor.
    }

    const onPlaying = () => {
        setPlaying(true);
        createInterval();
        setLoading(false);

        //console.log("onPlaying");

        let activeMedia = activeMediaRef.current;
        if (playerRef.current !== null && activeMedia !== null) {
            videoWatchEvent(
                "play",
                activeMedia.metadata.name,
                activeMedia.type === 'live',
                activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                activeMedia.id,
                activeMedia.titleId,
                activeMedia.metadata.category,
                playerRef.current !== null ? Math.floor(playerRef.current.currentTime()) : 0,
                playerRef.current !== null ? playerRef.current.duration() : 0
            );
        }

        // Sentry.captureMessage(
        //     "Video oynatıldı." 
        //     + " video name:" + activeMedia.title
        //     + "; user:" + (window.userEmail !== undefined ? window.userEmail : "undefined")
        //     + "; platform:" + (window.platform !== undefined ? window.platform : "undefined")
        //     + "; brand:" + (window.brand !== undefined ? window.brand : "undefined")
        //     + "; modelName:" + (window.modelName !== undefined ? window.modelName : "undefined")
        //     + "; version:" + (window.version !== undefined ? window.version : "undefined")
        // );
    }

    const onPause = () => {
        setPlaying(false);
        showVideoOverlay();
        setLoading(false);

        let activeMedia = activeMediaRef.current;
        if (playerRef.current !== null && activeMedia !== null && preventMultiTriggerForNextEpisode.current === false) {
            videoWatchEvent(
                "pause",
                activeMedia.metadata.name,
                activeMedia.type === 'live',
                activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                activeMedia.id,
                activeMedia.titleId,
                activeMedia.metadata.category,
                playerRef.current !== null ? Math.floor(playerRef.current.currentTime()) : 0,
                playerRef.current !== null ? playerRef.current.duration() : 0
            );
        }
    }

    const onWaiting = () => {
        window.platform !== "tizen" && setLoading(true);
        showVideoOverlay();
        setPlaying(false);
        //console.log("waiting");
    }

    const timeUpdate = () => {
        playerRef.current && showCurrentRatio();
        playerRef.current && showCurrentTime();
        // console.log('currentTime : '+ playerRef.current.currentTime());
        // console.log('duration : '+ playerRef.current.duration()); 

        // var loadingElement = document.querySelector('.loading-fullscreen');
        // if(loadingElement !== null && loading.style.display !== 'none'){
        //     document.querySelector('.loading-fullscreen').style.display = 'none'; // video oynuyorsa loading gösterilmez.
        // }

        let activeMedia = activeMediaRef.current;

        if (playerRef.current !== null && activeMedia.playerMediaMetaData.skipNextEpisode !== null) { // videonun skipNextEpisode değeri check edilip duruma göre video sonunda sonraki bölüme geç butonu açılır 
            // console.log('activeMedia.playerMediaMetaData.startIntro : '+ activeMedia.playerMediaMetaData.startIntro);
            // console.log('currentTime : '+playerRef.current.currentTime()); 

            var btnNextEpisode = document.getElementById("BottomNextEpisodeButton"); // sonraki bölüm varsa
            if (btnNextEpisode !== null && playerRef.current.currentTime() >= parseInt(activeMedia.playerMediaMetaData.skipNextEpisode)) {
                //console.log('VİDEO BİTİYOR', preventMultiTriggerForNextEpisode.current);

                if (preventMultiTriggerForNextEpisode.current === false) {
                    //console.log("test", nextEpisodeLayout);
                    triggerVideoCompletedGTMEvent();
                    preventMultiTriggerForNextEpisode.current = true;
                    setNextEpisodeLayout(true);
                    setVideoControlToggle(true);
                    videoEndRemoveClass();

                    setTimeout(function () {
                        triggerVideoEnded(false);
                    }, 4000);
                }
            }
            else {
                //console.log('DİZİ DEVAM EDİYOR'); 
                setVideoControlToggle(true);
                setNextEpisodeLayout(false);
                preventMultiTriggerForNextEpisode.current = false;
            }
        }
        else {
            setVideoControlToggle(true);
            setNextEpisodeLayout(false);
            preventMultiTriggerForNextEpisode.current = false;
        }
    }

    const triggerVideoEnded = (triggerEvent) => {
        let activeMedia = activeMediaRef.current;
        if (activeMedia !== null && activeMedia.type !== 'live') {
            var btnNextEpisode = document.getElementById("BottomNextEpisodeButton"); // sonraki bölüm varsa
            if (btnNextEpisode === null) {
                //console.log("recommended videos çekilir");
                getRecommendedVideos(activeMedia.titleId);
                videoEndAddClass();
            } else {
                if (triggerEvent === false) {
                    // setTimeout içerisinden tetiklendiğinde ilgili useEffect tetiklenmiyordu, sağ altta yer alan "Sonraki Bölüm" butonuna tıklatılarak ile çözüm üretildi.
                    btnNextEpisode.click();
                }
                else {
                    triggerVideoCompletedGTMEvent();
                    setPlayingVideoCount(playingVideoCount + 1);
                }
            }
        }
    }

    const triggerVideoCompletedGTMEvent = () => {
        let activeMedia = activeMediaRef.current;
        //console.log("activeMedia.episodeNumber", activeMedia.episodeNumber);

        // Canlı yayın değilse, episodik bir içerikse ve fragman değilse ya da episodik içerik değilse durumunda eventler tetikleniyor.
        if (activeMedia !== null && activeMedia.type !== 'live' && ((activeMedia.episodeNumber !== null && activeMedia.episodeNumber !== 0) || (activeMedia.episodeNumber === undefined || activeMedia.episodeNumber === null))) {

            videoWatchEvent(
                "completed",
                activeMedia.metadata.name,
                activeMedia.type === 'live',
                activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                activeMedia.id,
                activeMedia.titleId,
                activeMedia.metadata.category,
                Math.floor(playerRef.current.currentTime()),
                Math.floor(playerRef.current.duration())
            );

            videoTimeTrack(
                activeMedia.titleId,
                activeMedia.id,
                Math.floor(playerRef.current.currentTime()),
                true
            ).then(function (response) {
                // setTimeout(function(){
                //     preventMultiTriggerForNextEpisode.current = false;
                // }, 3000);
            }).catch(error => {
                // setTimeout(function(){
                //     preventMultiTriggerForNextEpisode.current = false;
                // }, 3000);
            });
        }
    }

    const onClickPlaying = () => {
        if (playerRef.current !== null) {
            if (playerRef.current.paused()) {
                // console.log("play");
                playerRef.current.play();
                showTotalTime();
            } else {
                // console.log("pause");
                playerRef.current.pause();
                showVideoOverlay();
            }
        }
    };

    const hideVideoOverlay = () => {
        if (playerRef.current !== null && playerRef.current.paused() === false) {
            var videoMainWrapper = document.getElementById("video-main-wrapper");
            if (videoMainWrapper !== null) {
                videoMainWrapper = videoMainWrapper.classList.add(
                    "video-overlay-hidden"
                );
            }
        }
    }

    const showVideoOverlay = () => {
        createInterval();

        var videoMainWrapper = document.getElementById("video-main-wrapper");
        if (videoMainWrapper !== null) {
            videoMainWrapper.classList.remove("video-overlay-hidden");
        }
    }

    const videoEndAddClass = () => {
        var videoMainWrapper = document.getElementById("video-main-wrapper");
        if (videoMainWrapper !== null) {
            videoMainWrapper.classList.add("video-ended");
        }
    }

    const videoEndRemoveClass = () => {
        var videoMainWrapper = document.getElementById("video-main-wrapper");
        if (videoMainWrapper !== null) {
            videoMainWrapper.classList.remove("video-ended");
        }
    }

    const onClickRepeat = () => {
        playerRef.current.currentTime(0);
        document.getElementById("ratio-info").style.width = "0";

        videoRewindEvent(
            currentMedia.metadata.name,
            currentMedia.episodeNumber !== null ? currentMedia.episodeNumber : "",
            currentMedia.seasonNumber !== null ? currentMedia.seasonNumber : "",
            currentMedia.id,
            Math.floor(playerRef.current.currentTime())
        );
    }

    // sonraki bölüme geçildiğinde introsu varsa otomatik olarak atlanır.
    // const seekToIntroEndTime = (media) => {
    //     if (
    //         media !== null &&
    //         media.playerMediaMetaData.startIntro !== undefined &&
    //         (media.introDuration !== undefined && media.introDuration !== 0)
    //     ) {
    //         //console.log(Math.floor(media.playerMediaMetaData.startIntro) + Math.floor(media.introDuration));
    //         playerRef.current.currentTime(Math.floor(media.playerMediaMetaData.startIntro) + Math.floor(media.playerMediaMetaData.introDuration));
    //         props.setFocus("VideoPlayButton");
    //     }

    //     // NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
    //     //playerRef.current.paused() && playerRef.current.play(); 
    // }


    

    const skipIntroClick = useCallback((startIntro, introDuration) => {
        timeIntervalForLiveStreamRef.current && clearInterval(timeIntervalForLiveStreamRef.current);
        timeOutForSkipIntroRef.current && clearTimeout(timeOutForSkipIntroRef.current);
        timeOutForSkipIntro2Ref.current && clearTimeout(timeOutForSkipIntro2Ref.current);
        setSkipIntroButtonToggle(false);
        setVideoControlToggle(true);
        //console.log("startIntro", startIntro);
        //console.log("introDuration", introDuration);
        playerRef.current.currentTime(
            Math.floor(startIntro) +
            Math.floor(introDuration)
        );
        props.setFocus("VideoPlayButton");

        videoSkipIntroEvent(
            currentMedia.metadata.name,
            currentMedia.episodeNumber !== null ? currentMedia.episodeNumber : "",
            currentMedia.seasonNumber !== null ? currentMedia.seasonNumber : "",
            currentMedia.id,
            currentMedia.metadata.category
        );
    }, [currentMedia, props, timeIntervalForLiveStreamRef])

    const showCurrentRatio = () => {
        if (playerRef.current !== null) {
            //  && playerRef.current.paused() === false
            var ratioInfoElement = document.getElementById("ratio-info");
            if (ratioInfoElement !== null) {
                var ratio =
                    playerRef.current.currentTime() /
                    playerRef.current.duration() *
                    100;
                ratioInfoElement.style.width = ratio + "%";
                // console.log("ratio", ratio);
            }
        }
    }

    const showCurrentTime = () => {
        //console.log("currentTime", playerRef.current.currentTime());
        if (playerRef.current !== null) {
            var currentTimeElement = document.getElementById("current-time");
            if (currentTimeElement !== null) {
                currentTimeElement.innerHTML = formatTime(
                    Math.floor(playerRef.current.currentTime())
                );
            }
        }
    }

    const showTotalTime = () => {
        //console.log(playerRef.current);
        if (playerRef.current !== null) {
            var totalTimeElement = document.getElementById("total-time");
            if (totalTimeElement !== null) {
                totalTimeElement.innerHTML = formatTime(
                    playerRef.current.duration()
                );
            }
        }
    }

    const formatTime = (time) => {
        var sec_num = parseInt(time, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = sec_num - hours * 3600 - minutes * 60;

        if (hours === 0) {
            hours = "";
        }

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        if (hours === "") {
            return minutes + ":" + seconds;
        } else {
            return hours + ":" + minutes + ":" + seconds;
        }
    }

    const getRecommendedVideos = (titleId) => {
        getSimilarPlaylist(titleId).then(function (response) {
            //console.log(response);
            setRecommendedVideos(response.data.slice(0, 5));
            const x = setTimeout(function () {
                props.setFocus("recommended-video-0");
                clearTimeout(x);
            }, 1500);

            similarVideoCount();

        });
    }


    const similarVideoCount = () => {
        let time = 10;
        clearInterval(similarVideoInterval.current);
        const count = document.querySelector("#video-main-wrapper .recommended-videos-container .count span");
        similarVideoInterval.current = setInterval(() => {
            if (time === 0) {
                setInterval(similarVideoInterval.current);
                const elem = document.querySelector("#video-main-wrapper .recommended-videos-container .item.selected");
                if (elem) {
                    clearInterval(similarVideoInterval.current);
                    elem.click();
                } else {
                    clearInterval(similarVideoInterval.current);
                }
            } else {
                if(count)
                {
                    count.innerHTML = time;
                }
            }
            time -= 1;
        }, 1000);
    }

    const changeProgress = (clickTime) => {
        // console.log('clickTime : ',clickTime);
        let total = playerRef.current.duration();
        playerRef.current.currentTime(Math.floor(total * clickTime / 100));
        setSkipIntroButtonToggle(false);

        let activeMedia = activeMediaRef.current;

        activeMedia !== null &&
            videoWatchEvent(
                "seeked",
                activeMedia.metadata.name,
                activeMedia.type === 'live',
                activeMedia.episodeNumber !== null ? activeMedia.episodeNumber : "",
                activeMedia.seasonNumber !== null ? activeMedia.seasonNumber : "",
                activeMedia.id,
                activeMedia.titleId,
                activeMedia.metadata.category,
                Math.floor(playerRef.current.currentTime()),
                Math.floor(total)
            );
    }


    const getCaption = (media) => {
        var captions = [];

        if (media.subtitles !== undefined && media.subtitles !== null && media.subtitles.length > 0) {
            captions = media.subtitles
        }

        return captions;
    }

    const onClickSubTitle = (lang = "tr") => {
        var nativeVideoElement = document.getElementsByTagName("video")[0];
        // REF: https://iandevlin.com/blog/2015/02/javascript/dynamically-adding-text-tracks-to-html5-video/
        //playerRef.current && console.log("playerRef.current.textTracks()", playerRef.current.textTracks());
        //props.setFocus("defaultTextTrack")
        // if(navigator.userAgent.indexOf('MB180') > -1) 
        var tracks = nativeVideoElement.textTracks;
        if (lang === "off") { // Altyazı kapalı
            setSubTitle("off");
            if (isSubtitleProblematicDevice() === true) {
                closeCaptionWithCSS();
            }
            Array.from(tracks).forEach(item => {
                item.mode = 'hidden';
            })
            subtitleToggleRef.current = false;
            return;
        }
        if (isSubtitleProblematicDevice() === true) {
            showCaptionWithCSS();
        }
        if (tracks.length > 0) {
            // console.log("1");
            // video'ya altyazı eklenmişse, göster
            //nativeVideoElement.textTracks[0].mode = "showing";
            var targetLang = lang;
            if (lang.includes('-')) {
                targetLang = lang.split('-')[0];
            }

            Array.from(tracks).forEach(item => {
                if (item.language === targetLang) {
                    item.mode = 'showing';
                    setSubTitle(targetLang);
                    subtitleToggleRef.current = true;
                } else {
                    item.mode = 'hidden';
                }
            })
        }
    };

   

    const closeCaptionWithCSS = () => {
        // Vestel MB180/MB181 gibi cihazlarda altyazı açma/kapama işlemi track.mode = "showing" ile yapılamıyordu, bu yüzden CSS kullanıldı.
        var s = document.createElement("style");
        s.type = "text/css";
        s.innerHTML = "::cue {opacity:0;}";
        document.head.appendChild(s);
    }

    const showCaptionWithCSS = () => {
        // Vestel MB180/MB181 gibi cihazlarda altyazı açma/kapama işlemi track.mode = "showing" ile yapılamıyordu, bu yüzden CSS kullanıldı.
        var s = document.createElement("style");
        s.type = "text/css";
        s.innerHTML = "::cue {opacity:1;}";
        document.head.appendChild(s);
    }

    // todo:subTitle
    const onClickSubtitleToggle = () => {
        setToggleBox("subtitle");
        props.setFocus("SubtitleStatusButton");
        videoMainWrapper.current.classList.add("disable-controls");
        videoMainWrapper.current.classList.add("video-bottom-toggle-box-active");
        videoMainWrapper.current.classList.add("subtitle");
        videoMainWrapper.current.classList.remove("track");
    }

    const onClickTrackToggle = () => {
        setToggleBox("track");
        props.setFocus("audio-track-0");
        videoMainWrapper.current.classList.add("disable-controls");
        videoMainWrapper.current.classList.add("video-bottom-toggle-box-active");
        videoMainWrapper.current.classList.add("track");
        videoMainWrapper.current.classList.remove("subtitle");
    }

    const disableToggleBox = (key) => {
        if (toggleBox !== "") {
            if (key) {
                props.setFocus(key);
            }
            setToggleBox("");
            videoMainWrapper.current.classList.remove("disable-controls");
            const x = setTimeout(() => {
                videoMainWrapper.current.classList.remove("video-bottom-toggle-box-active");
                clearTimeout(x);
            }, 200)
        }
    }


    if (currentMedia && premiumCheck === true) {
        return (
            // console.log("video page render"),
            <div id="video-main-wrapper" ref={videoMainWrapper} className="video-overlay-hidden">
                <LoadingFullscreen show={loading} />

                <VideoBackButtonFocusable
                    focusKey={"VideoTitleButton"}
                    className={Constants.BUTTON_VIDEO_BACK + "hide-element top"}
                    up={"VideoTitleButton"}
                    down={recommendedVideos !== null ? "recommended-video-0" : "VideoPlayButton"}
                    left={"VideoTitleButton"}
                    right={"VideoTitleButton"}
                    onEnterPress={() => {
                        //console.log(currentMedia);
                        //if(currentMedia.id === currentMedia.titleId) { // tekil içerikse içeriğin title sayfasına geri yönlendirilir.
                        try {
                            delete window.titlePageURL;
                        }
                        catch {
                            window.titlePageURL = undefined;
                        }
                        navigate("/title?id=" + currentMedia.titleId);
                        //}
                        //else {
                        //    navigate(-1);
                        //}
                    }}
                    // onEnterPress={() => {
                    // 	navigate(-1);
                    // }}
                    onClick={() => {
                        //console.log(currentMedia);
                        //if(currentMedia.id === currentMedia.titleId) { // tekil içerikse içeriğin title sayfasına geri yönlendirilir.
                        try {
                            delete window.titlePageURL;
                        }
                        catch {
                            window.titlePageURL = undefined;
                        }
                        navigate("/title?id=" + currentMedia.titleId);
                        //}
                        // else {
                        //     navigate(-1);
                        // }
                    }}
                    // onClick={() => {
                    // 	navigate(-1);
                    // }}
                    title={currentMedia.metadata.name}
                    subTitle=""
                // subTitle={currentMedia.seasonNumber + "S " + currentMedia.episodeNumber + "B"}
                />

                {currentMedia && currentMedia.type === 'live' && (
                    <span className="live-badge hide-element top">
                        <i></i>CANLI
                    </span>
                )}

                <div className="gradient-layout"></div>
                <div className="shadow-layout"></div>

                {currentMedia && currentMedia.episodeNumber !== 0 && currentMedia.metadata.contentRatings.length > 0 && (
                    <div id="player-rtuk-container">
                        {
                            currentMedia.metadata.contentRatings.map((item, index) => (
                                <div key={index}>
                                    {getRtukCard(item)}
                                </div>
                            ))
                        }
                    </div>
                )}

                <div className="video-player">
                    <>
                        {/* <VideoJS videoURL={currentMedia.videoURL} options={getVideoPlayerOptions()} onReady={handlePlayerReady} /> */}
                        <video
                            id="player"
                            className="video-js"
                            crossOrigin="anonymous"
                            autoPlay
                        ></video>
                    </>
                </div>

                <div
                    className={`next-episode-layout ${nextEpisodeLayout === true ? 'active' : ''} `}
                    style={{
                        visibility:
                            nextEpisodeLayout === true ? "visible" : "hidden",
                    }}
                >

                    {/* Next Section Button */}
                    <ButtonFocusable
                        focusKey={"NextEpisodeLayoutButton"}
                        up={"VideoPlayButton"}
                        animationTime={4} // kaç saniye boyunca animasyon olacağı
                        className={
                            Constants.BUTTON_PLAYER +
                            Constants.BUTTON_NEXT_EPISODE +
                            Constants.BUTTON_INTRO
                        }
                        onEnterPress={() => {
                        }}
                        onClick={() => {
                        }}
                        icon={
                            <i>
                                <svg
                                    width="24"
                                    height="27"
                                    viewBox="0 0 24 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21.832 3.41699V23.2503"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2 2L16.1667 13.3333L2 24.6667V2Z"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </i>
                        }
                        rightText={<span>{"Sonraki Bölüm"}</span>}
                    />
                </div>



                <div
                    className="player-control hide-element bottom"
                    style={{
                        visibility:
                            videoControlToggle === true ? "visible" : "hidden",
                    }}
                >
                    {playing === false ? (
                        <>
                            {/* Play Button */}
                            <VideoButtonFocusable
                                focusKey={"VideoPlayButton"}
                                left={"VideoPlayButton"}
                                right={"VideoPlayButton"}
                                down={
                                    visibleSubtitleButton === true
                                        ? "SubtitleButton"
                                        : "TrackButton"
                                }
                                className={Constants.BUTTON_PLAY_PAUSE}
                                onEnterPress={() => {
                                    onClickPlaying();
                                }}
                                onClick={() => {
                                    onClickPlaying();
                                }}
                                icon={
                                    <svg
                                        width={40}
                                        height={40}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="m10 5 23.333 15L10 35V5Z"
                                            fill="#fff"
                                            stroke="#fff"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                }
                            />
                        </>
                    ) : (
                        <>
                            {/* Pause Button */}
                            <VideoButtonFocusable
                                focusKey={"VideoPlayButton"}
                                left={"VideoPlayButton"}
                                right={"VideoPlayButton"}
                                down={
                                    visibleSubtitleButton === true
                                        ? "SubtitleButton"
                                        : "TrackButton"
                                }
                                className={Constants.BUTTON_PLAY_PAUSE}
                                onEnterPress={() => {
                                    onClickPlaying();
                                }}
                                onClick={() => {
                                    onClickPlaying();
                                }}
                                icon={
                                    <svg
                                        width={40}
                                        height={40}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    // {...props}
                                    >
                                        <path
                                            d="M7 35h8.571V5H7v30ZM24.143 5v30h8.571V5h-8.571Z"
                                            fill="#fff"
                                        />
                                    </svg>
                                }
                            />
                        </>
                    )}

                    {/* Player Range */}
                    <div className="player-range-container">
                        <span id="current-time">00:00</span>
                        <div className="timeline-range">
                            {/* <div
                                    className="vertical-line"
                                    style={{
                                        left: "20%",
                                    }}
                                >
                                    <PlayerPreview
                                            img={"https://cdn.jwplayer.com/thumbs/Of5mJAwI-320.jpg"}
                                            time={"02:15"}
                                        /> 
                                </div> */}
                            <div id="player-progress" className="line">
                                <i id="ratio-info">&nbsp;</i>
                                <em></em>
                            </div>
                        </div>
                        <span
                            id="total-time"
                            style={{
                                display:
                                    currentMedia && currentMedia.type === 'live'
                                        ? "none"
                                        : "block",
                            }}
                        >
                            00:00
                        </span>
                    </div>
                </div>

                <div className="player-buttons hide-element bottom"
                    style={{
                        visibility: nextEpisodeLayout === false ? "visible" : "hidden"
                    }}>
                    <div className="left-button">
                        {visibleSubtitleButton === true && (
                            <>
                                {/* Altyazı Button */}
                                <ButtonFocusable
                                    focusKey={"SubtitleButton"}
                                    up={"VideoPlayButton"}
                                    left={"SubtitleButton"}
                                    onEnterPress={() => {
                                        onClickSubtitleToggle();
                                    }}
                                    onClick={() => {
                                        onClickSubtitleToggle();
                                    }}
                                    onBecameFocused={() => {
                                        disableToggleBox()
                                    }}
                                    className={Constants.BUTTON_CIRCLE}
                                    icon={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={25}
                                            height={24}
                                            fill="none"
                                        >
                                            <g
                                                stroke="#fff"
                                                strokeLinecap="round"
                                                strokeWidth={2}
                                                clipPath="url(#a)"
                                            >
                                                <path
                                                    strokeLinejoin="round"
                                                    d="M21.283 7.109a3.32 3.32 0 0 0-.585-1.36 2.292 2.292 0 0 0-1.032-.796C18.233 4.5 12.5 4.5 12.5 4.5s-5.733 0-7.167.496a2.292 2.292 0 0 0-1.032.797 3.32 3.32 0 0 0-.584 1.359 40.23 40.23 0 0 0-.384 5.703c-.009 1.926.12 3.85.384 5.746.109.496.315.947.598 1.31.283.362.634.624 1.018.76 1.434.496 7.167.496 7.167.496s5.733 0 7.166-.496a2.292 2.292 0 0 0 1.032-.797c.284-.379.486-.848.585-1.36.26-1.867.388-3.761.383-5.66.01-1.926-.12-3.85-.383-5.745Z"
                                                />
                                                <path d="M7.5 13h1.667M15.833 16.166H17.5M12.5 13h5M7.5 16.166h5" />
                                            </g>
                                            <defs>
                                                <clipPath id="a">
                                                    <path fill="#fff" d="M2.5 2h20v20h-20z" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    }
                                />
                            </>
                        )}

                        {/* Seslendirme Button */}
                        <ButtonFocusable
                            focusKey={"TrackButton"}
                            left={
                                visibleSubtitleButton === true && "SubtitleButton"
                            }
                            up={"VideoPlayButton"}
                            onEnterPress={() => {
                                onClickTrackToggle();
                            }}
                            onClick={() => {
                                onClickTrackToggle();
                            }}
                            onBecameFocused={() => {
                                disableToggleBox()
                            }}
                            className={Constants.BUTTON_CIRCLE}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={25}
                                    height={24}
                                    fill="none"
                                >
                                    <path
                                        fill="#fff"
                                        d="M22.046 15.617c.559 0 1.006-.409 1.006-.908V9.29c0-.5-.447-.92-1.006-.92-.572 0-.994.42-.994.92v5.418c0 .5.422.908.994.908ZM18.23 21.342c.559 0 .994-.41.994-.909V3.567c0-.5-.435-.92-.994-.92-.584 0-1.006.42-1.006.92v16.866c0 .5.422.909 1.006.909ZM14.401 17.9c.572 0 1.006-.398 1.006-.909V7.008c0-.511-.434-.92-1.006-.92-.571 0-.994.409-.994.92v9.983c0 .511.423.909.994.909ZM10.574 23.863c.572 0 1.019-.41 1.019-.92V1.057c0-.511-.447-.92-1.019-.92-.559 0-.981.409-.981.92v21.886c0 .51.422.92.981.92ZM6.758 19.286c.572 0 1.007-.41 1.007-.92V5.634c0-.511-.435-.931-1.007-.931-.559 0-.993.42-.993.931v12.732c0 .51.434.92.993.92ZM2.93 14.64c.583 0 1.018-.409 1.018-.92V10.28c0-.511-.435-.931-1.018-.931-.56 0-.982.42-.982.93v3.442c0 .511.423.92.982.92Z"
                                    />
                                </svg>
                            }
                        />

                        {/* Repeat Button */}
                        <ButtonFocusable
                            focusKey={"RepeatButton"}
                            left={"TrackButton"}
                            up={"VideoPlayButton"}
                            onEnterPress={() => {
                                onClickRepeat();
                            }}
                            onClick={() => {
                                onClickRepeat();
                            }}
                            className={Constants.BUTTON_CIRCLE}
                            icon={
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M23 20V14H17"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M1 4V10H7"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M20.49 9.00008C19.9828 7.56686 19.1209 6.28548 17.9845 5.27549C16.8482 4.26551 15.4745 3.55984 13.9917 3.22433C12.5089 2.88883 10.9652 2.93442 9.50481 3.35685C8.04437 3.77928 6.71475 4.56479 5.64 5.64008L1 10.0001M23 14.0001L18.36 18.3601C17.2853 19.4354 15.9556 20.2209 14.4952 20.6433C13.0348 21.0657 11.4911 21.1113 10.0083 20.7758C8.52547 20.4403 7.1518 19.7346 6.01547 18.7247C4.87913 17.7147 4.01717 16.4333 3.51 15.0001"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            }
                        />

                        {/* Like Button */}
                        <ActionButtonFocusable
                            focusKey={"LikeButton"}
                            up={"VideoPlayButton"}
                            left={"RepeatButton"}
                            right={"DislikeButton"}
                            className={Constants.BUTTON_CIRCLE}
                            likeBtn={true}
                            rateStatus={rate}
                            onClick={(e) => rating(1)}
                            onEnterPress={(e) => rating(1)}
                            icon={
                                <>
                                    <svg className="border-line" width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.5" y="1" width="68" height="68" rx="34" stroke="#fff" strokeWidth="2" />
                                    </svg>
                                    <svg
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V12C1 11.4696 1.21071 10.9609 1.58579 10.5858C1.96086 10.2107 2.46957 10 3 10H6M13 8V4C13 3.20435 12.6839 2.44129 12.1213 1.87868C11.5587 1.31607 10.7956 1 10 1L6 10V21H17.28C17.7623 21.0055 18.2304 20.8364 18.5979 20.524C18.9654 20.2116 19.2077 19.7769 19.28 19.3L20.66 10.3C20.7035 10.0134 20.6842 9.72068 20.6033 9.44225C20.5225 9.16382 20.3821 8.90629 20.1919 8.68751C20.0016 8.46873 19.7661 8.29393 19.5016 8.17522C19.2371 8.0565 18.9499 7.99672 18.66 8H13Z"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </>
                            }
                        />

                        {/* Dislike Button */}
                        <ActionButtonFocusable
                            focusKey={"DislikeButton"}
                            up={"VideoPlayButton"}
                            left={"LikeButton"}
                            right={
                                nextEpisode !== null ?
                                    "NextEpisodeButton"
                                    : "DislikeButton"
                            }
                            className={Constants.BUTTON_CIRCLE}
                            dislikeBtn={true}
                            rateStatus={rate}
                            onClick={(e) => rating(-1)}
                            onEnterPress={(e) => rating(-1)}
                            icon={
                                <>
                                    <svg className="border-line" width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.5" y="1" width="68" height="68" rx="34" stroke="#fff" strokeWidth="2" />
                                    </svg>
                                    <svg
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16 0.999995H18.67C19.236 0.989986 19.7859 1.18813 20.2154 1.55681C20.645 1.9255 20.9242 2.43905 21 3V10C20.9242 10.5609 20.645 11.0745 20.2154 11.4432C19.7859 11.8119 19.236 12.01 18.67 12H16M9.00003 14V18C9.00003 18.7956 9.3161 19.5587 9.87871 20.1213C10.4413 20.6839 11.2044 21 12 21L16 12V0.999995H4.72003C4.2377 0.994543 3.76965 1.16359 3.40212 1.47599C3.0346 1.78839 2.79235 2.22309 2.72003 2.7L1.34003 11.7C1.29652 11.9866 1.31586 12.2793 1.39669 12.5577C1.47753 12.8362 1.61793 13.0937 1.80817 13.3125C1.99842 13.5313 2.23395 13.7061 2.49846 13.8248C2.76297 13.9435 3.05012 14.0033 3.34003 14H9.00003Z"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </>
                            }
                        />

                    </div>

                    <div className="right-button">
                        {nextEpisode !== null ? (
                            <>
                                {/* Next Section Button */}
                                <ButtonFocusable
                                    id="BottomNextEpisodeButton"
                                    focusKey={"NextEpisodeButton"}
                                    up={"VideoPlayButton"}
                                    left={"DislikeButton"}
                                    className={
                                        Constants.BUTTON_PLAYER +
                                        Constants.BUTTON_NEXT_EPISODE
                                    }
                                    onEnterPress={() => {
                                        //getNextEpisode();
                                        triggerVideoCompletedGTMEvent();
                                        setPlayingVideoCount(playingVideoCount + 1);
                                        document.title = nextEpisode.metadata.name;
                                        videoNextEpisodeEvent(
                                            nextEpisode.metadata.name,
                                            nextEpisode.episodeNumber !== null ? nextEpisode.episodeNumber : "",
                                            nextEpisode.seasonNumber !== null ? nextEpisode.seasonNumber : "",
                                            nextEpisode.id,
                                            nextEpisode.metadata.category,
                                            playerRef.current !== null ? Math.floor(playerRef.current.currentTime()) : 0
                                        );
                                        setSkipIntroButton();
                                        props.setFocus("VideoPlayButton");
                                    }}
                                    onClick={() => {
                                        //console.log("sonraki bölüm butonuna tıklandı");
                                        triggerVideoCompletedGTMEvent();
                                        setPlayingVideoCount(playingVideoCount + 1);
                                        document.title = nextEpisode.metadata.name;
                                        videoNextEpisodeEvent(
                                            nextEpisode.metadata.name,
                                            nextEpisode.episodeNumber !== null ? nextEpisode.episodeNumber : "",
                                            nextEpisode.seasonNumber !== null ? nextEpisode.seasonNumber : "",
                                            nextEpisode.id,
                                            nextEpisode.metadata.category,
                                            playerRef.current !== null ? Math.floor(playerRef.current.currentTime()) : 0
                                        );
                                        setSkipIntroButton();
                                        props.setFocus("VideoPlayButton");
                                    }}
                                    icon={
                                        <i>
                                            <svg
                                                width="24"
                                                height="27"
                                                viewBox="0 0 24 27"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M21.832 3.41699V23.2503"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M2 2L16.1667 13.3333L2 24.6667V2Z"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </i>
                                    }
                                    title={"Sonraki Bölüm"}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <BackButtonFocusable
                    extraClass={"in-player"}
                    up={"BackButton"}
                    left={"BackButton"}
                    right={"BackButton"}
                    down={toggleBox === "track" ? "audio-track-0" : "SubtitleStatusButton"}
                    focusKey={"BackButton"}
                    afterText={"Geri"}
                    type="button"
                    onEnterPress={() => {
                        disableToggleBox("VideoPlayButton")
                    }}
                    onClick={() => { disableToggleBox("VideoPlayButton") }}
                    icon={
                        <i>
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg" >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.6667 -8.46519e-05C25.8714 -8.50542e-05 33.3333 7.46184 33.3333 16.6666C33.3333 25.8713 25.8714 33.3333 16.6667 33.3333C7.46192 33.3333 -3.26171e-07 25.8713 -7.28523e-07 16.6666C-1.13088e-06 7.46184 7.46192 -8.42495e-05 16.6667 -8.46519e-05ZM8.39289 15.4073C8.12322 15.751 7.9614 16.1833 7.95841 16.6533L7.95837 16.6665L7.95837 16.6666C7.95837 17.2374 8.19262 17.7535 8.57023 18.124L15.223 24.7768C16.0203 25.5741 17.3131 25.5741 18.1104 24.7768C18.9077 23.9795 18.9077 22.6868 18.1104 21.8894L14.9292 18.7083L23.3334 18.7083C24.461 18.7083 25.375 17.7942 25.375 16.6666C25.375 15.539 24.461 14.6249 23.3334 14.6249L14.9289 14.6249L18.1104 11.4435C18.9077 10.6461 18.9077 9.35343 18.1104 8.55611C17.3131 7.75879 16.0203 7.75879 15.223 8.55611L8.55636 15.2228C8.4979 15.2812 8.44335 15.3429 8.39289 15.4073Z"
                                    fill="currentColor" />
                            </svg></i>
                    }
                />

                <div id="subtitles-box" className="video-bottom-toggle-box">
                    <div>
                        <div className="title-elem">
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_5001_1229)">
                                    <path
                                        d="M27.7106 9.47851C27.5786 8.79633 27.3098 8.1713 26.9313 7.66653C26.5529 7.16177 26.0781 6.79515 25.5551 6.60371C23.6441 6 16.0001 6 16.0001 6C16.0001 6 8.35601 6 6.445 6.6612C5.92196 6.85264 5.44724 7.21926 5.06879 7.72403C4.69033 8.22879 4.42153 8.85383 4.28955 9.53601C3.93981 12.0451 3.76873 14.5904 3.77847 17.1399C3.766 19.7086 3.93709 22.2733 4.28955 24.8012C4.43505 25.4622 4.70987 26.0635 5.08745 26.5469C5.46503 27.0303 5.9326 27.3796 6.445 27.561C8.35601 28.2222 16.0001 28.2222 16.0001 28.2222C16.0001 28.2222 23.6441 28.2222 25.5551 27.561C26.0781 27.3696 26.5529 27.003 26.9313 26.4982C27.3098 25.9934 27.5786 25.3684 27.7106 24.6862C28.0576 22.196 28.2287 19.6702 28.2216 17.1399C28.2341 14.5712 28.063 12.0065 27.7106 9.47851Z"
                                        stroke="#E1E1E8"
                                        strokeWidth="2.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M9.33301 17.333H11.5552"
                                        stroke="#E1E1E8"
                                        strokeWidth="2.66667"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M20.4443 21.5552H22.6666"
                                        stroke="#E1E1E8"
                                        strokeWidth="2.66667"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M16 17.333H22.6667"
                                        stroke="#E1E1E8"
                                        strokeWidth="2.66667"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M9.33301 21.5552H15.9997"
                                        stroke="#E1E1E8"
                                        strokeWidth="2.66667"
                                        strokeLinecap="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_5001_1229">
                                        <rect
                                            width="26.6667"
                                            height="26.6667"
                                            fill="white"
                                            transform="translate(2.6665 2.6665)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>Altyazılar</span>
                        </div>
                        <div className="buttons-huge">
                            <ButtonFocusable
                                focusKey={"SubtitleStatusButton"}
                                id={"SubtitleStatusButton"}
                                className="btn btn-subtitle btn-has-check"
                                title={"Kapalı"}
                                checkIcon={subTitle === "off" && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>}
                                left={"SubtitleButton"}
                                right={"subtitle-btn-0"}
                                up={"BackButton"}
                                // disabled={!validateForm()}
                                onBecameFocused={(e) => { }}
                                onClick={() => { onClickSubTitle("off") }}
                                onEnterPress={() => { onClickSubTitle("off") }}
                            />

                        </div>
                    </div>
                    <div>
                        <div className="title-elem">Diller</div>
                        <div className="buttons-huge">
                            {currentMedia !== null && currentMedia.subtitles && (
                                currentMedia.subtitles.map((elem, index) => (
                                    <ButtonFocusable
                                        focusKey={"subtitle-btn-" + index}
                                        id={"subtitle-btn-" + index}
                                        className="btn btn-has-check"
                                        title={elem.label}
                                        checkIcon={subTitle === elem.language.split('-')[0] && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>}
                                        left={index === 0 ? "SubtitleStatusButton" : "subtitle-btn-" + (index - 1)}
                                        right={currentMedia.subtitles.length - 1 === index ? "SubtitleStatusButton" : "subtitle-btn-" + (index + 1)}
                                        up={"BackButton"}
                                        key={"subtitle-btn-" + index}
                                        // disabled={!validateForm()}
                                        onBecameFocused={(e) => { }}
                                        onClick={() => {
                                            onClickSubTitle(elem.language);
                                        }}
                                        onEnterPress={() => {
                                            onClickSubTitle(elem.language);
                                        }}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>

                <div id="track-box" className="video-bottom-toggle-box">
                    <div>
                        <div className="title-elem">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.7273 20.8232C29.4727 20.8232 30.069 20.278 30.069 19.6117V12.3882C30.069 11.7219 29.4727 11.1616 28.7273 11.1616C27.9656 11.1616 27.4023 11.7219 27.4023 12.3882V19.6117C27.4023 20.278 27.9656 20.8232 28.7273 20.8232Z" fill="white" />
                                <path d="M23.6395 28.4559C24.3849 28.4559 24.9645 27.9108 24.9645 27.2445V4.75641C24.9645 4.0901 24.3849 3.52979 23.6395 3.52979C22.8611 3.52979 22.2979 4.0901 22.2979 4.75641V27.2445C22.2979 27.9108 22.8611 28.4559 23.6395 28.4559Z" fill="white" />
                                <path d="M18.534 23.8669C19.296 23.8669 19.8757 23.3369 19.8757 22.6554V9.3443C19.8757 8.66285 19.296 8.11768 18.534 8.11768C17.7722 8.11768 17.209 8.66285 17.209 9.3443V22.6554C17.209 23.3369 17.7722 23.8669 18.534 23.8669Z" fill="white" />
                                <path d="M13.4315 31.8174C14.1934 31.8174 14.7897 31.2722 14.7897 30.5907V1.40924C14.7897 0.727782 14.1934 0.182617 13.4315 0.182617C12.6862 0.182617 12.123 0.727782 12.123 1.40924V30.5907C12.123 31.2722 12.6862 31.8174 13.4315 31.8174Z" fill="white" />
                                <path d="M8.34458 25.7147C9.10648 25.7147 9.6862 25.1695 9.6862 24.4881V7.51228C9.6862 6.83081 9.10648 6.27051 8.34458 6.27051C7.59925 6.27051 7.01953 6.83081 7.01953 7.51228V24.4881C7.01953 25.1695 7.59925 25.7147 8.34458 25.7147Z" fill="white" />
                                <path d="M3.23915 19.5207C4.01762 19.5207 4.59733 18.9756 4.59733 18.2941V13.7056C4.59733 13.0242 4.01762 12.4639 3.23915 12.4639C2.49381 12.4639 1.93066 13.0242 1.93066 13.7056V18.2941C1.93066 18.9756 2.49381 19.5207 3.23915 19.5207Z" fill="white" />
                            </svg>

                            <span>Seslendirme</span>
                        </div>
                        <div className="buttons-huge">

                            {(audioTracks.tracks_ && audioTracks.tracks_.length > 0) && (
                                audioTracks.tracks_.map((elem, index) => (
                                    <ButtonFocusable
                                        focusKey={"audio-track-" + index}
                                        id={"audio-track-btn-" + index}
                                        className="btn btn-has-check"
                                        title={index === 0 ? "Orijinal Dil" : elem.label}
                                        checkIcon={index === audioTrack && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 6L9 17L4 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>}
                                        up={"BackButton"}
                                        left={index === 0 ? "TrackButton" : "audio-track-" + (index - 1)}
                                        right={audioTracks.tracks_.length - 1 === index ? "audio-track-0" : "audio-track-" + (index + 1)}
                                        key={"audio-track-btn-" + index}
                                        // disabled={!validateForm()}
                                        onBecameFocused={(e) => { }}
                                        onClick={() => {
                                            changePlayerAudio(index);
                                        }}
                                        onEnterPress={() => {
                                            changePlayerAudio(index);
                                        }}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>

                {skipIntroButtonToggle === true ? (
                    <>
                        {/* Skip Intro Button  */}
                        <div className="intro-button-container">
                            <ButtonFocusable
                                animationTime={8}
                                focusKey={"SkipIntroButton"}
                                className={
                                    Constants.BUTTON_PLAYER + Constants.BUTTON_INTRO
                                }
                                onEnterPress={() => {
                                    skipIntroClick(parseInt(currentMedia.playerMediaMetaData.startIntro), parseInt(currentMedia.playerMediaMetaData.introDuration));
                                }}
                                onClick={() => {
                                    skipIntroClick(parseInt(currentMedia.playerMediaMetaData.startIntro), parseInt(currentMedia.playerMediaMetaData.introDuration));
                                }}
                                icon={""}
                                title={<span>İntroyu Atla</span>}
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}

                {/* Generic Button & Next Section Button 
                <div className="final-button-container">

                        
                        <ButtonFocusable
                            focusKey={""}
                            className={Constants.BUTTON_PLAYER}
                            onEnterPress={() => {
                                //   navigate('/video')
                            }}
                            link={""}
                            icon={""}
                            title={"Jeneriyi İzle"}
                        /> 
                        <ButtonFocusable
                            focusKey={"WatchNowButton"}
                            className={
                                Constants.BUTTON_PLAYER +
                                Constants.BUTTON_NEXT_EPISODE
                            }
                            onEnterPress={() => {
                                //console.log("tst");
                                // navigate('/video')
                            }}
                            link={""}
                            icon={
                                <i>
                                    <svg
                                        width="25"
                                        height="30"
                                        viewBox="0 0 25 30"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2.5 2.25L22.3333 15L2.5 27.75V2.25Z"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </i>
                            }
                            title={"Sonraki Bölüm"}
                        />
                </div>
                */}

                {recommendedVideos !== null ? (
                    <>
                        {/*Other Titles  */}
                        <div className="recommended-videos-container">
                            <div>
                                <span className="title">
                                    Dikkatini çekebilecek diğer içerikler:
                                </span>
                                <div className="title-wrapper">
                                    {recommendedVideos.map((item, key) => (
                                        <SliderCardFocusable
                                            lazyLoad={false}
                                            key={"recommended-video-" + key}
                                            focusKey={"recommended-video-" + key}
                                            id={item.id}
                                            left={key === 0 && "VideoTitleButton"}
                                            right={recommendedVideos.length - 1 === key && "recommended-video-0"}
                                            title={item.metadata.name}
                                            onEnterPress={() => {
                                                redirectToVideo(item.id, item.titleId, item.type);
                                            }}
                                            onClick={() => {
                                                redirectToVideo(item.id, item.titleId, item.type);
                                            }}
                                            onBecameFocused={() => {
                                                similarVideoCount()
                                            }}
                                            imageURL={getThumbnailImage(item.metadata.images, 480)}
                                        />
                                    ))}
                                </div>
                                <span className="count">İçerik <span>10</span> saniye içinde başlıyor.</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        );
    } else if (premiumCheck === false) {
        // bu içeriği izlemeye yetkisi yok ise
        props.setFocus("PremiumFreeCard");
        return (
            <PremiumFreeFocusable
                focusKey={"PremiumFreeCard"}
                img={imgNoAccess}
            />
        );
    } else {
        return (
            <>
                <LoadingFullscreen show={true} />
            </>
        );
    }
}
