import React, { useState, useEffect, useLayoutEffect } from "react";
import imgBalloon from "../assets/img/balloon.svg";
import * as Constants from "../constants/constants";
import ButtonContainer from "./Button/buttonContainer";
import TitleButtonContainer from "./Button/titleButtonContainer";
import LinkButton from "./Button/linkButton";
import ActionButton from "./Button/actionButton";
import FeaturedMediaSummaryContainer from "./FeaturedMediaSummaryContainer";
import { useNavigate } from "react-router-dom";
import withDirectionFocusable from "../components/withDirectionFocusable";
import {
	addRate,
	deleteRate,
    addTitleToPlaylist,
    deleteTitleFromPlaylist,
} from "../services/gainAPI";
import {
    rating as ratingEvent
} from "../services/gtm";
import { 
    getLogoImage,
    getCoverImage
} from "../helper/imageHelper";

const LinkButtonFocusable = withDirectionFocusable(LinkButton);
const ActionButtonFocusable = withDirectionFocusable(ActionButton);
const ButtonContainerFocusable = withDirectionFocusable(ButtonContainer);
const FeaturedMediaSummaryContainerFocusable = withDirectionFocusable(
	FeaturedMediaSummaryContainer
);
const TitleButtonContainerFocusable = withDirectionFocusable(TitleButtonContainer);

export default function FeaturedMediaSummary(props) {
	const navigate = useNavigate();
	const [rate, setRate] = useState(0); // Rating
    const [isTitleInPlaylist, setIsTitleInPlaylist] = useState(false); // Listeye eklenmiş mi?
	const [continueButtonText, setContinueButtonText] = useState(null); // Devam Et butonunun text'i

    // unmount
	useEffect(() => {
		return () => {
			setContinueButtonText(null);
			setRate(0);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
        // console.log("initial:", props.initialFeaturedMedia);
        generateButtonText();

		if (props.ShowTitleButtons === true) {
			setRate(props.initialFeaturedMedia.userRate);
		}
 
		if(isTitleInPlaylist !== null){
			setIsTitleInPlaylist(props.initialFeaturedMedia.isAddedToUserList)
		}

        if(props.isHomePage === true){ 
            props.setFocus("WatchNowButton");
        }    

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const generateButtonText = () => {
		// Devam Et butonu text'i
		// console.log(props.initialFeaturedMedia);

		if (props.initialFeaturedMedia) { 
			var buttonText = "Şimdi İzle"; // varsayılan buton texti
            var isTitle = props.initialFeaturedMedia.type === 'title' || props.initialFeaturedMedia.type === 'singleTitle';

			if (props.initialFeaturedMedia.type === 'live') {
				// Canlı yayın ise
				buttonText = "Canlı Yayını İzle";
			} 
            // else 
            if (props.initialFeaturedMedia.continueWatch !== undefined && props.initialFeaturedMedia.continueWatch !== null) {
				// İzlemeye devam et datası var ise
                if(isTitle)
                {
                    buttonText = "Devam Et ";
                    if(props.initialFeaturedMedia.continueWatch.seasonNumber !== undefined && props.initialFeaturedMedia.continueWatch.episodeNumber !== undefined && props.initialFeaturedMedia.continueWatch.seasonNumber !== null && props.initialFeaturedMedia.continueWatch.episodeNumber !== null){
                        buttonText += props.initialFeaturedMedia.continueWatch.seasonNumber + ".S " + (props.initialFeaturedMedia.continueWatch.episodeNumber !== "0" ? props.initialFeaturedMedia.continueWatch.episodeNumber + ".B" : "");
                    }
                }
                else{
                    buttonText = "Devam Et  "
                }
			}

			// console.log("buttonText:" + buttonText);
			setContinueButtonText(buttonText);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const renderLogoOrTitle = () => {
		if (props.initialFeaturedMedia.metadata.images.logos !== null && props.initialFeaturedMedia.metadata.images.logos.length > 0) {
            var logoImageURL = getLogoImage(props.initialFeaturedMedia.metadata.images, 533);
			return (
				<>
					<img
						id="featured-media-logo"
						className="logo"
						src={logoImageURL}
						alt=" "
						style={{ display: "block" }}
					/>
					<div
						id="featured-media-title"
						className="title flex-ai-end"
						style={{ display: "none" }}
					>
						<h2>{props.initialFeaturedMedia.metadata.name}</h2>
					</div>
				</>
			);
		} else {
			return (
				<>
					<img
						id="featured-media-logo"
						className="logo"
						src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
						alt=" "
						style={{ display: "none" }}
					/>
					<div
						id="featured-media-title"
						className="title flex-ai-end"
						style={{ display: "flex" }}
					>
						<h2>{props.initialFeaturedMedia.metadata.name}</h2>
					</div>
				</>
			);
		}
	};

    const addToListButtonClick = () => { 
        if(isTitleInPlaylist === false){
            // listeye eklenir.
            setIsTitleInPlaylist(true);
            addTitleToPlaylist(props.initialFeaturedMedia.titleId).then(function (result) {});
        }
        else{
            // listeden çıkarılır.
            setIsTitleInPlaylist(false);
            deleteTitleFromPlaylist(props.initialFeaturedMedia.titleId).then(function (result) {});
        }
    }

	const rating = (newRate) => {
		if (props.initialFeaturedMedia !== null) {
            var isTitle = props.initialFeaturedMedia.type === "title" || props.initialFeaturedMedia.type === "singleTitle";
			if(rate === newRate){
                newRate = 0;
            } 
			if (rate === 0) {
				// eğer daha önce like ya da dislike edilmemişse 1 ya da -1 olarak kaydedilir.
				addRate(props.initialFeaturedMedia.titleId, isTitle === false ? props.initialFeaturedMedia.mediaId : "", newRate).then(function (result) {
					setRate(newRate);
				});

				ratingEvent(
					props.initialFeaturedMedia.title,
					isTitle,
					props.initialFeaturedMedia.titleId,
					props.initialFeaturedMedia.mediaid,
					props.initialFeaturedMedia.contentCategory,
					newRate === 1 ? "like" : "dislike",
					""
				);
			} else {
				if (rate !== newRate) {
					// eski ratingi siliniyor
					deleteRate(props.initialFeaturedMedia.titleId, isTitle === false ? props.initialFeaturedMedia.mediaId : "", rate).then(function (result) {
						setRate(0);

						// yeni verdiği rating ekleniyor
						addRate(props.initialFeaturedMedia.titleId, isTitle === false ? props.initialFeaturedMedia.mediaId : "", newRate).then(function (result) {
							setRate(newRate);
						});
					});

					ratingEvent(
						props.initialFeaturedMedia.title,
						isTitle,
						props.initialFeaturedMedia.titleId,
						props.initialFeaturedMedia.mediaid,
						props.initialFeaturedMedia.contentCategory,
						newRate === 1 ? "like" : "dislike",
						rate === 1 ? "like" : "dislike"
					);
				} else {
					// eski ratingi siliniyor
					deleteRate(props.initialFeaturedMedia.titleId,  isTitle === false ? props.initialFeaturedMedia.mediaId : "", newRate).then(function (result) {
						setRate(0);
					});
				}
			}
		}
	}

    const renderHomeHeroMainButton = () => {
			if (
				props.ShowTitleButtons === false &&
				props.initialFeaturedMedia &&
				props.ShowWatchNowButton === true // props.ShowDetailsButton === true
			) {
				// continueWatch datası varsa
				if (
					props.ShowWatchNowButton === true &&
					props.initialFeaturedMedia.continueWatch !== undefined &&
					props.initialFeaturedMedia.continueWatch !== null
				) {
                    return <LinkButtonFocusable
                                up={"WatchNowButton"}
                                down={"Slider"}
                                right={props.ShowDetailsButton ? "DetailButton" : null}
                                left={"Sidebar"}
                                focusKey={"WatchNowButton"}
                                className={
                                    Constants.BUTTON_MEDIUM +
                                    Constants.BUTTON_WATCH_NOW +
                                    "btn-home-hero-cont-watch"
                                }
                                onEnterPress={() => {
                                    navigate(
                                        "/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId
                                    );
                                }}
                                link={
                                    "/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId  + "&titleId=" + props.initialFeaturedMedia.titleId
                                }
                                percent={Math.floor(props.initialFeaturedMedia.continueWatch.lastEngagementTime * 100) / Math.floor(props.initialFeaturedMedia.continueWatch.contentDuration === undefined ? 10000 : props.initialFeaturedMedia.continueWatch.contentDuration)}
                                icon={
                                    <i>
                                        <svg
                                            width="22"
                                            height="28"
                                            viewBox="0 0 22 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1 1.25L20.8333 14L1 26.75V1.25Z"
                                                fill="currentColor"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </i>
                                }
                                title={continueButtonText}
                            />
				}
				// watchNow datası var ise (watchNow datası sadece title ya da singleTitle ise geliyor)
				else if (
					props.initialFeaturedMedia.watchNow !== null &&
					props.initialFeaturedMedia.watchNow !== undefined
				) {
                    return <LinkButtonFocusable
                            up={"WatchNowButton"}
                            down={"Slider"}
                            right={props.ShowDetailsButton ? "DetailButton" : null}
                            left={"Sidebar"}
                            focusKey={"WatchNowButton"}
                            className={
                                Constants.BUTTON_MEDIUM +
                                Constants.BUTTON_WATCH_NOW
                            }
                            onEnterPress={() => {
                                if (props.initialFeaturedMedia.continueWatch !== undefined && props.initialFeaturedMedia.continueWatch !== null) {
                                    navigate("/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId);
                                }
                                else if (props.initialFeaturedMedia.watchNow !== null && props.initialFeaturedMedia.watchNow !== undefined) {
                                    navigate("/video?id=" + props.initialFeaturedMedia.watchNow.id + "&titleId=" + props.initialFeaturedMedia.titleId);
                                }
                                else if (props.initialFeaturedMedia.type === 'title' || props.initialFeaturedMedia.type === 'singleTitle') {
                                    navigate("/title?id=" + props.initialFeaturedMedia.id);
                                }
                                else {
                                    navigate("/video?id=" + props.initialFeaturedMedia.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId);
                                }
                            }}
                            link={
                                props.initialFeaturedMedia.continueWatch !== undefined && props.initialFeaturedMedia.continueWatch !== null 
                                ? "/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId
                                : props.initialFeaturedMedia.watchNow !== null && props.initialFeaturedMedia.watchNow !== undefined
                                ? "/video?id=" + props.initialFeaturedMedia.watchNow.id + "&titleId=" + props.initialFeaturedMedia.titleId
                                : props.initialFeaturedMedia.type === 'title' || props.initialFeaturedMedia.type === 'singleTitle'
                                ? "/title?id=" + props.initialFeaturedMedia.id
                                : "/video?id=" + props.initialFeaturedMedia.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId
                            }
                            icon={
                                <i>
                                    <svg
                                        width="22"
                                        height="28"
                                        viewBox="0 0 22 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 1.25L20.8333 14L1 26.75V1.25Z"
                                            fill="currentColor"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </i>
                            }
                            title={props.initialFeaturedMedia.watchNow.isTrailer === false ? "Şimdi İzle" : "Fragmanı İzle"}
                        />
				}
                // live ya da continueWatch datası bulunmayan bir episode'dur
                else{
                    return <LinkButtonFocusable
                            up={"WatchNowButton"}
                            down={"Slider"}
                            right={props.ShowDetailsButton ? "DetailButton" : null}
                            left={"Sidebar"}
                            focusKey={"WatchNowButton"}
                            className={
                                Constants.BUTTON_MEDIUM +
                                Constants.BUTTON_WATCH_NOW
                            }
                            onEnterPress={() => {
                                if (props.initialFeaturedMedia.continueWatch !== undefined && props.initialFeaturedMedia.continueWatch !== null) {
                                    navigate("/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId);
                                }
                                else if (props.initialFeaturedMedia.watchNow !== null && props.initialFeaturedMedia.watchNow !== undefined) {
                                    navigate("/video?id=" + props.initialFeaturedMedia.watchNow.id + "&titleId=" + props.initialFeaturedMedia.titleId);
                                }
                                else if (props.initialFeaturedMedia.type === 'title' || props.initialFeaturedMedia.type === 'singleTitle') {
                                    navigate("/title?id=" + props.initialFeaturedMedia.id);
                                }
                                else {
                                    navigate("/video?id=" + props.initialFeaturedMedia.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId);
                                }
                            }}
                            link={
                                props.initialFeaturedMedia.continueWatch !== undefined && props.initialFeaturedMedia.continueWatch !== null 
                                ? "/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId
                                : props.initialFeaturedMedia.watchNow !== null && props.initialFeaturedMedia.watchNow !== undefined
                                ? "/video?id=" + props.initialFeaturedMedia.watchNow.id + "&titleId=" + props.initialFeaturedMedia.titleId
                                : props.initialFeaturedMedia.type === 'title' || props.initialFeaturedMedia.type === 'singleTitle'
                                ? "/title?id=" + props.initialFeaturedMedia.id
                                : "/video?id=" + props.initialFeaturedMedia.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId
                            }
                            icon={
                                <i>
                                    <svg
                                        width="22"
                                        height="28"
                                        viewBox="0 0 22 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 1.25L20.8333 14L1 26.75V1.25Z"
                                            fill="currentColor"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </i>
                            }
                            title={props.initialFeaturedMedia.type === "live" ? "Canlı Yayını İzle" : "Şimdi İzle"}
                        />
                }
			}
	}

    const renderHomeHeroDetailButton = () => {
            if (
                props.ShowDetailsButton === true 
                // && props.initialFeaturedMedia.watchNow !== null
                // && props.initialFeaturedMedia.watchNow !== undefined
                // && (props.initialFeaturedMedia.type === "title" || props.initialFeaturedMedia.type === "singleTitle")
            ) {
                return (
                    <LinkButtonFocusable
                        focusKey={"DetailButton"}
                        className={Constants.BUTTON_MEDIUM}
                        link={"/title?id=" + props.initialFeaturedMedia.titleId}
                        up={"DetailButton"}
                        down={"Slider"}
                        left={"WatchNowButton"}
                        onEnterPress={() => {
                            navigate(
                                "/title?id=" + props.initialFeaturedMedia.titleId
                            );
                        }}
                        title={"Detayları Gör"}
                    />
                );
            }
    }

	return (
		props.initialFeaturedMedia && <>
			<div className="img-container">
				<img id="img-hero" src={getCoverImage(props.initialFeaturedMedia.metadata.images, 1920)} alt=" " />
			</div>

			<FeaturedMediaSummaryContainerFocusable>
				{renderLogoOrTitle()}

				<i id="featured-media-live" className="live" style={{ display: props.initialFeaturedMedia.type === 'live' ? "block" : "none" }} />

				<ul id="featured-media-info" className="info" style={{ display: props.initialFeaturedMedia.type === 'live' ? "none" : "flex" }}>

                    {/* Gain Orjinal İçerik mi? */}
                    <li id="featured-media-originals" className="li-balloon" 
                        style={{display : props.initialFeaturedMedia.metadata.isGainOriginals !== undefined && props.initialFeaturedMedia.metadata.isGainOriginals === true ? "flex" : "none"}}>
                        <img className="balloon" src={imgBalloon} alt=" " />
                    </li>

                    {/* Kategori */}
                    <li>
                        <span id="featured-media-category">{props.initialFeaturedMedia.metadata.category}</span>
                    </li>

                    {/* Rtuk işaretleri */}
                    <li id="rtuk-container"
                        className={props.initialFeaturedMedia.metadata.imdbScore === undefined && props.initialFeaturedMedia.metadata.imdbScore === null ? "li-last" : ""}
                        style={{display : props.initialFeaturedMedia.metadata.contentRatings !== undefined && props.initialFeaturedMedia.metadata.contentRatings !== null && props.initialFeaturedMedia.metadata.contentRatings.length > 0 ? "flex" : "none"}}>
                        {
                            props.initialFeaturedMedia.metadata.contentRatings !== undefined && props.initialFeaturedMedia.metadata.contentRatings !== null && props.initialFeaturedMedia.metadata.contentRatings.map((item) => (
                                <span key={"rtuk-" + item} className={"rtuk " + item}></span>
                        ))
                        }
                    </li>

                    {/* IMDB Puanı */}
                    <li id="featured-media-imdb-container" style={{display : props.initialFeaturedMedia.metadata.imdbScore !== undefined && props.initialFeaturedMedia.metadata.imdbScore !== "" ? "flex" : "none"}}>
                        <span id="featured-media-imdb" className="imdb">{props.initialFeaturedMedia.metadata.imdbScore}</span>
                    </li>

                </ul>

				{props.ShowDescription === true && ( // Açıklama Alanı
					<p id="featured-media-description" className="description text-medium">
						{props.initialFeaturedMedia.metadata.description}
					</p>
				)}

                <div id="cont-watch-in-sticky-area" style={{ 'display': 'none'}}>
                    <i style={{ '--percent': '0%'}}></i>
                    <span></span>
                </div>


                {/* ANA SAYFA HERO İÇİN */}
				{
                    props.ShowTitleButtons === false && 
                    props.initialFeaturedMedia && 
                    (props.ShowWatchNowButton === true || props.ShowDetailsButton === true) &&
                    (
                        <ButtonContainerFocusable>
                            {/* "Şimdi İzle" / "Devam Et" butonu */}
                            {renderHomeHeroMainButton()}
                            
                            {/* "Detayları gör" butonu */}
                            {renderHomeHeroDetailButton()}
                        </ButtonContainerFocusable>
					)
                }


                {/* TITLE SAYFASI HERO İÇİN */}
				{props.ShowTitleButtons === true && props.initialFeaturedMedia && ( 
                        // Title sayfası butonları
						<>
							<div className="title-buttons">
								<TitleButtonContainerFocusable>
									{props.initialFeaturedMedia.continueWatch !== undefined &&  props.initialFeaturedMedia.continueWatch !== null ? ( 
                                        // Title Sayfası Şimdi İzle / Devam Et  Butonu
										<>
											<LinkButtonFocusable
                                                // up={"BackButton"}
                                                up={"WatchNowButton"} // webos bugı için kendisine gidecek şekilde ayarlandı.
                                                down={props.initialFeaturedMedia.type === 'title' ? "EpisodeButton" : "AddToListButton"}
												left={"Sidebar"}
												focusKey={"WatchNowButton"}
												className={
													Constants.BUTTON_MEDIUM +
													Constants.BUTTON_WATCH_NOW +
													"btn-full-width "
												}
												onEnterPress={() => {
													navigate(
														"/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId
													);
												}}
												link={
													"/video?id=" + props.initialFeaturedMedia.continueWatch.mediaId  + "&titleId=" + props.initialFeaturedMedia.titleId
												}
												percent={Math.floor(props.initialFeaturedMedia.continueWatch.lastEngagementTime * 100) / Math.floor(props.initialFeaturedMedia.continueWatch.contentDuration === undefined ? 10000 : props.initialFeaturedMedia.continueWatch.contentDuration)}
												icon={
													<i>
														<svg
															width="22"
															height="28"
															viewBox="0 0 22 28"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M1 1.25L20.8333 14L1 26.75V1.25Z"
																fill="currentColor"
																stroke="currentColor"
																strokeWidth="2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</i>
												}
												title={continueButtonText}
												// title={
												// 	"Devam Et " +
												// 	titleContinueWatch.seasonNumber +
												// 	".S " +
												// 	(titleContinueWatch.episodeNumber !== "0" ? titleContinueWatch.episodeNumber + ".B" : "Fragman")
												// }
											/>
										</>
									) : (
										<>
											<LinkButtonFocusable
                                                // up={"BackButton"}
                                                up={"WatchNowButton"} // webos bugı için kendisine gidecek şekilde ayarlandı.
                                                down={props.initialFeaturedMedia.type === 'title' ? "EpisodeButton" : "AddToListButton"}
												left={"Sidebar"}
												focusKey={"WatchNowButton"}
												className={
													Constants.BUTTON_MEDIUM +
													Constants.BUTTON_WATCH_NOW +
													"btn-full-width "
												}
												onEnterPress={() => {
                                                    props.initialFeaturedMedia.watchNow !== null && props.initialFeaturedMedia.watchNow !== undefined
                                                    ? navigate("/video?id=" + props.initialFeaturedMedia.watchNow.id + "&titleId=" + props.initialFeaturedMedia.titleId)
                                                    : navigate("/video?id=" + props.initialFeaturedMedia.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId)
												}}
												link={
                                                    props.initialFeaturedMedia.watchNow !== null && props.initialFeaturedMedia.watchNow !== undefined
                                                    ? "/video?id=" + props.initialFeaturedMedia.watchNow.id + "&titleId=" + props.initialFeaturedMedia.titleId
                                                    : "/video?id=" + props.initialFeaturedMedia.mediaId + "&titleId=" + props.initialFeaturedMedia.titleId
                                                }
												icon={
													<i>
														<svg
															width="22"
															height="28"
															viewBox="0 0 22 28"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M1 1.25L20.8333 14L1 26.75V1.25Z"
																fill="currentColor"
																stroke="currentColor"
																strokeWidth="2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</i>
												}
												title={continueButtonText}
											/>
										</>
									)}

									{
                                        // Bölümler ve Daha Fazlası Butonu
                                        props.initialFeaturedMedia.type === 'title' &&
                                        <LinkButtonFocusable 
                                            up={"WatchNowButton"}
                                            down={"AddToListButton"}
                                            left={"Sidebar"}
                                            focusKey={"EpisodeButton"}
                                            className={
                                                Constants.BUTTON_MEDIUM +
                                                Constants.BUTTON_WATCH_NOW +
                                                "btn-full-width"
                                            }
                                            onEnterPress={() => {
                                                navigate("/title-detail?id=" + props.initialFeaturedMedia.id);
                                            }}
                                            link={"/title-detail?id=" + props.initialFeaturedMedia.id}
                                            icon={
                                                <i>
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M13 8C13 7.4477 12.5523 7 12 7H3C2.44771 7 2 7.4477 2 8V17C2 17.5523 2.44771 18 3 18H12C12.5523 18 13 17.5523 13 17V8ZM12 5C13.6569 5 15 6.34315 15 8V17C15 18.6569 13.6569 20 12 20H3C1.34314 20 0 18.6569 0 17V8C0 6.34314 1.34315 5 3 5H12Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M7 3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10535 7.73478 2 8 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V12C18 12.2652 17.8946 12.5196 17.7071 12.7071C17.5196 12.8946 17.2652 13 17 13V15C17.7956 15 18.5587 14.6839 19.1213 14.1213C19.6839 13.5587 20 12.7956 20 12V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.31607 17.7956 0 17 0H8C7.20435 0 6.44129 0.31607 5.87868 0.87868C5.31607 1.44129 5 2.20435 5 3H7Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </i>
                                            }
                                            title={"Bölümler ve Daha Fazlası"}
                                        />
                                    }

									<div className="bottom-btn-wrapper">
										<ActionButtonFocusable // Listeye Ekle Butonu
                                            up={props.initialFeaturedMedia.type === 'title' ? "EpisodeButton" : "WatchNowButton"}
                                            down={"Slider"}
                                            right={"LikeButton"}
                                            left={"Sidebar"}
                                            focusKey={"AddToListButton"}
                                            className={
                                                Constants.BUTTON_MEDIUM +
                                                Constants.BUTTON_ADD_TO_LIST +
                                                (isTitleInPlaylist === true ? "added" : null)
                                            }
                                            addListBtn={true}
                                            onClick={() => addToListButtonClick()}
											onEnterPress={() => addToListButtonClick()}
                                            icon={
                                                <i>
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8 1V15"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M1 8H15"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </i>
                                            }
                                            title={isTitleInPlaylist === true ? "Listeden Çıkar" : "Listeye Ekle"}
                                        />

										<ActionButtonFocusable // Like Butonu
											up={props.initialFeaturedMedia.type === 'title' ? "EpisodeButton" : "WatchNowButton"}
											down={"Slider"}
											right={"DisLikeButton"}
											//left={"Sidebar"}
											left={"AddToListButton"}
											focusKey={"LikeButton"}
											className={Constants.BUTTON_CIRCLE}
											likeBtn={true}
											rateStatus={rate}
											onClick={(e) => rating(1)}
											onEnterPress={(e) => rating(1)}
											icon={
                                               <>
                                                    <svg className="border-line" width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <rect x="1.5" y="1" width="68" height="68" rx="34" stroke="#fff" strokeWidth="2"/>
                                                    </svg>    
                                                    <svg
                                                        width="22"
                                                        height="22"
                                                        viewBox="0 0 22 22"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        
                                                        <path
                                                            d="M6 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V12C1 11.4696 1.21071 10.9609 1.58579 10.5858C1.96086 10.2107 2.46957 10 3 10H6M13 8V4C13 3.20435 12.6839 2.44129 12.1213 1.87868C11.5587 1.31607 10.7956 1 10 1L6 10V21H17.28C17.7623 21.0055 18.2304 20.8364 18.5979 20.524C18.9654 20.2116 19.2077 19.7769 19.28 19.3L20.66 10.3C20.7035 10.0134 20.6842 9.72068 20.6033 9.44225C20.5225 9.16382 20.3821 8.90629 20.1919 8.68751C20.0016 8.46873 19.7661 8.29393 19.5016 8.17522C19.2371 8.0565 18.9499 7.99672 18.66 8H13Z"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                               </>
											}
										/>

										<ActionButtonFocusable // Dislike Butonu
											up={props.initialFeaturedMedia.type === 'title' ? "EpisodeButton" : "WatchNowButton"}
											down={"Slider"}
											left={"LikeButton"}
											focusKey={"DisLikeButton"}
											className={Constants.BUTTON_CIRCLE}
											dislikeBtn={true}
											rateStatus={rate}
											onClick={(e) => rating(-1)}
											onEnterPress={(e) => rating(-1)}
											icon={
                                                <>
                                                    <svg className="border-line" width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <rect x="1.5" y="1" width="68" height="68" rx="34" stroke="#fff" strokeWidth="2"/>
                                                    </svg>    
                                                    <svg
                                                        width="22"
                                                        height="22"
                                                        viewBox="0 0 22 22"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M16 0.999995H18.67C19.236 0.989986 19.7859 1.18813 20.2154 1.55681C20.645 1.9255 20.9242 2.43905 21 3V10C20.9242 10.5609 20.645 11.0745 20.2154 11.4432C19.7859 11.8119 19.236 12.01 18.67 12H16M9.00003 14V18C9.00003 18.7956 9.3161 19.5587 9.87871 20.1213C10.4413 20.6839 11.2044 21 12 21L16 12V0.999995H4.72003C4.2377 0.994543 3.76965 1.16359 3.40212 1.47599C3.0346 1.78839 2.79235 2.22309 2.72003 2.7L1.34003 11.7C1.29652 11.9866 1.31586 12.2793 1.39669 12.5577C1.47753 12.8362 1.61793 13.0937 1.80817 13.3125C1.99842 13.5313 2.23395 13.7061 2.49846 13.8248C2.76297 13.9435 3.05012 14.0033 3.34003 14H9.00003Z"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </>
												
											}
										/>
									</div>
								</TitleButtonContainerFocusable>
							</div>
						</>
					)}
			</FeaturedMediaSummaryContainerFocusable>
		</>
        );
}
