import React from "react";

export default function VideoButton(props) {
	return (
		<button
            className={`
            ${props.className}    
            ${props.focused ? "selected" : ""}`}
            onClick={props.onClick}
            >
              {props.icon}
        </button> 
	);
}